<template>
  <div class="text-center">
    <v-menu offset-x left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" icon x-small v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="moverActividad = true">
          <v-list-item-title>Mover actividad de módulo</v-list-item-title>
          <v-list-item-icon><v-icon small>mdi-share</v-icon></v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>

    <moverObjetoComponent
      v-if="moverActividad"
      :mostrar="moverActividad"
      :idCurso="idCurso"
      :actividad="actividad"
      :idModulo="idModulo"
      :tipoCurso="tipoCurso"
      @cancelar="moverActividad = false"
      @actividadCambiada="actividadCambiada"
    />
  </div>
</template>

<script>

import moverObjetoComponent from "../moverObjetoModulo.vue";

export default {
  name: "recursoMenu",
  props: {
    actividad: Object,
    idActividad: String,
  },
  components: { moverObjetoComponent },
  created(){
    this.tipoCurso = this.$route.params.tipoCurso;
    this.idCurso = this.$route.params.idCurso;
    this.idModulo = this.$route.params.idModulo;
  },
  data() {
    return {
      loading: true,
      tipoCurso: null,
      idCurso: null,
      idModulo: null,
      moverActividad: false,
    };
  },
  methods: {
    actividadCambiada(idActividad){
      this.$emit("eliminarActividad", idActividad);
    },
  },
};
</script>
