export function ordenarObjetosAsc(arreglo, llave) {
    return arreglo.sort((a, b) => (a[llave] > b[llave]) ? 1 : ((b[llave] > a[llave]) ? -1 : 0));
};

export const ordenarObjetosDesc = function (arreglo, llave) {
    return arreglo.sort((a, b) => (b[llave] > a[llave]) ? 1 : ((a[llave] > b[llave]) ? -1 : 0));
};

export function ordenarObjetosAscDeep(arreglo, llave1, llave2) {
    return arreglo.sort((a, b) => (a[llave1][llave2] > b[llave1][llave2]) ? 1 : ((b[llave1][llave2] > a[llave1][llave2]) ? -1 : 0));
};
export function ordenarObjetosDescDeep(arreglo, llave1, llave2) {
    return arreglo.sort((a, b) => (b[llave1][llave2] > a[llave1][llave2]) ? 1 : ((a[llave1][llave2] > b[llave1][llave2]) ? -1 : 0));
};

export function shuffleArray(array) {
    var currentIndex = array.length,
        temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export function moverElementoArray(arreglo, prevIndex, nextIndex) {
    let elementoMover = arreglo[prevIndex];

    let arregloNuevo = [];
    if (prevIndex - nextIndex == 1 || prevIndex - nextIndex == -1) {
        arregloNuevo = arreglo.map(object => ({ ...object._doc }));
        arregloNuevo[prevIndex] = arregloNuevo[nextIndex];
        arregloNuevo[nextIndex] = elementoMover;
    } else
        arreglo.forEach((element, index) => {
            // console.log(index, prevIndex, nextIndex);
            if (index != prevIndex) {
                if (index == nextIndex) {
                    if (prevIndex > nextIndex) {
                        arregloNuevo.push(elementoMover);
                        arregloNuevo.push(element);
                    } else {
                        arregloNuevo.push(element);
                        arregloNuevo.push(elementoMover);
                    }
                } else
                    arregloNuevo.push(element);
            }
        });

    return arregloNuevo;
}