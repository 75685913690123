import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,[_c(VListItemIcon,[(_vm.loading)?_c(VProgressCircular,{attrs:{"size":"20","indeterminate":"","color":"primary"}}):_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-harddisk")])],1),_c(VListItemContent,[(_vm.loading)?_c(VListItemTitle,[_vm._v("Cargando...")]):_c(VListItemTitle,[_vm._v(_vm._s(_vm.almacenamientoText))]),_c(VListItemSubtitle,[_vm._v("Almacenamiento total del curso")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }