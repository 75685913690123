<template>
  <div>
    <v-dialog
      v-model="mostrar"
      persistent
      fullscreen
      width="800"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card :loading="loading">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            <h3>{{titulo}}</h3>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn dark text @click="$emit('cerrar')">
            Cerrar
          </v-btn>
        </v-toolbar>

        <v-container>
          <v-row justify="center">
            <v-col cols="12" md="8" sm="12">
              <v-row justify="center">
                <v-col md="5" class="columna">
                  <v-menu
                    v-model="fechaPicker"
                    :close-on-content-click="false"
                    offset-y
                    max-width="290"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        :value="fechaTexto"
                        label="Fecha inicio:"
                        readonly
                        outlined
                        :disabled="loading || !permisoEscritura"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fecha"
                      locale="es"
                      @change="closeDatePicker"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col md="2" class="columna">
                  <v-menu
                    v-model="horaPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="hora"
                        label="Hora"
                        readonly
                        v-on="on"
                        outlined
                        :disabled="loading || !permisoEscritura"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="horaPicker"
                      v-model="hora"
                      format="24hr"
                      full-width
                      :disabled="loading"
                      @click:hour="closePicker"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col v-if="asistencia && permisoEscritura" md="2" class="columna">
                  <v-btn color="danger" small @click="eliminarAsistencia = true">
                    <v-icon color="white">mdi-trash-can-outline</v-icon>
                    <p style="margin:0px; color:white">Eliminar asistencia</p>
                  </v-btn>
                </v-col>
                <v-col v-else md="2" class="columna">
                  <v-select
                    v-model="duracion"
                    :items="duraciones"
                    outlined
                    :disabled="loading || !permisoEscritura"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="8" sm="12">
              <v-card outlined>
                <v-list>
                  <template v-for="(asistencia, index) in asistencias">
                    <v-list-item :key="asistencia.alumno._id">
                      <v-list-item-icon class="numero-lista">
                        <strong>{{ index + 1 }}</strong>
                      </v-list-item-icon>
                      <v-list-item-avatar class="avatar-lista">
                        <img
                          v-if="asistencia.alumno.avatar"
                          :src="`${imageURL}/icon/${asistencia.alumno.avatar}`"
                        />
                        <v-icon v-else size="45">mdi-account-circle</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{asistencia.alumno.nombreCompleto}}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action style="padding-right: 40px">
                        <v-btn
                          small
                          dark
                          min-width="130"
                          :color="asistencia.asistencia.color"
                          :loading="loading"
                          @click="cambiarStatus(index)"
                        >
                          {{ asistencia.asistencia.nombre }}</v-btn
                        >
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider class="mx-3" :key="index"></v-divider>
                  </template>
                </v-list>
                <v-card-actions
                  v-if="this.alumnos.length > 0 && permisoEscritura"
                  style="margin-left: 40px; margin-right: 45px"
                >
                  <v-btn block color="primary" dark small :loading="loading" @click="guardarAsistencia()">
                    Guardar
                  </v-btn>
                </v-card-actions>
                <v-card-text v-if="this.alumnos.length <= 0"> No hay alumnos registrados </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <eliminarAsistenciaComponent
      v-if="eliminarAsistencia"
      :mostrar="eliminarAsistencia"
      :idCurso="idCurso"
      :idAsistencia="asistencia.customData.asistencia.cursoAsistencia._id"
      @cancelar="eliminarAsistencia = false"
      @asistenciaEliminada="asistenciaEliminada"
    />
  </div>
</template>


<script>
import { DateTime } from "luxon";
import { mapGetters } from "vuex";
import { registrarAsistenciaService, modificarAsistenciaService, modificarFechaAsistenciaService } from './asistencias.service'
import eliminarAsistenciaComponent from "./eliminarAsistencia.vue";
export default {
  props: {
    mostrar: Boolean,
    idCurso: String,
    alumnos: Array,
    nuevo: { type: Boolean, default: false},
    asistencia: { type: Object, default: null },
    asistenciaStatus: { type: Array, default: null },
    permisoEscritura: { type: Boolean, default: false },
  },
  components: { eliminarAsistenciaComponent },
  computed: {
    ...mapGetters(["httpURL", "imageURL", "sessionToken", "timezone"]),
    fechaTexto() {
      let fechaTmp = this.fecha ? DateTime.fromISO(this.fecha).setLocale('es').toFormat("dd / MMMM / yyyy") : "";
      return fechaTmp;
    },
  },
  data: () => ({
    loading: false,
    esModificado: false,
    titulo: "Agregar asistencia",
    asistencias: [],
    asistenciaId: null,
    fecha: null,
    fechaPicker: false,
    hora: null,
    horaPicker: false,
    duracion: 1,
    duraciones: [
      { value: 1, text: "1 hora" },
      { value: 2, text: "2 horas" },
      { value: 3, text: "3 horas" },
      { value: 4, text: "4 horas" },
    ],
    eliminarAsistencia: false,
    fechaOld: null,
    horaOld: null,
  }),
  created() {
    if (this.nuevo == false) this.esModificado = true;
    this.fecha = DateTime.now().setZone(this.timezone).toFormat('yyyy-MM-dd');
    this.hora = DateTime.now().setZone(this.timezone).toLocaleString(DateTime.TIME_SIMPLE);
    this.hora = this.hora.split(':')[0] + ':00';
    if (this.asistencia) {
      this.titulo = "Modificar asistencia";
      this.fecha = DateTime.fromISO(this.asistencia.customData.asistencia.cursoAsistencia.fecha, { zone: this.timezone });
      this.fecha = this.fecha.toISO();
      this.hora = this.fecha.split('T')[1];
      this.fecha = this.fecha.split('T')[0];
      this.hora = this.hora.split(':')[0] + ':00';
      this.asistenciaId = this.asistencia.customData.asistencia.cursoAsistencia._id;
      this.fechaOld = this.fecha;
      this.horaOld = this.hora;
    }
    this.alumnos.forEach((alumno) => {
      let asistencia = {
        alumno,
        asistencia: this.asistencia ? { letra: "SC", nombre: "Sin captura", color: "grey" } : this.asistenciaStatus[0],
      };
      if (this.asistencia) {
        let asis = this.asistencia.customData.asistencia.asistencia.find((x) => x.usuario == alumno._id);
        if (asis)
          asistencia.asistencia = this.asistenciaStatus.find(
            (x) => x.letra == asis.asistencia
          );
      }
      this.asistencias.push(asistencia);
    });
  },
  methods: {
    cambiarStatus(index) {
      if(this.permisoEscritura) {
        let status = this.asistencias[index].asistencia.letra;
        let indexStatus = this.asistenciaStatus.findIndex(
          (x) => x.letra == status
        );
        indexStatus++;
        if (indexStatus == this.asistenciaStatus.length) indexStatus = 0;
        this.asistencias[index].asistencia = this.asistenciaStatus[indexStatus];
      }
    },
    closePicker: function(v){
      this.hora = v+":00";
      this.horaPicker = false;
      if(this.nuevo == false) this.modificarFechaAsistencia();
    },
    closeDatePicker: function(v){
      this.fechaPicker = false;
      if(this.nuevo == false) this.modificarFechaAsistencia()
    },
    async guardarAsistencia(){
      let asistencias = [];
      this.asistencias.forEach((asistencia) => {
        if (asistencia.asistencia.letra != "SC")
          asistencias.push({
            alumno: asistencia.alumno._id,
            asistencia: asistencia.asistencia.letra,
          });
      });
      let hora = parseInt(this.hora.split(':')[0]);
      let fecha = DateTime.fromISO(this.fecha, { zone: this.timezone });
      fecha = fecha.setZone(this.timezone).set({ hours: hora, minutes: 0 }).toISO();

      if (this.nuevo == true) {
        const data = { asistencias, fecha, duracion: this.duracion }
        this.nuevaAsistencia(data, this.idCurso);
      } else{
        const data = { asistencias, cursoAsistencia: this.asistenciaId }
        this.modificarAsistencia(data, this.idCurso);
      }
    },
    async nuevaAsistencia(data, idCurso){
      try {
        this.loading = true;
        const serverResponse = await registrarAsistenciaService(data, idCurso);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } 
        else {
          this.$emit("asistenciaCreada", serverResponse.asistencia);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async modificarAsistencia(data, idCurso){
      try {
        this.loading = true;
        const serverResponse = await modificarAsistenciaService(data, idCurso);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } 
        else {
          this.$emit("asistenciaModificada", serverResponse.asistencia);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    asistenciaEliminada(idAsistencia) {
      this.$emit("asistenciaEliminada", idAsistencia);
      this.eliminarAsistencia = false;
    },
    async modificarFechaAsistencia(){
      try {
        let hora = parseInt(this.hora.split(':')[0]);
        let fecha = DateTime.fromISO(this.fecha, { zone: this.timezone });
        fecha = fecha.setZone(this.timezone).set({ hours: hora, minutes: 0 }).toISO();

        let horaTmp = parseInt(this.horaOld.split(':')[0]);
        let fechaTmp = DateTime.fromISO(this.fechaOld, { zone: this.timezone });
        fechaTmp = fechaTmp.setZone(this.timezone).set({ hours: horaTmp, minutes: 0 }).toISO();

        const data = { fechaModificada: fecha, cursoAsistencia: this.asistenciaId }
        let serverResponse = null;
        if(fecha != fechaTmp) {
          this.loading = true;
          serverResponse = await modificarFechaAsistenciaService(data, this.idCurso);
          this.loading = false;

          if (!serverResponse.ok) {
            this.fecha = this.fechaOld;
            this.hora = this.horaOld;
            this.$systemErrorMessage(serverResponse.mensaje);
          } 
          else {
            this.fechaOld = this.fecha;
            this.horaOld = this.hora;
            this.$emit("asistenciaFechaModificada", serverResponse.fechaModificada);
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>

<style>
.link {
  text-decoration: none;
}
.numero-lista {
  margin-right: 10px !important;
  vertical-align: middle;
}
.avatar-lista {
  margin-right: 20px !important;
}
.columna {
  padding-bottom: 1px !important;
}
</style>