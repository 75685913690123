import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('datatable',{attrs:{"loading":_vm.loading,"datos":_vm.actividadesLocal,"pagination":_vm.pagination,"headers":_vm.headers,"totalItems":_vm.actividadesLocal.length,"hidePagination":"","hideSearch":""},scopedSlots:_vm._u([{key:"item-actividad",fn:function(ref){
var item = ref.item;
return [(_vm.sesionCursoAlumno || _vm.sesionCursoPadre)?_c('actividadAlumnoItemComponent',{attrs:{"idCurso":_vm.idCurso,"tipoCurso":_vm.tipoCurso,"tipoModulo":"modulo","actividad":item,"ocultarCriterio":"","ocultarIcono":""}}):_c('actividadItemComponent',{attrs:{"idCurso":_vm.idCurso,"tipoCurso":_vm.tipoCurso,"tipoModulo":"modulo","actividad":item,"ocultarCriterio":"","ocultarIcono":""}})]}},{key:"item-calificacion",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.calificacion))])]}},{key:"item-modulo",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(_vm._s(value.nombre))]):_c('span',[_c('i',[_vm._v("No definido")])])]}},{key:"item-criterio",fn:function(ref){
var value = ref.value;
return [(value)?_c(VListItem,{staticStyle:{"padding-left":"0px"},attrs:{"dense":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(value.nombre))]),_c(VListItemSubtitle,[_c('i',[_vm._v("Puntos: "+_vm._s(value.puntos))])])],1)],1):_c('span',[_c('i',[_vm._v("No definido")])])]}},{key:"item-ponderacion",fn:function(ref){
var value = ref.value;
return [(value == -1)?_c('span',[_vm._v("Promedio")]):_c('span',[_vm._v(_vm._s(value)+"%")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }