<template>
  <v-row>
    <v-col md="8" sm="12" cols="12">
      <v-card outlined class="my-2">
        <v-subheader class="font-weight-bold">Descripción</v-subheader>
        <v-divider class="mx-3"></v-divider>
        <v-skeleton-loader
          type="paragraph@3"
          style="margin: 20px"
        ></v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col md="4" sm="12" cols="12">
      <v-card outlined class="my-2">
        <v-subheader class="font-weight-bold">Evaluación</v-subheader>
        <v-divider class="mx-3"></v-divider>
       
      </v-card>
    </v-col>
  </v-row>
</template>

<script>


export default {
  name: "encuadreLoading",
 
  data() {
    return {
      loading: false,
    };
  },
};
</script>



