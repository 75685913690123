<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dark flat dense>
        <v-toolbar-title v-if="editarSeccion">Editar Sección</v-toolbar-title>
        <v-toolbar-title v-else>Agregar Sección</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn fab icon x-small @click="$emit('cancelar')">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <br />
      <v-form
        ref="formulario"
        v-model="esValido"
        lazy-validation
        class="formularioClass"
      >
        <v-text-field
          v-model="datosFormulario.nombre"
          outlined
          dense
          label="Nombre"
          :disabled="loading"
          :rules="[reglasComunes.valorRequerido('Nombre requerido')]"
        ></v-text-field>
        <v-select
          outlined
          dense
          v-model="datosFormulario.ponderacion"
          label="Ponderación:"
          :items="ponderaciones"
          :disabled="loading"
        ></v-select>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          outlined
          :disabled="loading"
          color="primary"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          small
          dark
          color="primary"
          :loading="loading"
          :class="{ 'disable-events': !esValido }"
          @click="guardarSeccion()"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  agregarSeccionCursoService,
  editarSeccionCursoService,
} from "./evaluacion.service.js";
import { validationMixin } from "vuelidate";

export default {
  props: {
    mostrar: Boolean,
    idCurso: String,
    tipoCurso: String,
    ponderacionMax: Number,
    editarSeccion: Object || null,
  },
  mixins: [validationMixin],
  computed: {
    datosFormulario() {
      let nombre = this.editarSeccion ? this.editarSeccion.nombre : "";
      let ponderacion = this.editarSeccion
        ? this.editarSeccion.ponderacion
        : -1;
      return { nombre, ponderacion };
    },
  },

  data: () => ({
    loading: false,
    ponderaciones: [],
    esValido: true,
    reglasComunes: {
      valorRequerido(textoParaMostrar) {
        return (v) => !!v || textoParaMostrar;
      },
    },
  }),
  created() {
    this.ponderaciones.push({ value: -1, text: "Promedio" });
    for (let i = 0; i <= this.ponderacionMax; i += 5)
      this.ponderaciones.push({ value: i, text: i + "%" });
  },
  methods: {
    async guardarSeccion() {
      if (this.$refs.formulario.validate()) {
        try {
          this.loading = true;
          const data = {
            nombre: this.datosFormulario.nombre,
            ponderacion: this.datosFormulario.ponderacion,
          };

          let serverResponse = null;
          if (this.editarSeccion)
            serverResponse = await editarSeccionCursoService(
              data,
              this.tipoCurso,
              this.idCurso,
              this.editarSeccion._id
            );
          else
            serverResponse = await agregarSeccionCursoService(
              data,
              this.tipoCurso,
              this.idCurso
            );
          this.loading = false;
          if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
          else {
            if (!this.editarSeccion)
              this.$emit("seccionAgregada", serverResponse.nuevaSeccion);
            else
              this.$emit(
                "seccionActualizada",
                serverResponse.seccionesActualizadas
              );

            this.$emit(
              "actualizarPuntosSecciones",
              serverResponse.seccionesActualizadas
            );
            this.$emit("cancelar");
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },
  },
};
</script>

<style scoped>
.formularioClass {
  padding: 15px;
  padding-bottom: 0;
}
</style>
