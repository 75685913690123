import { getServerRequest, postServerRequest, putServerRequest, patchServerRequest } from '../../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function obtenerAreasService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/plantilla/areas`;
    return await postServerRequest(url, data, config);
}

export async function obtenerCarrerasService(modeloEducativo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/plantilla/carreras/${modeloEducativo}`;
    return await getServerRequest(url, config);
}

export async function obtenerPlantillasService(idArea) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/plantilla/plantillas/${idArea}`;
    return await getServerRequest(url, config);
}

export async function crearAreaService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/plantilla/area`;
    return await putServerRequest(url, data, config);
}

export async function crearPlantillaService(data, idArea) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/plantilla/${idArea}`;
    return await putServerRequest(url, data, config);
}

export async function modificarAreaService(data, idArea) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/plantilla/area/${idArea}`;
    return await patchServerRequest(url, data, config);
}

export async function cambioAreaService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/plantilla/mover/${data.idPlantilla}/${data.idArea}`;
    return await patchServerRequest(url, {}, config);
}

export async function clonarPlantillaService(idPlantilla) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/plantilla/clonar/${idPlantilla}`;
    return await patchServerRequest(url, {}, config);
}

export async function importarPlantillaService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/plantilla/importar/from/empresa`;
    return await patchServerRequest(url, data, config);
}

export async function clonarPlantillaImportadaService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/plantilla/clonar/importada/from/empresa`;
    return await patchServerRequest(url, data, config);
}

export async function renovarCodigoPlantillaService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/plantilla/renovar/codigo/plantilla`;
    return await patchServerRequest(url, data, config);
}

export async function aplicarPlantillaService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/plantilla/aplicar/plantilla`;
    return await patchServerRequest(url, data, config);
}

export async function obtenerDescripcionCursoService(tipoCurso,idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/${tipoCurso}/encuadre/descripcion/${idCurso}`;
    return await getServerRequest(url,config);
}

export async function modificarDescripcionCursoService(data,tipoCurso,idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/${tipoCurso}/encuadre/descripcion/${idCurso}`;
    return await patchServerRequest(url,data,config);
}

export async function modificarPlantillaIconService(data) {
    const token = store.getters.sessionToken;
    const config = { 
        headers: { token },
        ...data.uploadHandler
    };
    const url = `${httpURL}/curso/${data.tipoCurso}/icono/${data.idCurso}`;
    return await patchServerRequest(url, data.image, config);
}