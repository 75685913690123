import { getServerRequest, putServerRequest, patchServerRequest, deleteServerRequest } from '../../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function obtenerRecursosService(tipoCurso, idCurso, idModulo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/modulo/recursos/${idCurso}/${idModulo}`;
    return await getServerRequest(url, config);
}

export async function agregarRecursoService(data, tipoCurso, idCurso, idModulo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/modulo/recurso/enlace/${idCurso}/${idModulo}`;
    return await putServerRequest(url, data, config);
}

export async function modificarRecursoService(data, tipoCurso, ids) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/modulo/recurso/${ids.idCurso}/${ids.idRecurso}`;
    return await patchServerRequest(url, data, config);
}

export async function modificarVisibleRecursoService(idCurso, idRecurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/modulo/recurso/visible/${idCurso}/${idRecurso}`;
    return await patchServerRequest(url, {}, config);
}

export async function eliminarRecursoService(tipoCurso, idCurso, idRecurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/modulo/recurso/eliminar/${idCurso}/${idRecurso}`;
    return await deleteServerRequest(url, config);
}

export async function cambiarOrdenRecursosService(data, tipoCurso, ids) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/modulo/recurso/mover/${ids.idCurso}/${ids.idRecurso}`;
    return await patchServerRequest(url, data, config);
}

export async function moverRecursoService(data, tipoCurso, ids) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/modulo/cambiar/recurso/${ids.idCurso}/${ids.idModulo}/${ids.idRecurso}`;
    return await patchServerRequest(url, data, config);
}