<template>
  <v-container>
    <v-card outlined>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon x-large color="primary">mdi-card-text-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="title" style="white-space: normal">
            Descripción
          </v-list-item-title>
        </v-list-item-content>

        <v-tooltip v-if="permisoEscritura && !vistaSeguimiento" top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="title"
              x-small
              icon
              color="primary"
              :disabled="loading"
              @click="edicionHabilitada = true"
            >
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>Modificar descripción</span>
        </v-tooltip>
      </v-list-item>
      <v-divider class="mx-3"></v-divider>

      <!-- <v-skeleton-loader
        v-if="loading"
        type="paragraph@3"
        style="margin: 20px"
      ></v-skeleton-loader> -->
      <!-- ------- -->
      <v-container>
        <div v-if="edicionHabilitada">
          <editor-texto
            ref="editorTexto"
            :texto="descripcion"
            :app="app"
            :contenido="tipoCursoCompleto"
            :idContenido="idCurso"
          ></editor-texto>
          <div style="text-align: right">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="margen"
              outlined
              small
              :disabled="loading"
              @click="edicionHabilitada = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              class="margen"
              dark
              small
              :loading="loading"
              @click="modificarDescripcion()"
            >
              Guardar
            </v-btn>
          </div>
        </div>
        <template v-else>
          <div v-if="!descripcion">
            <i>No se ha capturado una descripción</i>
          </div>
          <div v-else v-html="descripcion"></div>
        </template>
      </v-container>
      <!-- ------- -->
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import editorTexto from "../../contenidos/editorTexto.vue";
import {
  obtenerDescripcionCursoService,
  modificarDescripcionCursoService,
} from "../../cursos/listadoCursos/cursoPlantilla/cursoPlantilla.service";
import { obtenerDescripcionCursoControlParental } from "../../controlParental/controlParental.service";

export default {
  name: "descripcionCurso",
  props: {
    permisoEscritura: { type: Boolean, default: false },
  },
  components: { "editor-texto": editorTexto },
  data: () => ({
    loading: false,
    tipoCurso: null,
    idCurso: null,
    descripcion: null,
    edicionHabilitada: false,
  }),
  computed: {
    ...mapGetters(["sessionToken", "app", "sesionCursoPadre", "vistaSeguimiento"]),

    tipoCursoCompleto() {
      return this.tipoCurso === "campus" ? "cursoCampus" : "cursoPlantilla";
    },
  },
  watch: {
    sessionToken() {
      this.cargarDescripcion();
    },
    descripcion: function (nuevo) {
      if (this.descripcion != null)
        this.texto = this.descripcion.replace(/(<([^>]+)>)/gi, "");
      // this.texto = this.text.replace(/\\/g,'');
    },
  },
  created() {
    this.idCurso = this.$route.params.idCurso;
    this.tipoCurso = this.$route.params.tipoCurso;
    if (this.sessionToken) this.cargarDescripcion();
  },
  methods: {
    async cargarDescripcion() {
      try {
        this.loading = true;
        let serverResponse = null;
        if(this.sesionCursoPadre) serverResponse = await obtenerDescripcionCursoControlParental(this.idCurso);
        else serverResponse = await obtenerDescripcionCursoService(this.tipoCurso, this.idCurso);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.descripcion = serverResponse.descripcion;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async modificarDescripcion() {
      try {
        this.loading = true;
        const data = { descripcion: this.$refs.editorTexto.obtenerTexto() };

        const serverResponse = await modificarDescripcionCursoService(
          data,
          this.tipoCurso,
          this.idCurso
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.edicionHabilitada = false;
          this.descripcion = serverResponse.descripcion;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
