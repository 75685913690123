<template>
  <v-chip :color="status.color" dark x-small><strong>{{ status.texto }}</strong></v-chip>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "genericoChip",
  props: {
    disponible: { type: Object },
    entregaExt: { type: Boolean, default: false },
  },
  computed: {
    status() {
      let statusData = { color: "green darken-1", texto: "Disponible" };
      if (!this.disponible.abierta) {
        var fechaHoy = DateTime.now();
        var fechaInicio = DateTime.fromISO(this.disponible.inicio);
        var fechaFin = DateTime.fromISO(this.disponible.fin);

        if (fechaHoy < fechaInicio)
          statusData = { color: "red darken-1", texto: "Cerrada" };

        if (fechaHoy > fechaFin) {
          if (this.entregaExt)
            statusData = {
              color: "orange darken-1",
              texto: "Disponible extemporáneo",
            };
          else statusData = { color: "red darken-1", texto: "Cerrada" };
        }
      }

      return statusData;
    },
  },
};
</script>
