import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"flat":""}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VSpacer),_vm._v(" Actividades "),_c(VSpacer)],1),(_vm.loading)?_c('actividadesLoadingComponent'):_c(VCard,{attrs:{"outlined":""}},[_c('div',[_c(VRow,{staticStyle:{"margin":"5px"}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VSelect,{attrs:{"outlined":"","dense":"","label":"Módulo","items":_vm.modulos,"loading":_vm.loading,"item-value":"_id","item-text":"nombre"},on:{"change":function($event){return _vm.filtrarActividades()}},model:{value:(_vm.moduloSelect),callback:function ($$v) {_vm.moduloSelect=$$v},expression:"moduloSelect"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VSelect,{attrs:{"outlined":"","dense":"","label":"Criterios","items":_vm.criterios,"loading":_vm.loading,"item-value":"_id","item-text":"nombre"},on:{"change":function($event){return _vm.filtrarActividades()}},model:{value:(_vm.criterioSelect),callback:function ($$v) {_vm.criterioSelect=$$v},expression:"criterioSelect"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('actividadesTablaComponent',{attrs:{"idCurso":_vm.idCurso,"tipoCurso":_vm.tipoCurso,"actividades":_vm.actividadesFiltradas}})],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }