import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":500,"persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"danger","dark":"","dense":"","flat":""}},[_vm._v(_vm._s(_vm.titulo))]),_c(VContainer,{attrs:{"grid-list-md":""}},[_c('b',[_vm._v(_vm._s(_vm.leyenda))]),_c('br'),_c('p',[_c('b',[_vm._v("•")]),_vm._v(" Todas las actividades y calificaciones serán borradas de manera permanente.")]),_c('i',[_vm._v("Esta acción no podrá ser revertida.")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.cerrarForm()}}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"red","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.eliminarIntegrante()}}},[_vm._v(" Eliminar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }