<template>
  <v-dialog v-model="mostrar" persistent :max-width="500">
    <v-card>
      <v-toolbar dense color="danger" dark flat class="text-h6"
        >Eliminar sección</v-toolbar
      >
      <v-container grid-list-md>
        <p><b>¿Está seguro de eliminar la sección?</b> <br /></p>
        <ul>
          <li>{{ seccion.nombre }}</li>
        </ul>
        <p>
          Con esta acción se eliminarán los criterios asociados y todas las
          actividades relacionadas quedarán sin criterio y con puntuación cero.
        </p>
        <v-alert dense type="warning" border="left">
          Esta acción no podrá ser revertida.
        </v-alert>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          small
          outlined
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn color="danger" small dark :loading="loading" @click="eliminar()"
          >Eliminar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { eliminarSeccionCursoService } from "./evaluacion.service.js";
export default {
  name: "eliminarSeccion",
  props: {
    mostrar: Boolean,
    seccion: Object,
    idCurso: String,
    tipoCurso: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async eliminar() {
      try {
        this.loading = true;
        const serverResponse = await eliminarSeccionCursoService(
          this.tipoCurso,
          this.idCurso,
          this.seccion._id
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("seccionEliminada", this.seccion._id);
          this.$emit(
            "actualizarPuntosSecciones",
            serverResponse.seccionesActualizadas
          );
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
