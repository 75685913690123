<template>
  <v-container>
    <v-card flat>
      <v-toolbar dense color="secondary" dark flat class="text-h6">
        <v-spacer></v-spacer> Profesores <v-spacer></v-spacer>
        <v-icon v-if="permisoEscritura && app != 'campus'" small @click="crearModulo()">
          mdi-text-box-plus
        </v-icon>
      </v-toolbar>
      <profesoresLoadingComponent v-if="loading" />
      <template v-else>
        <v-row v-if="modulos.length > 0">
          <v-col md="12" sm="12" v-for="modulo in modulos" :key="modulo">
            <moduloItemComponent
              :tipoCurso="tipoCurso"
              :idModulo="modulo"
              :cursoPlantilla="cursoPlantilla"
              :idCurso="idCurso"
              :tipoModulo="tipo"
              :permisoEscritura="permisoEscritura"
              @moduloEliminado="moduloEliminado"
            />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col md="12" sm="12">
            <v-card outlined class="my-2" style="text-align: center">
              No hay módulos registrados
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-card>

    <agregarModuloComponent
      v-if="agregarModulo"
      :mostrar="agregarModulo"
      :idCurso="idCurso"
      :tipoCurso="tipoCurso"
      :tipo="tipo"
      :editarModulo="editarModulo"
      @cancelar="(agregarModulo = false), (editarModulo = null)"
      @moduloRegistrado="moduloRegistrado"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { obtenerModulosProfesorService } from "../modulos/modulos.service";
import profesoresLoadingComponent from "./profesoresLoading.vue";
import agregarModuloComponent from "../modulos/agregarModulo.vue";
import moduloItemComponent from "../modulos/moduloItem.vue";

export default {
  name: "profesoresTab",
  props: {
    tipoCurso: String,
    idCurso: String,
    cursoPlantilla: {type: String, default: null},
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    profesoresLoadingComponent,
    agregarModuloComponent,
    moduloItemComponent,
  },
  computed: {
    ...mapGetters(["role", "sessionToken", "app"]),
    puedeAgregarModulo() {
      let puede = false;
      if (this.role == "admin") puede = true;
      return puede;
    },
    localModulos() {
      let puede = false;
      if (this.modulos && this.modulos.length > 0) {
        puede = true;
      }
      return puede;
    },
  },
  watch: {
    sessionToken() {
      this.obtenerModulos();
    },
  },
  data() {
    return {
      loading: false,
      modulos: [],
      tipo: "moduloProfesor",
      agregarModulo: false,
      editarModulo: null,
    };
  },
  created() {
    if (this.sessionToken) this.obtenerModulos();
  },
  methods: {
    async obtenerModulos() {
      try {
        this.modulos = [];
        this.loading = true;
        let idCurso = this.idCurso;
        if (this.cursoPlantilla && this.app == 'campus') idCurso = this.cursoPlantilla;
        const serverResponse = await obtenerModulosProfesorService(
          this.tipoCurso,
          idCurso
        );
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.modulos = serverResponse.modulos;
        }
      } catch (error) {
        this.$appErrorMessage();
      }
    },
    crearModulo() {
      this.agregarModulo = true;
    },
    moduloRegistrado(curso) {
      this.modulos.push(curso);
    },
    moduloEliminado(modulo) {
      const index = this.modulos.findIndex((idModulo) => idModulo == modulo);
      if (index >= 0) this.modulos.splice(index,1)
    },
  },
};
</script>
