<template>
  <v-container>
    <v-card flat>
      <v-toolbar dense color="secondary" dark flat class="text-h6"> 
        <v-spacer></v-spacer> Avisos <v-spacer></v-spacer>
        <v-tooltip bottom v-if="permisoEscritura && !vistaSeguimiento">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="white" icon v-bind="attrs" v-on="on">
              <v-icon @click="agregarAviso = true">
                mdi-text-box-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Agregar aviso</span>
        </v-tooltip>   
      </v-toolbar>



      <v-card outlined>

        <v-container v-if="loading" fluid>
          <avisoLoadingComponent />
        </v-container>

        <v-container v-if="avisos.length == 0 && !loading" fluid>
          <v-card outlined class="text-left">
            <v-card-subtitle>No se han registrado avisos </v-card-subtitle>
          </v-card>
        </v-container>

        <v-container v-else fluid>
          <v-card outlined class="mb-2" v-for="(aviso, index) in avisos" :key="index">

            <!-- TIPO PROFESOR -->
            <avisoProfesorComponent 
              v-if="tipoCuentaUsuario === 'profesor'"
              :idCurso="idCurso"
              :tipoCurso="tipoCurso"
              :permisoEscritura="permisoEscritura"
              :aviso="aviso"
              @avisoModificado="avisoModificado"
              @avisoVisibleModificado="avisoVisibleModificado"
              @avisoConfirmacionModificado="avisoConfirmacionModificado"
              @avisoEliminado="avisoEliminado"
            />

            <!-- TIPO ALUMNO -->
            <avisoAlumnoComponent 
              v-if="tipoCuentaUsuario === 'alumno'"
              :idCurso="idCurso"
              :tipoCurso="tipoCurso"
              :aviso="aviso"
              :permisoEscritura="permisoEscritura"
              @avisoVisto="avisoVisto"
              @avisoVistoConfirmado="avisoVistoConfirmado"
            />
            
          </v-card>
        </v-container>

      </v-card>
    </v-card>

    <agregarAvisoComponent 
      v-if="agregarAviso"
      :mostrar="agregarAviso"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      @cancelar="agregarAviso = false"
      @nuevoAviso="nuevoAviso"
    />

  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import avisoLoadingComponent from "./avisosLoading.vue";
import agregarAvisoComponent from "./agregarAviso.vue";
import avisoProfesorComponent from "./avisoProfesor.vue";
import avisoAlumnoComponent from "./avisoAlumno.vue";
import { obtenerAvisosService } from "./avisos.service.js";

export default {
  name: "avisosTab",
  props: {
    tipoCurso: { type: String, default: null },
    idCurso: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    avisoLoadingComponent,
    agregarAvisoComponent,
    avisoProfesorComponent,
    avisoAlumnoComponent
  },
  computed: {
    ...mapGetters(["app", "tipoSesion", "vistaSeguimiento"]),
    tipoCuentaUsuario() {
      let usuario = '';
      if(this.tipoSesion.includes('profesor') || this.vistaSeguimiento)
        usuario = 'profesor';
      else usuario = 'alumno'
      return usuario;
    }
  },
  data() {
    return {
      loading: true,
      avisos: [],
      moment: null,
      agregarAviso: false,
    };
  },
  mounted() {
    this.cargarAvisos();
  },
  methods: {
    async cargarAvisos() {
      try {
        this.loading = true;
        let serverResponse = null;
        if(this.app == "campus" || this.vistaSeguimiento) {
          serverResponse = await obtenerAvisosService(this.tipoCurso, this.idCurso, this.tipoCuentaUsuario);
        }
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.avisos = serverResponse.avisos.reverse();
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    nuevoAviso(data) {
      data.visto = [];
      this.avisos.unshift(data);
      this.agregarAviso = false;
    },
    avisoModificado(data) {
      const indexAviso = this.avisos.findIndex((aviso) => aviso._id == data._id);
      this.avisos[indexAviso].titulo = data.titulo;
      this.avisos[indexAviso].descripcion = data.descripcion;
    },
    avisoVisibleModificado(data) {
      const indexAviso = this.avisos.findIndex((aviso) => aviso._id == data.idAviso);
      this.avisos[indexAviso].visible = data.visible;
    },
    avisoConfirmacionModificado(data) {
      const indexAviso = this.avisos.findIndex((aviso) => aviso._id == data.idAviso);
      this.avisos[indexAviso].confirmacion = data.confirmacion;
    },
    avisoEliminado(idAviso) {
      const index = this.avisos.findIndex((aviso) => aviso._id == idAviso);
      if(index >= 0) this.avisos.splice(index, 1);
    },
    avisoVisto(data) {
      const indexAviso = this.avisos.findIndex((aviso) => aviso._id === data.idAviso);
      this.avisos[indexAviso].idAvisoVisto = data.idAvisoVisto;
      this.avisos[indexAviso].visto = true;
      this.avisos[indexAviso].confirmado = false;
    },
    avisoVistoConfirmado(data) {
      const indexAviso = this.avisos.findIndex((aviso) => aviso._id == data.idAviso);
      this.avisos[indexAviso].confirmado = data.confirmado;
    }
  }
};
</script>

<style>
/* 
mdi-comment-plus-outline
mdi-plus-circle-outline

*/
.title-action {
  margin-right: 5px !important;
}
.margen-aviso {
  margin: 10px;
}
.margen-visto {
  margin-right: 15px !important;
  margin-bottom: 10px !important;
}

</style>
