<template>
  <div>
    <v-icon 
      v-if="loading" 
      large
      color="primary"
      >mdi-all-inclusive</v-icon
    >
    <v-tooltip v-else top>
      <template v-slot:activator="{ on }">
          <v-icon
            x-large
            v-on="on"
            v-if="visible"
            :disabled="deshabilitado"
            color="green"
            @click="modificarVisible()"
            >mdi-toggle-switch</v-icon
          >
          <v-icon x-large v-on="on" v-else color="grey"
          :disabled="deshabilitado"
            @click="modificarVisible()"
            >mdi-toggle-switch-off</v-icon
          >
      </template>
      <span v-if="visible">Ocultar módulo</span>
      <span v-else>Mostrar módulo</span>
    </v-tooltip>
  </div>
</template>

<script>

import {modificarModuloVisibleService} from './modulos.service';

export default {
  name: "moduloSwitch",
  props:{
    idCurso: String,
    idModulo: String,
    visible: Boolean,
    deshabilitado: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods:{
    async modificarVisible(){
      try {
        this.loading = true;
        const serverResponse = await modificarModuloVisibleService(this.idCurso, this.idModulo);
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else
          this.$emit('moduloVisibleModificado', serverResponse.visible)
        
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    }
  }
};
</script>
