<template>
  <v-list-item>
    <v-list-item-icon
      v-if="!ocultarIcono"
      :class="{ handle: dragState == true }"
      @mouseover="permisoEscritura  && !vistaSeguimiento ? (dragState = true) : null"
      @mouseout="permisoEscritura ? (dragState = false) : null"
    >
      <v-icon v-if="dragState" color="grey">mdi-drag</v-icon>
      <v-icon v-else color="primary">{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        <switchMiniComponent
          v-if="tipoCurso == 'campus' && !vistaSeguimiento && permisoEscritura"
          :active="actividad.visible"
          :loading="loading"
          :disabled="!permisoEscritura"
          @activate="modificarVisible(actividad._id)"
          @deactivate="modificarVisible(actividad._id)"
        />
        <a href="javascript:void(0);" class="link" @click="verActividad()">
          {{ actividad.nombre }}
        </a>
      </v-list-item-title>
      <v-list-item-subtitle v-if="!ocultarCriterio">
        <span v-if="vista == 'general'">Módulo: {{ nombreModulo }}.</span>
        <span v-if="actividad.criterio">
          {{ actividad.criterio.nombre }}
        </span>
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <p v-if="vista == 'general'">
          Ponderación: {{ actividad.ponderacion }}
        </p>
        <p v-if="vista == 'general'">Puntos: {{ actividad.puntos }}.</p>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="tipoCurso == 'campus'">
        <genericoChipComponent
          :icon="actividad.entrega == 1 ? 'mdi-account' : 'mdi-account-multiple'"
        />
        <genericoChipComponent
          :text="actividad.pendientes"
          :icon="actividad.pendientes == 0 ? 'mdi-tray' : 'mdi-tray-full'"
        />
        <genericoChipComponent
          :text="`${actividad.porcentajeEntrega}%`"
          icon="mdi-account-check"
        />
        <genericoChipComponent
          :text="actividad.calificacionPromedio"
          icon="mdi-chart-box-outline"
        />
        <actividadDisponibleChip
          v-if="!actividad.examenAplicacion"
          :disponible="actividad.disponible"
          :entregaExt="actividad.entregaExt"
        />
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon v-if="actividad.calificacionAlumno">
      {{ actividad.calificacionAlumno.calificacion }}
    </v-list-item-icon>
    <v-list-item-action>
      <actividadesMenuComponent
        v-if="permisoEscritura && !vistaSeguimiento"
        :idActividad="actividad._id"
        :actividad="actividad"
        @eliminarActividad="eliminarActividad"
      />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import { store } from "@/store/store";
import switchMiniComponent from "../../../widgets/switchMini.vue";
import genericoChipComponent from "../../../widgets/chips/genericoChip.vue";
import actividadDisponibleChip from "../../../widgets/chips/actividadDisponibleChip.vue";
import { modificarActividadVisibleService } from "./actividades.service";
import actividadesMenuComponent from "./actividadesMenu.vue"

export default {
  name: "actividadItem",
  props: {
    idCurso: String,
    tipoCurso: String,
    tipoModulo: String,
    actividad: Object,
    vista: { type: String, default: "modular" },
    permisoEscritura: { type: Boolean, default: false },
    ocultarCriterio: { type: Boolean, default: false },
    ocultarIcono: { type: Boolean, default: false },
  },
  components: {
    switchMiniComponent,
    genericoChipComponent,
    actividadDisponibleChip,
    actividadesMenuComponent
  },
  computed: {
    ...mapGetters([
      "actividadDirectaIcon",
      "actividadArchivoIcon",
      "actividadForoIcon",
      "actividadEncuestaIcon",
      "examenIcon",
      "agregarContenidoIcon",
      "vistaSeguimiento",
    ]),
    icon() {
      if (this.actividad.tipo == 1) return this.actividadDirectaIcon;
      if (this.actividad.tipo == 2) return this.actividadArchivoIcon;
      if (this.actividad.tipo == 3) return this.actividadForoIcon;
      if (this.actividad.tipo == 4) return this.actividadEncuestaIcon;
      return "mdi-file-outline";
    },
    nombreModulo() {
      if (this.tipoCurso == "plantilla")
        return this.actividad.cursoPlantillaModulo.nombre;
      else return this.actividad.cursoModulo.nombre;
    },
  },
  data() {
    return {
      app: store.getters.app,
      loading: false,
      dragState: false,
    };
  },
  methods: {
    verActividad() {
      // if (this.vista == 'modulo')
      if(this.actividad.examenAplicacion && this.actividad.examenAplicacion == true) {
        const params = {
        idCurso: this.idCurso,
        tipoCurso: this.tipoCurso,
        idModulo: this.actividad.cursoModulo._id,
        idAplicacion: this.actividad._id,
      };
        this.$router.push({name: "ExamenAplicacion", params});
      } else {
        this.$router.push(`/curso/${this.tipoCurso}/${this.idCurso}/${this.tipoModulo}/vista/${this.vista}/actividad/${this.actividad._id}`);
      }
    },
    async modificarVisible(idActividad) {
      if (this.permisoEscritura) {
        try {
          this.loading = true;
          const serverResponse = await modificarActividadVisibleService(
            this.idCurso,
            idActividad
          );
          this.loading = false;
          if (!serverResponse.ok) {
            this.$systemErrorMessage(serverResponse.mensaje);
          } else {
            const emitData = {
              idActividad,
              visible: serverResponse.actividad.visible,
            };
            this.$emit("actividadVisibleModificado", emitData);
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },
    eliminarActividad(idActividad){
      this.$emit("actividadCambiada", idActividad);
    }
  },
};
</script>
<style scoped>
p {
  margin: 0px 0px 0px 15px;
  display: inline;
}
</style>
