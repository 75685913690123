<template>
  <div>

    <v-card-title class="pt-0">
      <v-col style="padding: 0px;">
        <v-card-title style="font-size: medium; padding: 0px; margin: 5px;">{{ aviso.titulo }}</v-card-title>
        <v-card-subtitle style="font-size: small; padding: 5px;">{{ moment(aviso.fecha).format('DD [de] MMMM [de] YYYY') }}</v-card-subtitle>
      </v-col>
    </v-card-title>

    <v-card-text class="text-h8">
      {{ aviso.descripcion }}
    </v-card-text>

    <v-card-actions v-if="aviso.confirmacion && permisoEscritura" class="d-flex justify-end mr-2 mb-1">
      <v-btn small color="primary" :outlined="mostrarConfirmado" @click="confirmarAviso">{{ mensaje }}</v-btn>
    </v-card-actions>

    <eliminarConfirmacionComponent 
      v-if="eliminarConfirmacion"
      :mostrar="eliminarConfirmacion"
      @cancelar="eliminarConfirmacion = false"
      @modificarConfirmarAviso="modificarConfirmarAviso"
    />

  </div>
</template>

<script>
import moment from "moment-timezone";
moment.locale('es');

import eliminarConfirmacionComponent from "./eliminarConfirmacion.vue";
import { registrarAvisoVisto, confirmarAvisoVisto } from "./avisos.service.js";

export default {
  name: 'avisoAlumno',
  props: {
    idCurso: { type: String, default: null },
    tipoCurso: { type: String, default: null },
    aviso: { type: Object, default: () => {} },
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    eliminarConfirmacionComponent
  },
  computed: {
    mensaje() {
      if(this.mostrarConfirmado) return 'Confirmado';
      return 'Confirmar';
    }
  },
  data () {
    return {
      loading: false,
      mostrarConfirmado: false,
      eliminarConfirmacion: false,
    }
  },
  async created() {
    this.moment = moment;
    if(!this.aviso.visto) {
      await this.agregarVisto();
    } else this.mostrarConfirmado = this.aviso.confirmado;
  },
  methods: {
    async agregarVisto() {
      try {
        this.loading = true;
        const serverResponse = await registrarAvisoVisto(this.tipoCurso, this.idCurso, this.aviso._id);
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.mostrarConfirmado = false;
          const resData = {
            idAviso: this.aviso._id,
            idAvisoVisto: serverResponse.idAvisoVisto
          };
          this.$emit('avisoVisto', resData);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async modificarConfirmarAviso() {
      try {
        this.eliminarConfirmacion = false;
        this.loading = true;
        const data = { confirmado: !this.mostrarConfirmado}
        const serverResponse = await confirmarAvisoVisto(this.tipoCurso, this.idCurso, this.aviso._id, this.aviso.idAvisoVisto, data);
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          const resData = {
            idAviso: this.aviso._id,
            confirmado: serverResponse.confirmado
          };
          this.mostrarConfirmado = resData.confirmado;
          this.$emit('avisoVistoConfirmado', resData);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    confirmarAviso() {
      if(this.aviso.confirmado) this.eliminarConfirmacion = true;
      else this.modificarConfirmarAviso();
    }
  }
  
}
</script>