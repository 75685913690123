<template>
  <div>
  <v-card-title class="pt-0">
    <v-switch
      v-if="!vistaSeguimiento && permisoEscritura" 
      v-model="aviso.visible"
      color="green darken-"
      inset 
      dense
      :disabled="loading || loadingVisibilidad"
      :loading="loadingVisibilidad"
      @change="visibilidadAviso"
    >
    </v-switch>
    
     <!-- ICONO 
    <v-icon left color="orange darken-3">
      mdi-bell-ring-outline
    </v-icon>  -->
    
    <v-col style="padding: 0px;">
      <v-card-title style="font-size: medium; padding: 0px; margin: 5px;">{{ aviso.titulo }}</v-card-title>
      <v-card-subtitle style="font-size: small; padding: 5px;">{{ moment(aviso.fecha).format('DD [de] MMMM [de] YYYY') }}</v-card-subtitle>
    </v-col>

    <v-spacer></v-spacer>
    <avisosMenuComponent
      v-if="!vistaSeguimiento && permisoEscritura"
      :avisoConfirmacion="aviso.confirmacion"
      @confirmacionAviso="confirmacionAviso" 
      @modificarAviso="modificarAviso"
      @eliminarAviso="eliminarAviso"
    />
  </v-card-title>

  
  <v-card-text class="text-h8">
    {{ aviso.descripcion }}
  </v-card-text>

  <v-row class="d-flex justify-end mr-2 mb-1">
    <v-card-actions v-if="aviso.confirmacion" class="pr-0">
      <v-btn small text style="color:darkgrey;" @click="mostrarConfirmados = true" :disabled="avisoConfirmados.length == 0"><v-icon>mdi-check</v-icon></v-btn>
      <v-card-subtitle v-if="avisoConfirmados.length == 0" style="color:darkgrey; font-size: 15px; padding: 0px;"> Sin confirmados aún</v-card-subtitle>
      <v-card-subtitle v-else style="color:darkgrey; font-size: 15px; padding: 0px;">{{ avisoConfirmados.length }}</v-card-subtitle>
    </v-card-actions>

    <v-card-actions class="pl-2">
      <v-btn small text style="color:darkgrey;" @click="mostrarVistos = true" :disabled="aviso.visto.length == 0"><v-icon>mdi-eye</v-icon></v-btn>
      <v-card-subtitle v-if="aviso.visto.length == 0" style="color:darkgrey; font-size: 15px; padding: 0px;"> Sin vistas aún</v-card-subtitle>
      <v-card-subtitle v-else style="color:darkgrey; font-size: 15px; padding: 0px;">{{ aviso.visto.length }}</v-card-subtitle>
    </v-card-actions>
  </v-row>

  <agregarAvisoComponent 
    v-if="editarAviso.mostrar"
    :mostrar="editarAviso.mostrar"
    :tipoCurso="tipoCurso"
    :idCurso="idCurso"
    :aviso="editarAviso.aviso"
    @cancelar="cancelar"
    @avisoModificado="avisoModificado"
  />

  <eliminarAvisoComponent 
    v-if="eliminar.mostrar"
    :mostrar="eliminar.mostrar"
    :tipoCurso="tipoCurso"
    :idCurso="idCurso"
    :aviso="eliminar.aviso"
    @cancelar="cancelarEliminar"
    @avisoEliminado="avisoEliminado"
  />

  <listadoAvisosVistosComponent 
    v-if="mostrarVistos"
    :mostrar="mostrarVistos"
    :vistos="aviso.visto"
    @cancelar="mostrarVistos = false"
  />

  <listadoAvisosConfirmadosComponent 
    v-if="mostrarConfirmados"
    :mostrar="mostrarConfirmados"
    :confirmados="avisoConfirmados"
    @cancelar="mostrarConfirmados = false"
  />

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
moment.locale('es');

import avisosMenuComponent from "./avisosMenu.vue";
import agregarAvisoComponent from "./agregarAviso.vue";
import eliminarAvisoComponent from "./eliminarAviso.vue";
import listadoAvisosVistosComponent from "./listadoAvisosVistos.vue";
import listadoAvisosConfirmadosComponent from "./listadoAvisosConfirmados.vue";
import { modificarVisibilidadAvisoService, modificarConfirmacionAvisoService } from "./avisos.service.js";

export default {
  name: 'avisoProfesor',
  props: {
    idCurso: { type: String, default: null },
    tipoCurso: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
    aviso: { type: Object, default: () => {} },
  },
  components: {
    avisosMenuComponent,
    agregarAvisoComponent,
    eliminarAvisoComponent,
    listadoAvisosVistosComponent,
    listadoAvisosConfirmadosComponent
  },
  computed: {
    ...mapGetters(["vistaSeguimiento"]),
    avisoConfirmados() {
      let confirmados = [];
      this.aviso.visto.forEach(visto => {
        if(visto.confirmado) confirmados.push(visto);
      });
      // La función de comparación devuelve el resultado de la diferencia de tiempo calculada. 
      // Esto determinará el orden de los elementos en el arreglo confirmados. 
      // Si el resultado es negativo, significa que b debe colocarse antes que a en el orden. 
      // Si es positivo, a debe colocarse antes que b. 
      // Si es cero, se considera que los elementos son iguales en términos de ordenamiento.
      // 'diff()': Es un método de Moment.js que calcula la diferencia entre dos momentos en términos de duración.
      confirmados.sort((a,b) => {
        return moment.utc(b.fechaConfirmado).diff(moment.utc(a.fechaConfirmado))
      });
      
      return confirmados;
    }
  },
  data () {
    return {
      loading: false,
      loadingVisibilidad: false,
      editarAviso: {
        mostrar: false,
        aviso: null
      },
      eliminar: {
        mostrar: false,
        aviso: null
      },
      mostrarVistos: false,
      mostrarConfirmados: false,
    }
  },
  created() {
    this.moment = moment;
  },
  methods: {
    async visibilidadAviso() {
      try {
        this.loading = true;
        this.loadingVisibilidad = true;

        const idAviso = this.aviso._id;
        const data = { visible: this.aviso.visible};

        const serverResponse = await modificarVisibilidadAvisoService(this.tipoCurso, this.idCurso, idAviso, data);
        this.loading = false;
        this.loadingVisibilidad = false;
        if(!serverResponse.ok){
          this.aviso.visible = !this.aviso.visible;
          this.$systemErrorMessage(serverResponse.mensaje);
        }
        else {
          const resData = {
            idAviso,
            visible: serverResponse.visible
          };
          this.$emit('avisoVisibleModificado', resData);
        }
      } catch (error) {
        this.loading = false;
        this.loadingVisibilidad = false;
        this.$appErrorMessage();
      }
    },
    async confirmacionAviso() {
      try {
        this.loading = true;

        const idAviso = this.aviso._id;
        const data = { confirmacion: !this.aviso.confirmacion };
        
        const serverResponse = await modificarConfirmacionAvisoService(this.tipoCurso, this.idCurso, idAviso, data);
        this.loading = false;
        if(!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const resData = {
            idAviso,
            confirmacion: serverResponse.confirmacion
          };
          this.$emit('avisoConfirmacionModificado', resData);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    modificarAviso() {
      this.editarAviso.aviso = this.aviso;
      this.editarAviso.mostrar = true;
    },
    eliminarAviso() {
      this.eliminar.aviso = this.aviso;
      this.eliminar.mostrar = true;
    },
    cancelar() {
      this.editarAviso.aviso = null;
      this.editarAviso.mostrar = false;
    },
    cancelarEliminar() {
      this.eliminar.aviso = null;
      this.eliminar.mostrar = false;
    },
    avisoModificado(data) {
      this.editarAviso.aviso = null;
      this.editarAviso.mostrar = false;
      this.$emit("avisoModificado", data);
    },
    avisoEliminado(idAviso) {
      this.eliminar.aviso = null;
      this.eliminar.mostrar = false;
      this.$emit('avisoEliminado', idAviso);
    },
  }
}
</script>