<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" x-small icon v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item @click="$emit('modificarCriterio')">
        <v-list-item-icon
          ><v-icon> mdi-square-edit-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-title>Modificar criterio</v-list-item-title>
      </v-list-item>
      <v-list-item @click="$emit('eliminarCriterio')">
        <v-list-item-icon
          ><v-icon color="red darken-1"> mdi-trash-can-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-title>Eliminar criterio</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: "menuCriterio",
  props: {
    criterio: Object,
  },
  data: () => ({
    loading: false,
  }),
};
</script>