<template>
  <v-chip v-if="rounded" :color="labelColor" label small outlined>
    <v-icon small :color="iconColor" class="labelIcon">{{ icon }}</v-icon>
    <span class="labelText">{{ text }}</span>
  </v-chip>
  <span v-else text small>
    <v-icon small :color="iconColor">{{ icon }}</v-icon>
    <span class="infoText">{{ text }}</span>
  </span>
</template>

<script>
export default {
  name: "genericoChip",
  props: {
    icon: { type: String, default: "mdi-information-outline" },
    text: { type: [String, Number], default: "" },
    iconColor: { type: String, default: "primary" },
    labelColor: { type: String, default: "primary" },
    rounded: { type: Boolean, default: false },
  },
};
</script>

<style>
.labelIcon {
  margin-left: -7px;
}
.labelText {
  margin-left: 3px;
  margin-right: -3px;
}

.infoText {
  margin-left: 3px;
  margin-right: 5px;
}
</style>
