<template>
  <v-img :src="image" class="fondo">
    <v-app-bar flat color="rgba(0, 0, 0, 0)">
      <v-btn color="white" icon @click="regresar()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
        <v-spacer></v-spacer>
        <v-menu v-if="permisoEscritura && !vistaSeguimiento" left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon color="white" v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="modificarNombre = true">
              <v-list-item-title>Modificar nombre de curso</v-list-item-title>
              <v-list-item-icon>
                <v-icon small style="margin-right: 5px">
                  mdi-square-edit-outline
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item @click="modificarPortada = true">
              <v-list-item-title>Modificar imagen de curso</v-list-item-title>
              <v-list-item-icon>
                <v-icon small style="margin-right: 5px">
                  mdi-image
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-menu>
      <!-- <v-btn color="white" icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-row
      align="end"
      class="lightbox white--text pa-5 fill-height"
      style="position:relative;"
    >
      <v-col>
        <div class="headline mb-15">
          <h2>{{ nombre }}</h2>
        </div>
      </v-col>
    </v-row>

    <agregarPlantillaComponent
      v-if="modificarNombre"
      :mostrar="modificarNombre"
      :nombreAnterior="nombre"
      @cancelar="modificarNombre = false"
      @cursoModificado="cursoModificado"
    />
    <modificarPortadaComponent
      v-if="modificarPortada"
      :mostrar="modificarPortada"
      :idCurso="idCurso"
      @cancelar="modificarPortada = false"
      @imagenModificada="imagenModificada"
    />
  </v-img>
</template>

<script>
import { mapGetters } from "vuex";
import agregarPlantillaComponent from "../listadoCursos/cursoPlantilla/agregarPlantilla.vue";
import modificarPortadaComponent from "./modificarPortada.vue"
export default {
  name: "portadaCurso",
  props: {
    idCurso: String,
    nombre: String,
    permisoEscritura: { type: Boolean, default: false },
    portada: String,
  },
  components: {
    agregarPlantillaComponent,
    modificarPortadaComponent,
  },
  created() {
    if (this.portada != null) {
      this.image = this.imageURL + "/" + this.portada;
    }
  },
  computed: {
    ...mapGetters(["httpURL", "sessionToken", "role", "imageURL", "sesionCursoPadre", "vistaSeguimiento"]),
  },
  data: () => ({
    image: "/images/agnus_cursos.jpg",
    modificarNombre: false,
    modificarPortada: false,
  }),
  watch: {
    "portada": function (nuevo) {
      if (this.portada != null) {
        this.image = this.imageURL + "/" + this.portada 
      }
    },
  },
  methods: {
    cursoModificado(nombreCurso){
      this.modificarNombre = false;
      this.$emit("cursoModificado", nombreCurso);
    },
    imagenModificada(portada){
      this.image = this.imageURL + "/" + portada;
      this.modificarPortada = false;
    },
    regresar(){
      if(this.vistaSeguimiento) this.$router.push('/seguimiento');
      else if(this.sesionCursoPadre) this.$router.push('/controlParental');
      else this.$router.push('/cursos#cursos')
    },
  },
};
</script>

<style>
.fondo {
  min-height: 300px;
  max-height: 300px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.fondo:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
