import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"danger","dense":"","flat":"","dark":""}},[_vm._v("Eliminar aviso")]),_c(VContainer,[_c('b',[_vm._v("¿Está seguro de eliminar el aviso?")]),_vm._v(" "),_c('br'),_c(VCardTitle,{staticStyle:{"justify-content":"center"}},[_vm._v(_vm._s(_vm.aviso.titulo))]),_c('i',[_vm._v("Esta acción eliminará todo registro relacionado de manera irreversible.")])],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar ")]),_c(VBtn,{attrs:{"color":"danger","small":"","dark":"","loading":_vm.loading},on:{"click":function($event){return _vm.eliminar()}}},[_vm._v("Eliminar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }