import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"600","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"secondary","dense":"","flat":"","dark":""}},[_c('strong',[_vm._v(_vm._s(_vm.aviso ? "Modificar aviso" : "Agregar aviso"))])]),_c(VContainer,[_c(VCard,{attrs:{"elevation":"0"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VTextField,{attrs:{"label":"Título","outlined":"","dense":"","disabled":_vm.loading,"error-messages":_vm.errors.titulo},on:{"input":function($event){return _vm.validarTitulo()},"blur":function($event){return _vm.validarTitulo()}},model:{value:(_vm.titulo),callback:function ($$v) {_vm.titulo=$$v},expression:"titulo"}}),_c(VTextarea,{attrs:{"label":"Descripción","append-icon":"mdi-comment-plus-outline","outlined":"","disabled":_vm.loading,"rows":"5","no-resize":"","error-messages":_vm.errors.descripcion},on:{"input":function($event){return _vm.validarDescripcion()},"blur":function($event){return _vm.validarDescripcion()}},model:{value:(_vm.descripcion),callback:function ($$v) {_vm.descripcion=$$v},expression:"descripcion"}})],1)],1)],1),_c(VDivider,{staticClass:"mx-3"}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"small":"","outlined":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar ")]),_c(VBtn,{class:{
          'disable-events': _vm.blockBtn,
        },attrs:{"small":"","color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.aviso ? "Modificar" : "Agregar")+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }