<template>
  <v-dialog v-model="mostrar" :max-width="300" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dark dense flat class="text-h6">
        Clave de Inscripción
      </v-toolbar>

      <v-container grid-list-md>
        <v-text-field
          v-model.trim="dataLocal"
          outlined
          dense
          label="Clave"
          :disabled="loading"
          :error-messages="claveErrors"
          @input="$v.dataLocal.$touch()"
          @blur="$v.dataLocal.$touch()"
        >
        </v-text-field>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined small :disabled="loading" @click="cerrarForm()">
            Cancelar
          </v-btn>
          <v-btn color="primary" dark small :loading="loading" @click="modificarClave()">
            Modificar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { modificarConfiguracionService } from "./configuracion.service";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  props: {
    mostrar: Boolean,
    idCurso: String,
    valor: String,
  },
  mixins: [validationMixin],
  computed: {
    claveErrors() {
      const errors = [];
      if (!this.$v.dataLocal.$dirty) return errors;
      !this.$v.dataLocal.required && errors.push("Campo requerido.");
      !this.$v.dataLocal.minLength && errors.push("Mínimo 4 caracteres.");
      return errors;
    },
  },
  validations: {
    dataLocal: { required, minLength: minLength(4) },
  },
  data: () => ({
    loading: false,
    dataLocal: "",
  }),
  mounted() {
    this.dataLocal = this.valor;
  },
  created() {
    this.dataLocal = this.valor;
  },
  methods: {
    async modificarClave() {
      try {
        this.$v.$touch();
        if (!this.$v.dataLocal.$invalid) {
          this.loading = true;
          const data = { nuevoValor:this.dataLocal }
          const serverResponse = await modificarConfiguracionService(this.idCurso, 'claveInscripcion', data);
          this.loading = false;

          if (!serverResponse.ok) {
            this.$systemErrorMessage(serverResponse.mensaje);
          } else {
            this.$emit("valorModificado", serverResponse.nuevoValor);
            this.$emit("nuevoLog", serverResponse.nuevoLog);
            this.$emit("cancelar");
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    cerrarForm() {
      this.$emit("cancelar");
    },
  },
};
</script>