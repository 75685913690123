<template>
  <v-container>
    <v-card flat>
      <v-toolbar dense color="secondary" dark flat class="text-h6">
        <v-spacer></v-spacer> Actividades <v-spacer></v-spacer>
      </v-toolbar>
      <actividadesLoadingComponent v-if="loading" />
      <v-card v-else outlined>
        <div>
          <v-row style="margin: 5px">
            <v-col cols="6">
              <v-select
                v-model="moduloSelect"
                outlined
                dense
                label="Módulo"
                :items="modulos"
                :loading="loading"
                item-value="_id"
                item-text="nombre"
                @change="filtrarActividades()"
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="criterioSelect"
                outlined
                dense
                label="Criterios"
                :items="criterios"
                :loading="loading"
                item-value="_id"
                item-text="nombre"
                @change="filtrarActividades()"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <actividadesTablaComponent
                :idCurso="idCurso"
                :tipoCurso="tipoCurso"
                :actividades="actividadesFiltradas"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import actividadesLoadingComponent from "./actividadesLoading.vue";
import { obtenerActividadesCursoService } from "./actividad.service";
import actividadesTablaComponent from "./actividadesTabla.vue";
import { uniqueArrayObjects } from "../../../helpers/utilerias";
import { ordenarObjetosAsc } from "../../../helpers/ordenador";
import { obtenerActividadesCursoControlParental } from "../../controlParental/controlParental.service";

export default {
  name: "actividadesTab",
  props: {
    tipoCurso: String,
  },
  components: {
    actividadesLoadingComponent,
    actividadesTablaComponent,
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "sesionCursoPadre", "vistaSeguimiento"]),
  },
  watch: {
    sessionToken() {
      this.cargarActividades();
    },
  },
  created() {
    // if (this.app != "campus" && !this.vistaSeguimiento) this.tipoCurso = "plantilla";
    this.idCurso = this.$route.params.idCurso;
    if (this.sessionToken) this.cargarActividades();
  },
  data() {
    return {
      loading: false,
      idCurso: null,
      // tipoCurso: "campus",
      actividades: [],
      actividadesFiltradas: [],
      modulos: [],
      criterios: [],
      moduloSelect: null,
      criterioSelect: null,
    };
  },

  methods: {
    async cargarActividades() {
      try {
        this.loading = true;
        let serverResponse = null;
        if(this.sesionCursoPadre) serverResponse = await obtenerActividadesCursoControlParental(this.idCurso);
        else serverResponse = await obtenerActividadesCursoService(this.tipoCurso, this.idCurso);
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.actividades = serverResponse.actividades;
          this.actividadesFiltradas = serverResponse.actividades;
          this.cargarModulos();
          this.cargarCriterios();
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    cargarModulos() {
      let modulos = this.actividades.map(
        (actividad) => actividad.cursoModulo || actividad.cursoPlantillaModulo
      );
      modulos = ordenarObjetosAsc(modulos, "nombre");
      this.modulos = uniqueArrayObjects(modulos, "_id");
      this.modulos.unshift({ _id: 0, nombre: "Todos" });
      this.moduloSelect = this.modulos[0]._id;
    },
    cargarCriterios() {
      const actividadesConCriterio = this.actividades.filter(
        (actividad) => actividad.criterio
      );
      let criterios = actividadesConCriterio.map(
        (actividad) => actividad.criterio
      );
      criterios = ordenarObjetosAsc(criterios, "nombre");
      this.criterios = uniqueArrayObjects(criterios, "_id");
      this.criterios.unshift({ _id: 0, nombre: "Todos" });
      this.criterios.push({ _id: null, nombre: "No definido" });
      this.criterioSelect = this.criterios[0]._id;
    },
    filtrarActividades() {
      let actividadesFiltradas = [];
      if (this.moduloSelect == 0) actividadesFiltradas = this.actividades;
      else {
        actividadesFiltradas = this.actividades.filter((x) => {
          if (x.cursoModulo) return x.cursoModulo._id == this.moduloSelect;
          if (x.cursoPlantillaModulo)
            return x.cursoPlantillaModulo._id == this.moduloSelect;
        });
      }

      this.actividadesFiltradas = actividadesFiltradas;
      if (this.criterioSelect === 0) return;
      if (this.criterioSelect === null) {
        this.actividadesFiltradas = this.actividadesFiltradas.filter(
          (x) => !x.criterio
        );
        return;
      }
      this.actividadesFiltradas = this.actividadesFiltradas.filter((x) =>
        x.criterio ? x.criterio._id == this.criterioSelect : false
      );
    },
    // verActividad(idActividad) {
    //   this.$router.push(
    //     `/curso/${this.tipoCurso}/actividad/${this.idCurso}/${idActividad}`
    //   );
    // },
  },
};
</script>
