import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"flat":""}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VSpacer),_vm._v(" Encuadre "),_c(VSpacer)],1),(_vm.loading)?_c('encuadreLoadingComponent'):_c(VRow,[_c(VCol,{attrs:{"md":"8"}},[_c('descripcionComponent',{attrs:{"permisoEscritura":_vm.permisoEscritura}})],1),_c(VCol,{attrs:{"md":"4"}},[_c('evaluacionComponent',{attrs:{"permisoEscritura":_vm.permisoEscritura}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }