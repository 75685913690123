<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dark dense flat class="text-h6">{{titulo}}</v-toolbar>

      <v-container grid-list-md>
        <v-select
            label="Módulo:"
            v-model="moduloSel"
            :items="modulos"
            item-text="nombre"
            outlined
            dense
            return-object
            :disabled="loading"
        ></v-select>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            small
            :disabled="loading"
            @click="cerrarForm()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            dark
            small
            :loading="loading"
            @click="moverObjeto()"
          >
            Mover
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import { moverRecursoService } from "./recursos/recursos.service";
import { moverActividadService } from "./actividades/actividades.service";
import { obtenerModulosService } from "./modulos.service";

export default {
  props: {
    mostrar: Boolean,
    tipoCurso: String,
    idCurso: String,
    idModulo: String,
    actividad: { type: Object, default: null },
    recurso: { type: Object, default: null },
  },
  data: () => ({
    loading: false,
    objeto: null,
    titulo: "",
    tipoModulo: null,
    modulos: [],
    moduloSel: null
  }),
  created() {
      this.tipoModulo = this.$route.params.tipoModulo;
      if (this.actividad) this.titulo = `Mover actividad "${this.actividad.nombre}" de módulo`;
      else this.titulo = `Mover recurso "${this.recurso.recurso.nombre}" de módulo`;

      this.cargarModulos();
  },
  methods: {
    async cargarModulos() {
      try{
        this.loading = true;
        const serverResponse = await obtenerModulosService(this.tipoCurso, this.tipoModulo, this.idCurso, this.idModulo);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          this.loading = false;
        } else {
          this.modulos = serverResponse.modulos;
          this.moduloSel = this.modulos[0];
        }

      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async moverObjeto() {
      if (this.actividad) this.moverActividad();
      else this.moverRecurso();
    },
    async moverActividad() {
      try {
        this.loading = true;
        const data = {idModuloNew: this.moduloSel}
        const ids = {idCurso: this.idCurso, idModulo: this.idModulo, idActividad: this.actividad._id}
        const serverResponse = await moverActividadService(data, this.tipoCurso, ids);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("actividadCambiada", serverResponse.actividad._id);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async moverRecurso() {
      try {
        this.loading = true;
        const data = {idModuloNew: this.moduloSel}
        const ids = {idCurso: this.idCurso, idModulo: this.idModulo, idRecurso: this.recurso._id}
        const serverResponse = await moverRecursoService(data, this.tipoCurso, ids);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("recursoCambiado", serverResponse.recurso._id);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    cerrarForm() {
      this.$emit("cancelar");
    },
  },
};
</script>

<style scoped>
.formularioClass {
  padding: 15px;
  padding-bottom: 0;
}
</style>
