import { getServerRequest, postServerRequest } from '../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function getInvitacionesService() {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/perfil/configuracion/control/parental/invitaciones`;
    const config = { headers: { token: sessionToken } }
    return await getServerRequest(url, config);
}

export async function getCursosHijoControlParental(data) {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/controlParental/cursos/hijo`;
    const config = { headers: { token: sessionToken } }
    return await postServerRequest(url, {data}, config);
}

export async function crearSesionTokenControlParental(data) {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/controlParental/tokenSession`;
    const config = { headers: { token: sessionToken } }
    return await postServerRequest(url, {data}, config);
}

export async function obtenerCursoHijoService(idCurso) {
    const token = store.getters.tokenSesionHijo;
    const config = { headers: { token } };
    const url = `${httpURL}/controlParental/hijo/curso/${idCurso}`;
    return await postServerRequest(url, {}, config);
}

export async function obtenerDescripcionCursoControlParental(idCurso) {
    const token = store.getters.tokenSesionHijo;
    const config = { headers: { token } };
    const url = `${httpURL}/controlParental/cursos/descripcion/${idCurso}`;
    return await postServerRequest(url, {}, config);
}

export async function obtenerEvaluacionCursoControlParental(idCurso) {
    const token = store.getters.tokenSesionHijo;
    const config = { headers: { token } };
    const url = `${httpURL}/controlParental/cursos/${idCurso}/evaluacion/secciones`;
    return await postServerRequest(url, {}, config);
}

export async function obtenerActividadesCursoControlParental(idCurso) {
    const token = store.getters.tokenSesionHijo;
    const config = { headers: { token } };
    const url = `${httpURL}/controlParental/curso/listado/actividades/${idCurso}`;
    return await postServerRequest(url, {}, config);
}

export async function obtenerAsistenciaAlumnosControlParental(idCurso) {
    const token = store.getters.tokenSesionHijo;
    const config = { headers: { token } };
    const url = `${httpURL}/controlParental/curso/asistencias/alumnos/${idCurso}`;
    return await postServerRequest(url, {}, config);
}

export async function obtenerCalificacionControlParental(idCurso) {
    const token = store.getters.tokenSesionHijo;
    const config = { headers: { token } };
    const url = `${httpURL}/controlParental/curso/calificaciones/alumnos/${idCurso}`;
    return await postServerRequest(url, {}, config);
  }