import { deleteServerRequest, getServerRequest, patchServerRequest, putServerRequest } from '../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export const obtenerAvisosService = async(tipoCurso, idCurso, tipoUsuario) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/${tipoCurso}/avisos/${tipoUsuario}/${idCurso}`;
  return await getServerRequest(url, config);
}

export const crearAvisoProfesorService = async(tipoCurso, idCurso, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/${tipoCurso}/aviso/${idCurso}`;
  return await putServerRequest(url, data, config);
}

export const modificarAvisoService = async (tipoCurso, idCurso, idAviso, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/${tipoCurso}/aviso/${idCurso}/${idAviso}`;
  return await patchServerRequest(url, data, config);
}

export const modificarVisibilidadAvisoService = async (tipoCurso, idCurso, idAviso, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/${tipoCurso}/aviso/visible/${idCurso}/${idAviso}`;
  return await patchServerRequest(url, data, config);
}

export const modificarConfirmacionAvisoService = async (tipoCurso, idCurso, idAviso, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/${tipoCurso}/aviso/confirmacion/${idCurso}/${idAviso}`;
  return await patchServerRequest(url, data, config);
}

export const eliminarAvisoService = async (tipoCurso, idCurso, idAviso) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/${tipoCurso}/aviso/${idCurso}/${idAviso}`;
  return await deleteServerRequest(url, config);
}

export const registrarAvisoVisto = async (tipoCurso, idCurso, idAviso) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/${tipoCurso}/aviso/alumno/${idCurso}/${idAviso}`;
  return await putServerRequest(url, {}, config);
}

export const confirmarAvisoVisto = async (tipoCurso, idCurso, idAviso, idAvisoVisto, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/${tipoCurso}/aviso/alumno/confirmacion/${idCurso}/${idAviso}/${idAvisoVisto}`;
  return await patchServerRequest(url, data, config);
}

export const ocultarAvisoVisto = async (idCurso, idAvisoVisto, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/campus/aviso/alumno/ocultar/${idCurso}/${idAvisoVisto}`;
  return await patchServerRequest(url, data, config);
}

// Cursos Avisos
export const getCursosAvisos = async () => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/cursos/avisos`;
  return await getServerRequest(url, config);
}