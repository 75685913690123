<template>
  <div>
    <v-card outlined class="my-2">
      <v-skeleton-loader
        v-if="loading"
        type="list-item-avatar-two-line@2"
        style="margin: 20px"
      ></v-skeleton-loader>

      <v-row v-else>
        <v-col md="4" sm="6">
          <claveInscripcionComponent
            :idCurso="idCurso"
            :permisoEscritura="permisoEscritura"
            :claveInscripcion="configuracion.claveInscripcion"
            @claveModificada="claveModificada"
            @nuevoLog="nuevoLog"
          />
        </v-col>
        <v-col md="4" sm="6">
          <tamanoArchivosComponent
            :idCurso="idCurso"
            :permisoEscritura="permisoEscritura"
            :fileSize="configuracion.fileSize"
            @tamanoModificado="tamanoModificado"
            @nuevoLog="nuevoLog"
          />
        </v-col>
        <v-col md="4" sm="6">
          <registroAlumnosComponent
            :idCurso="idCurso"
            :permisoEscritura="permisoEscritura"
            :maxAlumnos="configuracion.maxAlumnos"
            @maxAlumnosModificado="maxAlumnosModificado"
            @nuevoLog="nuevoLog"
          />
        </v-col>
        <v-col md="4" sm="6">
          <retardosFaltaComponent
            :idCurso="idCurso"
            :permisoEscritura="permisoEscritura"
            :retardosFalta="configuracion.retardosFalta"
            @retardosFaltaModificado="retardosFaltaModificado"
            @nuevoLog="nuevoLog"
          />
        </v-col>
        <v-col md="4" sm="6">
          <almacenamientoTotalComponent
            :idCurso="idCurso"
          />
        </v-col>
        <v-col md="4" sm="6">
          <avanceAutomaticoComponent
            :tipoCurso="tipoCurso"
            :idCurso="idCurso"
            :permisoEscritura="permisoEscritura"
            :avance="avanceAutomatico"
            @nuevoLog="nuevoLog"
          />
        </v-col>
        <!-- <v-col md="4" sm="6">
          <discordChannelComponent
            :idCurso="idCurso"
            :permisoEscritura="permisoEscritura"
            :discordTextChannel="configuracion.discordTextChannel"
            :discordVoiceChannel="configuracion.discordVoiceChannel"
          />
        </v-col> -->
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import claveInscripcionComponent from "./claveInscripcion.vue";
import tamanoArchivosComponent from "./tamanoArchivos.vue";
import registroAlumnosComponent from "./registroAlumnos.vue";
import retardosFaltaComponent from "./retardosFalta.vue";
import almacenamientoTotalComponent from "./almacenamientoTotal.vue";
// import discordChannelComponent from "./discordChannel.vue";
import { obtenerConfiguracionService } from "./configuracion.service";
import avanceAutomaticoComponent from "./avanceAutomatico.vue";

export default {
  name: "configuracionCurso",
  props: {
    tipoCurso: String,
    idCurso: String,
    curso: Object,
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    claveInscripcionComponent,
    tamanoArchivosComponent,
    registroAlumnosComponent,
    retardosFaltaComponent,
    almacenamientoTotalComponent,
    avanceAutomaticoComponent,
  },
  watch: {
    sessionToken() {
      if( this.tipoCurso == "campus" ) this.cargarConfiguracion();
      else if(this.tipoCurso == "plantilla") this.avanceAutomatico = this.curso.avanceAutomatico || false;
    },
  },
  computed: {
    ...mapGetters(["sessionToken", "app"]),
  },
  data() {
    return {
      loading: true,
      configuracion: {},
      avanceAutomatico: false
    };
  },
  mounted() {
    if (this.sessionToken && this.tipoCurso == "campus") this.cargarConfiguracion();
    else if(this.sessionToken && this.tipoCurso == "plantilla") this.avanceAutomatico = this.curso.avanceAutomatico || false;
  },
  methods: {
    async cargarConfiguracion() {
      try {
        this.loading = true;
        const serverResponse = await obtenerConfiguracionService(this.idCurso);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.configuracion = serverResponse.configuracion;
          this.avanceAutomatico = this.configuracion.avanceAutomatico || false;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    claveModificada(nuevaClave){
      this.configuracion.claveInscripcion = nuevaClave.configuracion.claveInscripcion;
    },
    tamanoModificado(nuevoTamano){
      this.configuracion.fileSize = nuevoTamano.configuracion.fileSize;
    },
    maxAlumnosModificado(nuevoMaxAlumnos){
      this.configuracion.maxAlumnos = nuevoMaxAlumnos.configuracion.maxAlumnos;
    },
    retardosFaltaModificado(nuevoRetardosFalta){
      this.configuracion.retardosFalta = nuevoRetardosFalta.configuracion.retardosFalta;
    },
    nuevoLog(log){
      this.$emit("nuevoLog", log);
    }
  },
};
</script>
