<template>
  <div class="text-center">
    <v-menu offset-x left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small v-bind="attrs" v-on="on" :loading="loading">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item  v-if="permisoEscritura" @click="blockIntegrante">
          <template v-if="!bloqueado">
            <v-list-item-title>Bloquear alumno</v-list-item-title>
            <v-list-item-icon><v-icon small>mdi-lock</v-icon></v-list-item-icon>
          </template>
          <template v-else>
            <v-list-item-title>Desbloquear alumno</v-list-item-title>
            <v-list-item-icon
              ><v-icon small>mdi-lock-open</v-icon></v-list-item-icon
            >
          </template>
        </v-list-item>
        <v-list-item v-if="!cursoEscuela" @click="eliminarIntegrante = true">
          <v-list-item-title>
            <font color="#D32F2F"> {{leyendaEliminar}} </font>
          </v-list-item-title>
          <v-list-item-icon
            ><v-icon color="red darken-2" small
              >mdi-trash-can-outline</v-icon
            ></v-list-item-icon
          >
        </v-list-item>
      </v-list>
    </v-menu>
    <eliminarIntegranteComponent
      v-if="eliminarIntegrante"
      :mostrar="eliminarIntegrante"
      :idCurso="idCurso"
      :idIntegrante="idIntegrante"
      :nombreIntegrante="nombreIntegrante"
      @cancelar="eliminarIntegrante = false"
      @integranteEliminado="eliminar"
    />
  </div>
</template>

<script>
import { blockIntegranteCursoService } from "./integrantes.service";
import eliminarIntegranteComponent from "./eliminarIntegrante.vue";
import { mapGetters } from "vuex";

export default {
  name: "integranteMenu",
  components: { eliminarIntegranteComponent },
  props: {
    idCurso: String,
    bloqueado: { type: Boolean, default: false },
    idIntegrante: { type: String || null },
    nombreIntegrante: { type: String || null },
    cursoEscuela: { type: Boolean, default: false },
    permisoEscritura: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(["app", "sessionToken", "sesionCursoAlumno"]),
  },
  data() {
    return {
      loading: false,
      eliminarIntegrante: false,
      leyendaEliminar: ""
    };
  },
  created() {
      if(this.app == "campus" && this.sesionCursoAlumno) this.leyendaEliminar = "Salir del grupo";
      else this.leyendaEliminar = "Eliminar alumno";
  },
  methods: {
    async blockIntegrante() {
      try {
        this.loading = true;
        const serverResponse = await blockIntegranteCursoService(
          this.idCurso,
          this.idIntegrante
        );
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          const emitData = {
            idUsuario: serverResponse.idUsuario,
            bloqueado: serverResponse.bloqueado,
          };
          this.$emit("blockUsuario", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    eliminar(idIntegrante) {
      this.$emit("integranteEliminado", idIntegrante);
    },
  },
};
</script>
