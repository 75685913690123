import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":500,"persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{attrs:{"color":"primary","dark":"","flat":"","dense":""}},[(_vm.editarSeccion)?_c(VToolbarTitle,[_vm._v("Editar Sección")]):_c(VToolbarTitle,[_vm._v("Agregar Sección")]),_c(VSpacer),_c(VBtn,{attrs:{"fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)],1),_c('br'),_c(VForm,{ref:"formulario",staticClass:"formularioClass",attrs:{"lazy-validation":""},model:{value:(_vm.esValido),callback:function ($$v) {_vm.esValido=$$v},expression:"esValido"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","label":"Nombre","disabled":_vm.loading,"rules":[_vm.reglasComunes.valorRequerido('Nombre requerido')]},model:{value:(_vm.datosFormulario.nombre),callback:function ($$v) {_vm.$set(_vm.datosFormulario, "nombre", $$v)},expression:"datosFormulario.nombre"}}),_c(VSelect,{attrs:{"outlined":"","dense":"","label":"Ponderación:","items":_vm.ponderaciones,"disabled":_vm.loading},model:{value:(_vm.datosFormulario.ponderacion),callback:function ($$v) {_vm.$set(_vm.datosFormulario, "ponderacion", $$v)},expression:"datosFormulario.ponderacion"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"small":"","outlined":"","disabled":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{class:{ 'disable-events': !_vm.esValido },attrs:{"small":"","dark":"","color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.guardarSeccion()}}},[_vm._v("Guardar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }