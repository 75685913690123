import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":500,"persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"secondary","dark":"","flat":"","dense":""}},[(!_vm.editarModulo)?_c(VToolbarTitle,[_vm._v("Agregar módulo")]):_c(VToolbarTitle,[_vm._v("Modificar módulo")]),_c(VSpacer)],1),_c('br'),_c(VForm,{ref:"formulario",staticClass:"formularioClass",attrs:{"lazy-validation":""},model:{value:(_vm.esValido),callback:function ($$v) {_vm.esValido=$$v},expression:"esValido"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","label":"Nombre","disabled":_vm.loading,"rules":[_vm.reglasComunes.valorRequerido('Nombre requerido')]},model:{value:(_vm.datosFormulario.nombre),callback:function ($$v) {_vm.$set(_vm.datosFormulario, "nombre", $$v)},expression:"datosFormulario.nombre"}}),(
          _vm.puedeModificarClases ||
          _vm.puedeModificarActividades ||
          _vm.puedeModificarRecursos ||
          _vm.puedeModificarExamen
        )?_c('div',{staticStyle:{"text-align":"center"}},[_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(" Las secciones con contenido no pueden ser desactivadas ")])]):_vm._e(),_c(VContainer,[_c('p',[_vm._v("Este módulo contiene:")]),_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VCheckbox,{attrs:{"disabled":_vm.puedeModificarClases,"label":"Clases"},model:{value:(_vm.datosFormulario.contieneClases),callback:function ($$v) {_vm.$set(_vm.datosFormulario, "contieneClases", $$v)},expression:"datosFormulario.contieneClases"}})],1),(_vm.tipoModulo != 'moduloProfesor')?_c(VCol,{attrs:{"cols":"6"}},[_c(VCheckbox,{attrs:{"disabled":_vm.puedeModificarActividades,"label":"Actividades"},model:{value:(_vm.datosFormulario.contieneActividades),callback:function ($$v) {_vm.$set(_vm.datosFormulario, "contieneActividades", $$v)},expression:"datosFormulario.contieneActividades"}})],1):_vm._e()],1),_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VCheckbox,{attrs:{"disabled":_vm.puedeModificarRecursos,"label":"Recursos"},model:{value:(_vm.datosFormulario.contieneRecursos),callback:function ($$v) {_vm.$set(_vm.datosFormulario, "contieneRecursos", $$v)},expression:"datosFormulario.contieneRecursos"}})],1),(_vm.tipoModulo != 'moduloProfesor')?_c(VCol,{attrs:{"cols":"6"}},[_c(VCheckbox,{attrs:{"disabled":_vm.puedeModificarExamen,"label":"Exámenes"},model:{value:(_vm.datosFormulario.contieneExamenes),callback:function ($$v) {_vm.$set(_vm.datosFormulario, "contieneExamenes", $$v)},expression:"datosFormulario.contieneExamenes"}})],1):_vm._e()],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.cerrarForm()}}},[_vm._v(" Cancelar ")]),_c(VBtn,{class:{ 'disable-events': !_vm.esValido },attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.agregarModulo()}}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }