<template>
  <v-container>
    <v-card flat>
      <v-toolbar dense color="secondary" dark flat class="text-h6"> 
        <v-spacer></v-spacer> Asistencias <v-spacer></v-spacer>
        <v-tooltip bottom v-if="permisoEscritura && !vistaSeguimiento">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="white" icon v-bind="attrs" v-on="on">
              <v-icon @click="crearAsistencia()">
                mdi-text-box-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Nueva Asistencia</span>
        </v-tooltip>
      </v-toolbar>
      <asistenciasLoadingComponent v-if="loading"/>
      <div v-else>
        <v-row style="padding-right: 15px; padding-top: 15px;">
          <v-col cols="12" md="3" sm="4">
            <v-select 
              v-model="vistaSelect"
              outlined
              label="Vista:"
              :items="vistaItems"
              style="padding-left: 20px;"
              >
            </v-select>
          </v-col>
          <v-col cols="12" md="3" sm="4"
            v-show="vistaSelect == 'alumnos' && !sesionCursoAlumno && !sesionCursoPadre">
            <v-select 
              v-model="periodo"
              outlined
              label="Periodo:"
              :items="periodoItems"
              style="padding-left: 20px;"
              >
            </v-select>
          </v-col>
        </v-row>
        <vistaFechasComponent
          v-if="vistaSelect == 'fecha'"
          :idCurso="idCurso"
          :alumnos="alumnos"
          :asistenciaStatus="asistenciaStatus"
          :asistencias="asistencias"
          :permisoEscritura="permisoEscritura"
          :coloresAsistencias="coloresAsistencias"
          @asistenciaModificada="asistenciaModificada"
          @asistenciaEliminada="asistenciaEliminada"
          @asistenciaFechaModificada="asistenciaFechaModificada"
        />
        <vistaAlumnosComponent
          v-if="vistaSelect == 'alumnos'"
          :idCurso="idCurso"
          :alumnos="alumnos"
          :idHijo="idHijo"
          :asistencias="asistencias"
          :periodo="periodo"
          :retardosFalta="retardosFalta"
          :periodoItems="periodoItems"
          :coloresAsistencias="coloresAsistencias"
          :refrescar="refrescar"
        />
      </div>
    </v-card>

    <modificarAsistenciaComponent 
        v-if="mostrarCrear == true"
        :mostrar="mostrarCrear"
        :idCurso="idCurso"
        :alumnos="alumnos"
        :nuevo="true"
        :asistenciaStatus="asistenciaStatus"
        :permisoEscritura="permisoEscritura"
        @asistenciaCreada="asistenciaCreada"
        @cerrar="mostrarCrear = false"
    />
  </v-container>
</template>

<script>
import asistenciasLoadingComponent from "./asistenciasLoading.vue";
import vistaFechasComponent from "./vistaFechas.vue";
import vistaAlumnosComponent from "./vistaAlumnos.vue"
import modificarAsistenciaComponent from './modificarAsistencia.vue';
import { obtenerAlumnosService } from './asistencias.service';
import { obtenerAsistenciaAlumnosControlParental } from "../../controlParental/controlParental.service";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import { ordenarObjetosAsc } from '../../../helpers/ordenador';

export default {
  name: "asistenciasTab",
  props: {
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    asistenciasLoadingComponent,
    vistaFechasComponent,
    vistaAlumnosComponent,
    modificarAsistenciaComponent,
  },
  computed: {
    ...mapGetters(["sessionToken", "sesionCursoAlumno", "sesionCursoPadre", "vistaSeguimiento"]),
    vistaItems(){
      if(this.sesionCursoAlumno || this.sesionCursoPadre) 
        return [ {value:'fecha',text:'Fecha'}, {value:'alumnos',text:'Mensual'}]
      else
        return [ {value:'fecha',text:'Fecha'}, {value:'alumnos',text:'Alumnos'}]
    }
  },
  watch: {
    sessionToken() {
      this.obtenerListadoAlumnos();
    },
  },
  created(){
    this.idCurso = this.$route.params.idCurso;
    if (this.sessionToken) this.obtenerListadoAlumnos();
  },
  data() {
    return {
      loading: false,
      mostrarCrear: false,
      idCurso: null,
      alumnos: [],
      asistenciaStatus: [],
      asistencias: [],
      periodoItems:[],
      periodo: 0,
      vistaSelect: 'fecha',
      refrescar: 0,
      retardosFalta: 3,
      coloresAsistencias: null,
      idHijo: null,
    };
  },
  methods: {
    crearAsistencia() {
      this.mostrarCrear = true;
    },
    async obtenerListadoAlumnos(){
      try {
      this.loading = true;
      let serverResponse = null;
      if(this.sesionCursoPadre) serverResponse = await obtenerAsistenciaAlumnosControlParental(this.idCurso);
      else serverResponse = await obtenerAlumnosService(this.idCurso);
      this.loading = false;
      if (!serverResponse.ok) {
        this.$systemErrorMessage(serverResponse.mensaje);
      } else {
        this.alumnos = serverResponse.alumnos;
        if(this.alumnos.length > 0) this.alumnos = ordenarObjetosAsc(this.alumnos, 'nombreCompleto');
        this.asistenciaStatus = serverResponse.asistenciaStatus;
        this.asistencias = serverResponse.asistencias;
        this.coloresAsistencias = serverResponse.coloresAsistencias;
        this.retardosFalta = serverResponse.retardosFalta;
        this.idHijo = serverResponse.idHijo;
        this.crearPeriodoItems(this.asistencias);
      }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    asistenciaModificada(asistencia){
      const index = this.asistencias.findIndex((x) => x.cursoAsistencia._id == asistencia.cursoAsistencia._id);
      this.asistencias.splice(index, 1, asistencia);
      this.refrescar++;
    },
    asistenciaCreada(asistencia){
      this.mostrarCrear = false;
      asistencia.forEach(asis => {
        this.asistencias.push(asis);
      });
      this.crearPeriodoItems(this.asistencias);
    },
    asistenciaEliminada(idAsistencia){
      const index = this.asistencias.findIndex((x) => x.cursoAsistencia._id == idAsistencia);
      this.asistencias.splice(index, 1);
      this.crearPeriodoItems(this.asistencias);
    },
    crearPeriodoItems(asistencias){
      this.periodoItems = [];
      this.periodoItems.push({ value:0, text:'Total' })
      asistencias.forEach(asistencia => {
        let mes = DateTime.fromISO(asistencia.cursoAsistencia.fecha).toFormat('yyyy-MM-dd');
        mes = mes.split('-');
        let periodo = { value: parseInt(mes[1]) }
        mes = DateTime.local(parseInt(mes[0]), parseInt(mes[1]), parseInt(mes[2])).monthLong;
        periodo.text = mes
        this.periodoItems.push(periodo);
      });
      this.periodoItems.sort(this.comparaMeses)
      let hash = {};
      this.periodoItems = this.periodoItems.filter(o => hash[o.value] ? false : hash[o.value] = true);
    },
    asistenciaFechaModificada(asistencia){
      const index = this.asistencias.findIndex((x) => x.cursoAsistencia._id == asistencia._id);
      const asistenciaTmp = this.asistencias[index];
      asistenciaTmp.cursoAsistencia.fecha = asistencia.fecha;
      this.asistencias.splice(index, 1, asistenciaTmp);
      this.refrescar++;
    },
    comparaMeses(a, b) {
      return a.value - b.value;
    }
  }
};
</script>

<style lang="postcss" scoped>
/deep/ .vc-container {
  border-radius: 0;
  width: 100%;
}
</style>