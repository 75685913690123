<template>
  <div>
    <v-dialog v-model="mostrar" :max-width="500" persistent>
      <v-card :loading="loading">
        <v-toolbar color="primary" dark flat dense>
          <v-toolbar-title v-if="editarCriterio"
            >Editar Criterio</v-toolbar-title
          >
          <v-toolbar-title v-else>Agregar Criterio</v-toolbar-title>
        </v-toolbar>
        <br />
        <v-form
          ref="formulario"
          v-model="esValido"
          lazy-validation
          class="formularioClass"
        >
          <v-text-field
            v-model="datosFormulario.nombre"
            outlined
            dense
            label="Nombre"
            :disabled="loading"
            :rules="[reglasComunes.valorRequerido('Campo requerido')]"
          ></v-text-field>
          <v-select
            outlined
            dense
            v-model="datosFormulario.ponderacion"
            label="Ponderación:"
            :items="ponderaciones"
            :disabled="loading"
          ></v-select>
          <v-alert
            v-if="
              datosFormulario.asistencias &&
                editarCriterio &&
                !criterio.asistencias.requerido
            "
            dense
            type="warning"
            border="left"
          >
            La actividades pertenecientes a este criterio serán desligadas del
            mismo.
          </v-alert>
          <v-checkbox
            v-model="datosFormulario.asistencias"
            label="Ligar asistencias"
            :disabled="loading"
            style="margin-top: 0px"
          />
          <v-row v-show="datosFormulario.asistencias">
            <v-col md="6">
              <v-menu
                v-model="fechaInicioPicker"
                :close-on-content-click="false"
                offset-y
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    :value="fechaInicioTexto"
                    label="Fecha inicio:"
                    dense
                    :disabled="!datosFormulario.asistencias || loading"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="datosFormulario.fechaInicio"
                  locale="es"
                  @change="fechaInicioPicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col md="6">
              <v-menu
                v-model="fechaFinPicker"
                :close-on-content-click="false"
                offset-y
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="fechaFinTexto"
                    v-on="on"
                    label="Fecha fin:"
                    dense
                    :disabled="!datosFormulario.asistencias || loading"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="datosFormulario.fechaFin"
                  locale="es"
                  @change="fechaFinPicker = false"
                  :min="datosFormulario.fechaInicio"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            small
            :disabled="loading"
            @click="$emit('cancelar')"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            small
            dark
            :loading="loading"
            :class="{ 'disable-events': !esValido }"
            @click="guardarCriterio()"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  agregarCriterioCursoService,
  editarCriterioCursoService,
} from "./evaluacion.service.js";
import { validationMixin } from "vuelidate";
import { DateTime } from "luxon";

export default {
  name: "criterioForm",
  props: {
    mostrar: Boolean,
    tipoCurso: String,
    idCurso: String,
    idSeccion: String,
    ponderacionMax: Number,
    editarCriterio: Boolean,
    criterio: Object,
  },
  mixins: [validationMixin],
  computed: {
    fechaInicioTexto() {
      return this.datosFormulario.fechaInicio
        ? DateTime.fromISO(this.datosFormulario.fechaInicio).toLocaleString(
            DateTime.DATE_MED
          )
        : "";
    },
    fechaFinTexto() {
      return this.datosFormulario.fechaFin
        ? DateTime.fromISO(this.datosFormulario.fechaFin).toLocaleString(
            DateTime.DATE_MED
          )
        : "";
    },
  },
  mounted() {
    this.generarPonderaciones();
    if (this.editarCriterio) this.llenarFormulario();
  },
  data: () => ({
    loading: false,
    ponderaciones: [],
    esValido: true,
    reglasComunes: {
      valorRequerido(textoParaMostrar) {
        return (v) => !!v || textoParaMostrar;
      },
    },
    datosFormulario: {
      nombre: "",
      ponderacion: 5,
      asistencias: false,
      fechaInicio: "",
      fechaFin: "",
    },
    fechaInicioPicker: false,
    fechaFinPicker: false,
  }),
  methods: {
    generarPonderaciones() {
      let ponderacionMax = this.ponderacionMax;
      if (this.editarCriterio) ponderacionMax += this.criterio.ponderacion;
      for (let i = 5; i <= ponderacionMax; i += 5)
        this.ponderaciones.push({ value: i, text: i + "%" });
    },
    llenarFormulario() {
      this.datosFormulario.nombre = this.criterio.nombre;
      this.datosFormulario.ponderacion = this.criterio.ponderacion;
      this.datosFormulario.asistencias = this.criterio.asistencias.requerido;
      if (this.datosFormulario.asistencias) {
        let inicio = DateTime.fromISO(this.criterio.asistencias.fechaInicio);
        let final = DateTime.fromISO(this.criterio.asistencias.fechaFin);
        this.datosFormulario.fechaInicio = inicio.toFormat("y-MM-dd");
        this.datosFormulario.fechaFin = final.toFormat("y-MM-dd");
      }
    },
    async guardarCriterio() {
      if (this.$refs.formulario.validate()) {
        let inicio = DateTime.fromISO(this.datosFormulario.fechaInicio);
        let fin = DateTime.fromISO(this.datosFormulario.fechaFin);
        try {
          this.loading = true;
          const data = {
            nombre: this.datosFormulario.nombre,
            ponderacion: this.datosFormulario.ponderacion,
            asistencias: this.datosFormulario.asistencias,
            fechaInicio: inicio.toISO(),
            fechaFin: fin.toISO(),
          };

          let serverResponse = null;
          if (this.editarCriterio)
            serverResponse = await editarCriterioCursoService(
              data,
              this.tipoCurso,
              this.idCurso,
              this.criterio._id
            );
          else
            serverResponse = await agregarCriterioCursoService(
              data,
              this.tipoCurso,
              this.idCurso,
              this.idSeccion
            );
          this.loading = false;
          if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
          else {
            if (!this.editarCriterio) {
              let emitData = {
                nuevoCriterio: serverResponse.nuevoCriterio,
                idSeccion: this.idSeccion,
              };
              this.$emit("criterioAgregado", emitData);
            } else {
              let emitData = {
                criterioModificado: serverResponse.criterioModificado,
                idSeccion: this.idSeccion,
              };
              this.$emit("criterioModificado", emitData);
            }
            this.$emit("cancelar");
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },
  },
};
</script>

<style scoped>
.formularioClass {
  padding: 15px;
  padding-bottom: 0;
}
</style>
