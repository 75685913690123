import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"flat":""}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VSpacer),_vm._v(" Calificaciones "),_c(VSpacer)],1),(_vm.loading)?_c('calificacionesLoadingComponent'):_c(VCard,{attrs:{"outlined":""}},[_c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('calificacionesTablaComponent',{attrs:{"idCurso":_vm.idCurso,"calificaciones":_vm.calificaciones,"secciones":_vm.secciones},on:{"download":_vm.descargarCalificaciones,"puntosUsuarioActualizados":_vm.puntosUsuarioActualizados,"cargarCalificaciones":_vm.cargarCalificaciones}})],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }