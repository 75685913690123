<template>
  <v-container>
    <v-skeleton-loader
      v-if="!escuelaTienePermiso"
      type="list-item-two-line@5"
    ></v-skeleton-loader>
    <v-card v-else rounded="lg">
      <portadaCursoComponent
        :idCurso="curso._id"
        :nombre="curso.nombre"
        :portada="curso.portada"
        :permisoEscritura="permisoEscritura"
        @cursoModificado="cursoModificado"
      />
      <cursoTabsComponent
        :tipoCurso="tipoCurso"
        :idCurso="idCurso"
        :curso="curso"
        :cursoPlantilla="curso.cursoPlantilla"
        :permisoEscritura="permisoEscritura"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import portadaCursoComponent from "./portadaCurso.vue";
import cursoTabsComponent from "./cursoTabs.vue";
import { obtenerCursoService } from "./curso.service";
import { obtenerCursoHijoService } from "../../controlParental/controlParental.service";
import { permisoMenuEscuela } from "../../../helpers/utilerias";

export default {
  name: "cursoView",
  components: {
    portadaCursoComponent,
    cursoTabsComponent,
  },
  computed: {
    ...mapGetters(["sessionToken", "menu", "sesionCursoPadre"]),
  },
  watch: {
    sessionToken() {
      if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso = true;
        if (this.sesionCursoPadre == true) this.cargarCursoHijo();
        else this.cargarCurso();
      }
    },
  },
  mounted() {
    if (this.sessionToken) {
      if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso = true;
        if (this.sesionCursoPadre == true) this.cargarCursoHijo();
        else this.cargarCurso();
      }
    }
  },
  data() {
    return {
      loading: false,
      tab: null,
      tipoCurso: "campus",
      idCurso: null,
      curso: {},
      permisoEscritura: false,
      escuelaTienePermiso: false,
    };
  },
  methods: {
    async cargarCurso() {
      try {
        if (this.$route.params.tipoCurso != "campus")
          this.tipoCurso = "plantilla";
        this.idCurso = this.$route.params.idCurso;
        this.loading = true;
        const data = { tipoCurso: this.tipoCurso, idCurso: this.idCurso };
        const serverResponse = await obtenerCursoService(data);
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          this.$router.push("/cursos");
        } else {
          this.curso = serverResponse.curso;
          this.permisoEscritura = serverResponse.permisoEscritura;
          if (this.tipoCurso === "campus"){
            this.$store.dispatch("setSesionCursoProfesor",serverResponse.curso.sesionProfesor || false);
            if (serverResponse.curso.seguimiento == false) this.$store.dispatch("setSesionCursoAlumno",serverResponse.curso.sesionAlumno);
            if (serverResponse.curso.seguimiento == true) this.$store.dispatch("setVistaSeguimiento",serverResponse.curso.seguimiento);
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async cargarCursoHijo() {
      try {
        this.idCurso = this.$route.params.idCurso;
        this.loading = true;
        const serverResponse = await obtenerCursoHijoService(this.idCurso);
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          this.$router.push("/controlParental");
        } else {
          this.curso = serverResponse.curso;
          this.permisoEscritura = serverResponse.permisoEscritura;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    cursoModificado(nombreCurso) {
      this.curso.nombre = nombreCurso;
    },
  },
};
</script>
