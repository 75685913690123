<template>
  <v-dialog v-model="mostrar" width="500" persistent transition="dialog-bottom-transition">
    <v-card>
    <v-toolbar color="danger" dense flat dark class="text-h6">Eliminar aviso</v-toolbar>

    <v-container>
        <b>¿Está seguro de eliminar el aviso?</b> <br>
        <v-card-title style="justify-content: center;">{{aviso.titulo}}</v-card-title>
        <i>Esta acción eliminará todo registro relacionado de manera irreversible.</i>
    </v-container>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn 
        color="primary" 
        outlined 
        small 
        :disabled="loading" 
        @click="$emit('cancelar')"
      >Cancelar
      </v-btn>
      <v-btn 
        color="danger" 
        small
        dark
        :loading="loading" 
        @click="eliminar()">Eliminar</v-btn>
    </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { eliminarAvisoService } from "./avisos.service";

export default {
  name: "eliminarAviso",
  props: {
    mostrar: { type: Boolean, default: false },
    tipoCurso: { type: String, default: null },
    idCurso: { type: String, default: null },
    aviso: { type: Object, default: () => null },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async eliminar() {
      try {
        this.loading = true;

        const serverResponse = await eliminarAvisoService(this.tipoCurso, this.idCurso, this.aviso._id);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("avisoEliminado", this.aviso._id);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    }
  }
}
</script>