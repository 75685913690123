import { getServerRequest, putServerRequest, patchServerRequest, deleteServerRequest } from '../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function obtenerActividadService(tipoCurso, idCurso, idActividad) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/actividad/${idCurso}/${idActividad}`;
    return await getServerRequest(url, config);
}

export async function obtenerActividadesCursoService(tipoCurso, idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/listado/actividades/${idCurso}`;
    return await getServerRequest(url, config);
}

export async function modificarCriterioService({ tipoCurso, idCurso, idActividad, tipoActividad, data }) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/${tipoActividad}/criterio/${idCurso}/${idActividad}`;
    return await patchServerRequest(url, data, config);
}

export async function obtenerPonderacionMaximaService(tipoCurso, idCurso, idActividad) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/actividad/ponderacionMaxima/${idCurso}/${idActividad}`;
    return await getServerRequest(url, config);
}

export async function modificarPonderacionService(tipoCurso, idCurso, idActividad, ponderacion) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const data = { ponderacion }
    const url = `${httpURL}/curso/${tipoCurso}/actividad/ponderacion/${idCurso}/${idActividad}`;
    return await patchServerRequest(url, data, config);
}

export async function modificarTipoService(tipoCurso, idCurso, idActividad, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/actividad/tipo/${idCurso}/${idActividad}`;
    return await patchServerRequest(url, data, config);
}

export async function modificarEntregaService(tipoCurso, idCurso, idActividad, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/actividad/entrega/${idCurso}/${idActividad}`;
    return await patchServerRequest(url, data, config);
}

export async function modificarInstrumentoService(tipoCurso, idCurso, idActividad, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/actividad/instrumento/${idCurso}/${idActividad}`;
    return await patchServerRequest(url, data, config);
}

export async function modificarFormatoArchivoService(tipoCurso, idCurso, idActividad, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/actividad/formatoArchivo/${idCurso}/${idActividad}`;
    return await patchServerRequest(url, data, config);
}

export async function obtenerInstrumentosService() {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumentos/actividad/listadoInstrumentos`;
    return await getServerRequest(url, config);
}

export async function modificarEntregaExtemporanea(idCurso, idActividad) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/actividad/entregaExtemporanea/${idCurso}/${idActividad}`;
    return await patchServerRequest(url, {}, config);
}

export async function modificarFormarEquipos(idCurso, idActividad) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/actividad/formarEquipos/${idCurso}/${idActividad}`;
    return await patchServerRequest(url, {}, config);
}

export async function modificarFechaEntrega(idCurso, idActividad, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/actividad/fechaEntrega/${idCurso}/${idActividad}`;
    return await patchServerRequest(url, data, config);
}

//cambiar a actividad
export async function eliminarActividadService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${data.tipoCurso}/actividad/eliminar/${data.idCurso}/${data.idActividad}`;
    return await deleteServerRequest(url, config);
}

export async function modificarNombreActividadService(idCurso, tipoCurso, idActividad, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/actividad/nombre/${idCurso}/${idActividad}`;
    return await patchServerRequest(url, data, config);
}

export async function modificarDescripcionActividadService(idCurso, tipoCurso, idActividad, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/actividad/descripcion/${idCurso}/${idActividad}`;
    return await patchServerRequest(url, data, config);
}

export const getEquiposActividad = async ({ idCurso, idActividad }) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/${idCurso}/actividad/${idActividad}/equipos`;
    return await getServerRequest(url, config);
}