import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,[_c(VCard,{attrs:{"outlined":""}},[_c(VListItem,[_c(VListItemAvatar,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-format-list-bulleted ")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"title",staticStyle:{"white-space":"normal"}},[_vm._v(" Evaluación ")])],1),(_vm.agregarSeccionDisponible && _vm.permisoEscritura && !_vm.vistaSeguimiento)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"title",attrs:{"x-small":"","icon":"","color":"primary","disabled":_vm.loading || !_vm.agregarSeccionDisponible},on:{"click":function($event){return _vm.mostrarAgregarSeccion()}}},on),[_c(VIcon,[_vm._v("mdi-text-box-plus-outline")])],1)]}}],null,false,3439887560)},[_c('span',[_vm._v("Agregar sección")])]):_vm._e()],1),(_vm.loading)?_c(VSkeletonLoader,{staticStyle:{"margin":"20px"},attrs:{"type":"list-item-two-line@3"}}):[(_vm.secciones.length == 0)?_c(VCardText,[_vm._v("No se han definido secciones")]):_vm._e(),_vm._l((_vm.secciones),function(seccion){return _c('seccionEvaluacionComponent',{key:seccion._id,attrs:{"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"seccion":seccion,"permisoEscritura":_vm.permisoEscritura},on:{"criterioAgregado":_vm.criterioAgregado,"criterioModificado":_vm.criterioModificado,"modificarSeccion":_vm.modificarSeccion,"eliminarSeccion":_vm.eliminarSeccionAction,"criterioEliminado":_vm.criterioEliminado}})})]],2)],1),(_vm.abrirEditorSeccion)?_c('seccionFormComponent',{attrs:{"mostrar":_vm.abrirEditorSeccion,"idCurso":_vm.idCurso,"tipoCurso":_vm.tipoCurso,"ponderacionMax":_vm.ponderacionMax,"editarSeccion":_vm.editarSeccion},on:{"cancelar":function($event){(_vm.abrirEditorSeccion = false), (_vm.editarSeccion = null)},"seccionAgregada":_vm.seccionAgregada,"seccionActualizada":_vm.seccionActualizada,"actualizarPuntosSecciones":_vm.actualizarPuntosSecciones}}):_vm._e(),(_vm.eliminarSeccion.mostrar)?_c('eliminarSeccion',{attrs:{"mostrar":_vm.eliminarSeccion.mostrar,"seccion":_vm.eliminarSeccion.seccion,"idCurso":_vm.idCurso,"tipoCurso":_vm.tipoCurso},on:{"cancelar":function($event){_vm.eliminarSeccion.mostrar = false},"seccionEliminada":_vm.seccionEliminada,"actualizarPuntosSecciones":_vm.actualizarPuntosSecciones}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }