import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"flat":""}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VSpacer),_vm._v(" Profesores "),_c(VSpacer),(_vm.permisoEscritura && _vm.app != 'campus')?_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.crearModulo()}}},[_vm._v(" mdi-text-box-plus ")]):_vm._e()],1),(_vm.loading)?_c('profesoresLoadingComponent'):[(_vm.modulos.length > 0)?_c(VRow,_vm._l((_vm.modulos),function(modulo){return _c(VCol,{key:modulo,attrs:{"md":"12","sm":"12"}},[_c('moduloItemComponent',{attrs:{"tipoCurso":_vm.tipoCurso,"idModulo":modulo,"cursoPlantilla":_vm.cursoPlantilla,"idCurso":_vm.idCurso,"tipoModulo":_vm.tipo,"permisoEscritura":_vm.permisoEscritura},on:{"moduloEliminado":_vm.moduloEliminado}})],1)}),1):_c(VRow,[_c(VCol,{attrs:{"md":"12","sm":"12"}},[_c(VCard,{staticClass:"my-2",staticStyle:{"text-align":"center"},attrs:{"outlined":""}},[_vm._v(" No hay módulos registrados ")])],1)],1)]],2),(_vm.agregarModulo)?_c('agregarModuloComponent',{attrs:{"mostrar":_vm.agregarModulo,"idCurso":_vm.idCurso,"tipoCurso":_vm.tipoCurso,"tipo":_vm.tipo,"editarModulo":_vm.editarModulo},on:{"cancelar":function($event){(_vm.agregarModulo = false), (_vm.editarModulo = null)},"moduloRegistrado":_vm.moduloRegistrado}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }