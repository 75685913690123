import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,[_c(VRow,{attrs:{"dense":"","justify":"space-between"}},[_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c(VSelect,{attrs:{"items":_vm.seccionesConTotal,"item-text":"nombre","item-value":"_id","label":"Sección","dense":"","outlined":"","no-data-text":"Sin secciones para mostrar","menu-props":_vm.menuProps},model:{value:(_vm.seccion),callback:function ($$v) {_vm.seccion=$$v},expression:"seccion"}})],1),(!_vm.vistaSeguimiento)?_c(VCol,{attrs:{"cols":"6","md":"2"}},[(_vm.sesionCursoProfesor && !_vm.sesionCursoPadre)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"secondary","outlined":"","loading":_vm.loading},on:{"click":function($event){return _vm.recalcularCalificaciones()}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-table-refresh")])],1)]}}],null,false,1773507047)},[_c('span',[_vm._v("Recalcular calificaciones")])]):_vm._e(),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"margin-left":"10px"},attrs:{"color":"#1D6F42","dark":"","loading":_vm.loading},on:{"click":function($event){return _vm.$emit('download')}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-file-excel")])],1)]}}],null,false,1948015057)},[_c('span',[_vm._v("Generar Excel")])])],1):_vm._e()],1),_c(VSimpleTable,[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"30px"}},[_vm._v("No.")]),_c('th',[_vm._v("Alumno")]),_vm._l((_vm.tableHeaders.headers),function(item){return _c('th',{key:item._id,staticStyle:{"width":"50px"}},[(item.tooltip)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({attrs:{"dark":"","label":"","color":item.color.codigo}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.texto)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.nombre))])]):_c(VChip,{attrs:{"label":""}},[_vm._v(_vm._s(item.texto))])],1)})],2)]),_c('tbody',_vm._l((_vm.calificacionesSeccion),function(calificacion,index){return _c('tr',{key:calificacion.usuario._id},[_c('td',[_c('span',[_vm._v(_vm._s(index + 1))])]),_c('td',[_c(VListItem,[_c(VListItemAvatar,[(calificacion.usuario.avatar)?_c('img',{attrs:{"src":(_vm.imageURL + "/icon/" + (calificacion.usuario.avatar)),"alt":"User"}}):_c(VIcon,[_vm._v("mdi-account")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(calificacion.usuario.nombreCompleto))])],1)],1)],1),_vm._l((calificacion.calificaciones),function(calificacionAlumno){return _c('td',{key:calificacionAlumno._id,staticStyle:{"width":"50px"},attrs:{"align":"center"}},[_c('span',[_vm._v(_vm._s(calificacionAlumno.calificacion))])])}),(_vm.seccion !== 'TOTAL')?_c('td',{staticStyle:{"width":"50px"},attrs:{"align":"center"},on:{"dblclick":function($event){return _vm.mostrarPtsExtraDialog(
                calificacion.usuario._id,
                calificacion.puntosExtra,
                calificacion.calificacion
              )}}},[_c('span',[_vm._v(_vm._s(calificacion.puntosExtra)+" ")])]):_vm._e(),_c('td',{staticStyle:{"width":"50px"},attrs:{"align":"center"}},[_c('span',[_vm._v(_vm._s(calificacion.final))])])],2)}),0)]),(_vm.ptsExtraData.mostrar)?_c('updatePuntosExtraSeccion',{attrs:{"mostrar":_vm.ptsExtraData.mostrar,"usuario":_vm.ptsExtraData.usuario,"currentPuntosExtra":_vm.ptsExtraData.puntos,"maximoPuntosExtra":_vm.ptsExtraData.maxPuntos,"seccion":_vm.seccion,"idCurso":_vm.idCurso},on:{"cancelar":function($event){_vm.ptsExtraData.mostrar = false},"puntosActualizados":_vm.puntosActualizados}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }