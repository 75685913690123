import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"flat":""}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VSpacer),_vm._v(" Avisos "),_c(VSpacer),(_vm.permisoEscritura && !_vm.vistaSeguimiento)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"white","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{on:{"click":function($event){_vm.agregarAviso = true}}},[_vm._v(" mdi-text-box-plus ")])],1)]}}],null,false,3494269721)},[_c('span',[_vm._v("Agregar aviso")])]):_vm._e()],1),_c(VCard,{attrs:{"outlined":""}},[(_vm.loading)?_c(VContainer,{attrs:{"fluid":""}},[_c('avisoLoadingComponent')],1):_vm._e(),(_vm.avisos.length == 0 && !_vm.loading)?_c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"text-left",attrs:{"outlined":""}},[_c(VCardSubtitle,[_vm._v("No se han registrado avisos ")])],1)],1):_c(VContainer,{attrs:{"fluid":""}},_vm._l((_vm.avisos),function(aviso,index){return _c(VCard,{key:index,staticClass:"mb-2",attrs:{"outlined":""}},[(_vm.tipoCuentaUsuario === 'profesor')?_c('avisoProfesorComponent',{attrs:{"idCurso":_vm.idCurso,"tipoCurso":_vm.tipoCurso,"permisoEscritura":_vm.permisoEscritura,"aviso":aviso},on:{"avisoModificado":_vm.avisoModificado,"avisoVisibleModificado":_vm.avisoVisibleModificado,"avisoConfirmacionModificado":_vm.avisoConfirmacionModificado,"avisoEliminado":_vm.avisoEliminado}}):_vm._e(),(_vm.tipoCuentaUsuario === 'alumno')?_c('avisoAlumnoComponent',{attrs:{"idCurso":_vm.idCurso,"tipoCurso":_vm.tipoCurso,"aviso":aviso,"permisoEscritura":_vm.permisoEscritura},on:{"avisoVisto":_vm.avisoVisto,"avisoVistoConfirmado":_vm.avisoVistoConfirmado}}):_vm._e()],1)}),1)],1)],1),(_vm.agregarAviso)?_c('agregarAvisoComponent',{attrs:{"mostrar":_vm.agregarAviso,"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso},on:{"cancelar":function($event){_vm.agregarAviso = false},"nuevoAviso":_vm.nuevoAviso}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }