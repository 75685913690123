import { getServerRequest, postServerRequest, putServerRequest, patchServerRequest } from '../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function obtenerConfiguracionService(idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/configuracion/${idCurso}`;
    return await getServerRequest(url, config);
}

export async function obtenerAlmacenamientoService(idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/almacenamiento/${idCurso}`;
    return await getServerRequest(url, config);
}

export async function obtenerUsuariosService(tipoUsuario, tipoCurso, idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/${tipoCurso}/usuarios/${tipoUsuario}/${idCurso}`;
    return await getServerRequest(url, config);
}

export async function agregarUsuarioService(tipoUsuario, tipoCurso, idCurso, idUsuario) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const data = { idUsuario }
    const url = `${httpURL}/cursos/${tipoCurso}/usuarios/${tipoUsuario}/${idCurso}`;
    return await putServerRequest(url, data, config);
}

export async function quitarUsuarioService(ruta, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/${ruta}`;
    return await patchServerRequest(url, data, config);
}

export async function obtenerLogService(tipoCurso, idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/configuracion/logs/${idCurso}`;
    return await getServerRequest(url, config);
}

export async function modificarConfiguracionService(idCurso, campo, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/campus/configuracion/${campo}/${idCurso}`;
    return await patchServerRequest(url, data, config);
}

export async function modificarAvanceAutomaticoService(tipoCurso, idCurso, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/avance/automatico/${idCurso}`;
    return await patchServerRequest(url, data, config);
}