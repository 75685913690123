import { getServerRequest } from '../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function obtenerModuloProfesorService(tipoCurso, idCurso, idModulo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/profesor/${idCurso}/modulo/${idModulo}`;
    return await getServerRequest(url, config);
}

export async function obtenerClasesCampusService(tipoCurso, idCurso, idModulo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/profesor/clases/${idCurso}/${idModulo}`;
    return await getServerRequest(url, config);
}

export async function obtenerRecursosCampusService(tipoCurso, idCurso, idModulo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/profesor/recursos/${idCurso}/${idModulo}`;
    return await getServerRequest(url, config);
}

export async function obtenerClaseProfesorService(tipoCurso, idCurso, idClase) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/profesor/clase/${idCurso}/${idClase}`;
    return await getServerRequest(url, config);
}