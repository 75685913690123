import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VImg,{staticClass:"fondo",attrs:{"src":_vm.image}},[_c(VAppBar,{attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c(VBtn,{attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.regresar()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),(_vm.permisoEscritura && !_vm.vistaSeguimiento)?_c(VMenu,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"white"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2814390451)},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){_vm.modificarNombre = true}}},[_c(VListItemTitle,[_vm._v("Modificar nombre de curso")]),_c(VListItemIcon,[_c(VIcon,{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v(" mdi-square-edit-outline ")])],1)],1),_c(VListItem,{on:{"click":function($event){_vm.modificarPortada = true}}},[_c(VListItemTitle,[_vm._v("Modificar imagen de curso")]),_c(VListItemIcon,[_c(VIcon,{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v(" mdi-image ")])],1)],1)],1)],1):_vm._e()],1),_c(VRow,{staticClass:"lightbox white--text pa-5 fill-height",staticStyle:{"position":"relative"},attrs:{"align":"end"}},[_c(VCol,[_c('div',{staticClass:"headline mb-15"},[_c('h2',[_vm._v(_vm._s(_vm.nombre))])])])],1),(_vm.modificarNombre)?_c('agregarPlantillaComponent',{attrs:{"mostrar":_vm.modificarNombre,"nombreAnterior":_vm.nombre},on:{"cancelar":function($event){_vm.modificarNombre = false},"cursoModificado":_vm.cursoModificado}}):_vm._e(),(_vm.modificarPortada)?_c('modificarPortadaComponent',{attrs:{"mostrar":_vm.modificarPortada,"idCurso":_vm.idCurso},on:{"cancelar":function($event){_vm.modificarPortada = false},"imagenModificada":_vm.imagenModificada}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }