import { getServerRequest, postServerRequest, patchServerRequest } from '../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function obtenerCursoService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${data.tipoCurso}/${data.idCurso}`;
    return await getServerRequest(url, config);
}

export async function modificarNombreCursoService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${data.tipoCurso}/nombre/${data.idCurso}`;
    return await patchServerRequest(url, { nombre: data.nombre }, config);
}

export async function modificarPortadaService(data) {
    const token = store.getters.sessionToken;
    const config = { 
        headers: { token },
        ...data.uploadHandler
    };
    const url = `${httpURL}/curso/${data.tipoCurso}/portada/${data.idCurso}`;
    return await patchServerRequest(url, data.image, config);
}