<template>
  <v-dialog v-model="mostrar" :max-width="300" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dark dense flat class="text-h6">
          {{titulo}}
      </v-toolbar>

      <v-container grid-list-md>
        <v-select v-model="dataLocal" :items="items" :label="subtitulo" :disabled="loading"></v-select>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined small :disabled="loading" @click="cerrarForm()">
            Cancelar
          </v-btn>
          <v-btn color="primary" dark small :loading="loading" @click="modificarConfig()">
            Modificar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import { modificarConfiguracionService } from "./configuracion.service";

export default {
  props: {
    mostrar: Boolean,
    titulo: String,
    subtitulo: String,
    idCurso: String,
    tipoModificacion: String,
    valor: Number,
  },
  data: () => ({
    loading: false,
    items:[],
    dataLocal: 0,
  }),
  created() {
    if(this.tipoModificacion == 'fileSize'){
      for(let i=1; i<=10; i+=1)
          this.items.push({ value:i, text: i + ' MB' })
    } 
    if(this.tipoModificacion == 'maxAlumnos') {
      for(let i=5; i<=100; i+=5)
          this.items.push({ value:i, text: i })
    } 
    if(this.tipoModificacion == 'retardosFalta') {
      this.items.push({ value:0, text: 'Ninguno' })
      this.items.push({ value:1, text: '1 Retardo' })
      for(let i=2; i<=10; i+=1)
          this.items.push({ value:i, text: i + ' Retardos' })
    }
    this.dataLocal = this.valor;
  },
  methods: {
    async modificarConfig() {
      try {

        this.loading = true;
        const campo = this.tipoModificacion
        const data = { nuevoValor:this.dataLocal }
        const serverResponse = await modificarConfiguracionService(this.idCurso, campo, data);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("valorModificado", serverResponse.nuevoValor);
          this.$emit("nuevoLog", serverResponse.nuevoLog);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    cerrarForm() {
      this.$emit("cancelar");
    },
  },
};
</script>