import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,[_c(VListItemIcon,[_c(VCheckbox,{attrs:{"color":"primary","hide-details":"","disabled":_vm.loading},on:{"change":function($event){return _vm.updateAvanceAutomatico()}},model:{value:(_vm.avanceAutomatico),callback:function ($$v) {_vm.avanceAutomatico=$$v},expression:"avanceAutomatico"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Avance automático ")]),_c(VListItemSubtitle,[_vm._v("Los módulos se mostrarán cuando se complete el módulo anterior")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }