import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"300"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{attrs:{"dense":"","dark":"","flat":"","tile":"","color":"primary"}},[_c(VToolbarTitle,[_c('span',[_vm._v("Actualizar puntos extra")])])],1),_c(VCardText,{staticClass:"pt-5"},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.puntosDisponibles,"outlined":"","dense":"","rules":_vm.formRules,"label":"Puntos extra"},model:{value:(_vm.formData.puntosExtra),callback:function ($$v) {_vm.$set(_vm.formData, "puntosExtra", $$v)},expression:"formData.puntosExtra"}})],1),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_c('span',[_vm._v("Cancelar")])]),_c(VBtn,{attrs:{"color":"primary","small":"","loading":_vm.loading,"disabled":!_vm.isFormValid},on:{"click":function($event){return _vm.submit()}}},[_c('span',[_vm._v("Guardar")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }