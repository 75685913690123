<template>
  <div>
    <v-container>
      <v-card outlined>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon color="primary"> mdi-format-list-bulleted </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title" style="white-space: normal">
              Evaluación
            </v-list-item-title>
          </v-list-item-content>

          <v-tooltip v-if="agregarSeccionDisponible && permisoEscritura && !vistaSeguimiento" top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="title"
                x-small
                icon
                color="primary"
                :disabled="loading || !agregarSeccionDisponible"
                @click="mostrarAgregarSeccion()"
              >
                <v-icon>mdi-text-box-plus-outline</v-icon>
              </v-btn>
            </template>
            <span>Agregar sección</span>
          </v-tooltip>
        </v-list-item>

        <v-skeleton-loader
          v-if="loading"
          type="list-item-two-line@3"
          style="margin: 20px"
        ></v-skeleton-loader>

        <template v-else>
          <v-card-text v-if="secciones.length == 0"
            >No se han definido secciones</v-card-text
          >
          <seccionEvaluacionComponent
            v-for="seccion in secciones"
            :key="seccion._id"
            :tipoCurso="tipoCurso"
            :idCurso="idCurso"
            :seccion="seccion"
            :permisoEscritura="permisoEscritura"
            @criterioAgregado="criterioAgregado"
            @criterioModificado="criterioModificado"
            @modificarSeccion="modificarSeccion"
            @eliminarSeccion="eliminarSeccionAction"
            @criterioEliminado="criterioEliminado"
          />
        </template>
      </v-card>
    </v-container>
    <seccionFormComponent
      v-if="abrirEditorSeccion"
      :mostrar="abrirEditorSeccion"
      :idCurso="idCurso"
      :tipoCurso="tipoCurso"
      :ponderacionMax="ponderacionMax"
      :editarSeccion="editarSeccion"
      @cancelar="(abrirEditorSeccion = false), (editarSeccion = null)"
      @seccionAgregada="seccionAgregada"
      @seccionActualizada="seccionActualizada"
      @actualizarPuntosSecciones="actualizarPuntosSecciones"
    />

    <eliminarSeccion
      v-if="eliminarSeccion.mostrar"
      :mostrar="eliminarSeccion.mostrar"
      :seccion="eliminarSeccion.seccion"
      :idCurso="idCurso"
      :tipoCurso="tipoCurso"
      @cancelar="eliminarSeccion.mostrar = false"
      @seccionEliminada="seccionEliminada"
      @actualizarPuntosSecciones="actualizarPuntosSecciones"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import seccionEvaluacionComponent from "./seccionEvaluacion.vue";
import seccionFormComponent from "./seccionForm.vue";
import eliminarSeccion from "./eliminarSeccion.vue";
import _ from "underscore";
import { obtenerEvaluacionCursoService } from "./evaluacion.service.js";
import { obtenerEvaluacionCursoControlParental } from "../../../controlParental/controlParental.service";

export default {
  name: "evaluacion",
  props: {
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    seccionEvaluacionComponent,
    seccionFormComponent,
    eliminarSeccion,
  },
  computed: {
    ...mapGetters(["sessionToken", "sesionCursoPadre", "vistaSeguimiento"]),
    agregarSeccionDisponible() {
      let sumatoria = 0;
      if (this.secciones.length > 0) {
        this.secciones.forEach((seccion) => {
          if (seccion.ponderacion > 0) {
            sumatoria += seccion.ponderacion;
          }
        });
      }
      return sumatoria < 100;
    },
  },
  watch: {
    sessionToken() {
      this.cargarEvaluacion();
    },
  },
  data: () => ({
    loading: false,
    idCurso: null,
    tipoCurso: null,
    secciones: [],

    boton: true,
    abrirEditorSeccion: false,
    editarSeccion: null,
    abrirEditorCriterio: false,
    editarCriterio: null,
    ponderacionMax: 100,

    eliminarCriterio: {
      mostrar: false,
      criterio: {},
    },
    eliminarSeccion: {
      mostrar: false,
      seccion: {},
    },
  }),
  created() {
    this.idCurso = this.$route.params.idCurso;
    this.tipoCurso = this.$route.params.tipoCurso;
  },
  mounted() {
    if (this.sessionToken) this.cargarEvaluacion();
  },
  methods: {
    async cargarEvaluacion() {
      try {
        this.loading = true;
        
        let serverResponse = null;
        if(this.sesionCursoPadre) serverResponse = await obtenerEvaluacionCursoControlParental(this.idCurso);
        else serverResponse = await obtenerEvaluacionCursoService(this.tipoCurso, this.idCurso);

        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.secciones = serverResponse.secciones;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    totalSeccion(id) {
      let index = this.secciones.findIndex((x) => x._id.toString() == id);
      let total = 0;
      if (index !== -1) {
        this.secciones[index].criterios.forEach((criterio) => {
          total += criterio.ponderacion;
        });
      }

      return total;
    },
    mostrarAgregarSeccion() {
      let sumatoria = 0;
      if (this.secciones.length > 0) {
        this.secciones.forEach((seccion) => {
          if (seccion.ponderacion > 0) {
            sumatoria += seccion.ponderacion;
          }
        });
      }
      this.ponderacionMax = 100 - sumatoria;
      this.abrirEditorSeccion = true;
    },
    seccionAgregada(nuevaSeccion) {
      this.secciones.push(nuevaSeccion);
    },
    seccionActualizada(seccionesActualizadas) {
      this.secciones.forEach((seccion) => {
        const indexSeccion = seccionesActualizadas.findIndex(
          (x) => x._id == seccion._id
        );
        if (indexSeccion >= 0) {
          seccion.nombre = seccionesActualizadas[indexSeccion].nombre;
          seccion.ponderacion = seccionesActualizadas[indexSeccion].ponderacion;
          if (seccionesActualizadas[indexSeccion].criterios.length > 0)
            seccion.criterios = seccionesActualizadas[indexSeccion].criterios;
        }
      });
    },
    modificarSeccion(idSeccion) {
      this.editarSeccion = {};
      let sumatoria = 0;
      this.secciones.forEach((seccion) => {
        if (seccion._id != idSeccion) {
          if (seccion.ponderacion > 0) sumatoria += seccion.ponderacion;
        } else this.editarSeccion = seccion;
      });
      this.ponderacionMax = 100 - sumatoria;
      this.abrirEditorSeccion = true;
    },
    eliminarSeccionAction(idSeccion) {
      this.eliminarSeccion.seccion = this.secciones.find(
        (x) => x._id == idSeccion
      );
      this.eliminarSeccion.mostrar = true;
    },
    seccionEliminada(idSeccion) {
      const index = this.secciones.findIndex((x) => x._id == idSeccion);
      if (index >= 0) this.secciones.splice(index, 1);
    },
    actualizarPuntosSecciones(seccionesActualizadas) {
      for (const seccion of seccionesActualizadas) {
        const index = this.secciones.findIndex((x) => x._id == seccion._id);
        if (index >= 0) this.secciones[index].puntos = seccion.puntos;
      }
    },
    actualizarSeccion(data) {
      let index = this.secciones.findIndex((x) => x._id === data._id);
      if (index !== -1) this.secciones.splice(index, 1, data);
    },
    criterioAgregado(data) {
      const index = this.secciones.findIndex((x) => x._id == data.idSeccion);
      if (index >= 0) this.secciones[index].criterios.push(data.nuevoCriterio);
    },
    criterioModificado(data) {
      const criterio = data.criterioModificado;
      const indexSeccion = this.secciones.findIndex(
        (x) => x._id == data.idSeccion
      );
      if (indexSeccion >= 0) {
        const indexCriterio = this.secciones[indexSeccion].criterios.findIndex(
          (x) => x._id == criterio._id
        );
        this.secciones[indexSeccion].criterios[indexCriterio].nombre =
          criterio.nombre;
        this.secciones[indexSeccion].criterios[indexCriterio].ponderacion =
          criterio.ponderacion;
        this.secciones[indexSeccion].criterios[indexCriterio].puntos =
          criterio.puntos;
        this.secciones[indexSeccion].criterios[indexCriterio].asistencias =
          criterio.asistencias;
      }
    },
    criterioEliminado(data) {
      const indexSeccion = this.secciones.findIndex(
        (x) => x._id == data.idSeccion
      );
      const indexCriterio = this.secciones[indexSeccion].criterios.findIndex(
        (x) => x._id == data.idCriterio
      );
      this.secciones[indexSeccion].criterios.splice(indexCriterio, 1);
    },
  },
};
</script>
