<template>
  <v-row>
    <v-col md="12" sm="12">
      <v-card outlined class="my-2">
        <v-skeleton-loader
          type="table-tbody"
          style="margin: 20px"
        ></v-skeleton-loader>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "asistenciasLoading",
  data() {
    return {
      loading: false,
    };
  },
};
</script>
