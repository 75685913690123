<template>
  <datatable
    :datos="alumnos"
    :pagination="{ page: 1, itemsPerPage: alumnos.length }"
    :page="1"
    :totalItems="alumnos.length"
    :headers="tableHeaders"
    hide-search
    hide-pagination
  >
    <template #item-avatar="{ value }">
      <v-avatar color="primary" size="30">
        <v-icon color="white" v-if="!value">mdi-account</v-icon>
        <img v-else :src="`${imageURL}/avatar/${value}`" alt="UserImage" />
      </v-avatar>
    </template>

    <template #item-nombreCompleto="{ item }">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.nombreCompleto }}
            <v-chip
              v-if="item.bloqueado && !sesionCursoAlumno"
              color="red darken-2"
              x-small
              outlined
              >Bloqueado</v-chip
            >
          </v-list-item-title>
          <v-list-item-subtitle v-if="!sesionCursoAlumno">{{
            item.correo
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template #item-menu="{ item }" >
      <integranteMenu 
        v-if="(sesionCursoAlumno && userId == item._id && !cursoEscuela && permisoEscritura) || (!sesionCursoAlumno && !vistaSeguimiento && permisoEscritura)"
        :bloqueado="item.bloqueado"
        :idCurso="idCurso"
        :idIntegrante="item._id"
        :nombreIntegrante="item.nombreCompleto"
        :cursoEscuela="cursoEscuela"
        :permisoEscritura="permisoEscritura"
        @blockUsuario="blockUsuario"
        @integranteEliminado="eliminarIntegrante"
      />
    </template>
  </datatable>
</template>

<script>
import datatable from "../../widgets/datatable.vue";
import integranteMenu from "./integranteMenu.vue";
import { mapGetters } from "vuex";

export default {
  name: "tablaAlumnos",
  props: {
    idCurso: { type: String || null },
    alumnos: { type: Array, default: () => [] },
    permisoEscritura: { type: Boolean, default: false },
    cursoEscuela: { type: Boolean, default: false },
  },
  components: {
    datatable,
    integranteMenu,
  },
  computed: {
    ...mapGetters(["imageURL", "sessionToken", "sesionCursoAlumno", "userId", "vistaSeguimiento"]),
    alumnosLocal() {
      const alumnos = [];
      let numero = 1;
      for (const alumno of this.alumnos) {
        const nuevoAlumno = {
          _id: alumno._id,
          numero: numero++,
          nombreCompleto: alumno.nombreCompleto,
          avatar: alumno.avatar,
          bloqueado: alumno.bloqueado,
        };
        alumnos.push(nuevoAlumno);
      }
      return alumnos;
    },
  },
  data() {
    return {
      tableHeaders: [
        { value: "numero", text: "#", sortable: false, width: "40px" },
        { value: "avatar", text: "", sortable: false, width: "50px" },
        { value: "nombreCompleto", text: "Nombre", sortable: false },
        { value: "menu", text: "", sortable: false, width: "50px" },
      ],
    };
  },
  methods: {
    blockUsuario(data) {
      this.$emit("blockUsuario", data);
    },
    eliminarIntegrante(idIntegrante) {
      this.$emit("integranteEliminado", idIntegrante);
    },
  },
};
</script>
