import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VTabs,{attrs:{"centered":"","color":"secondary","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":"warning"}}),_c(VTab,{attrs:{"href":"#encuadre","to":"#encuadre"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-book-open-outline")])]}}])},[_c('span',[_vm._v("Encuadre")])])],1),(_vm.tipoCurso == 'campus' && !_vm.sesionCursoPadre)?_c(VTab,{attrs:{"href":"#avisos","to":"#avisos"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-bullhorn")])]}}],null,false,1132632835)},[_c('span',[_vm._v("Avisos")])])],1):_vm._e(),(!_vm.sesionCursoPadre)?_c(VTab,{attrs:{"href":"#modulos","to":"#modulos"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-view-list")])]}}],null,false,1640329069)},[_c('span',[_vm._v("Módulos")])]),_c(VIcon)],1):_vm._e(),_c(VTab,{attrs:{"href":"#actividades","to":"#actividades"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-format-list-text")])]}}])},[_c('span',[_vm._v("Actividades")])]),_c(VIcon)],1),(!_vm.sesionCursoAlumno && !_vm.sesionCursoPadre)?_c(VTab,{attrs:{"href":"#profesores","to":"#profesores"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-briefcase")])]}}],null,false,2645048033)},[_c('span',[_vm._v("Profesores")])])],1):_vm._e(),(_vm.tipoCurso == 'campus' && !_vm.sesionCursoPadre)?_c(VTab,{attrs:{"href":"#integrantes","to":"#integrantes"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-account-group")])]}}],null,false,1602634556)},[_c('span',[_vm._v("Integrantes")])])],1):_vm._e(),(_vm.tipoCurso == 'campus')?_c(VTab,{attrs:{"href":"#asistencias","to":"#asistencias"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-clipboard-text-clock-outline")])]}}],null,false,3556469051)},[_c('span',[_vm._v("Asistencias")])])],1):_vm._e(),(_vm.tipoCurso == 'campus')?_c(VTab,{attrs:{"href":"#calificaciones","to":"#calificaciones"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-chart-box")])]}}],null,false,2536221339)},[_c('span',[_vm._v("Calificaciones")])])],1):_vm._e(),(!_vm.sesionCursoAlumno && !_vm.sesionCursoPadre)?_c(VTab,{attrs:{"href":"#configuracion","to":"#configuracion"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-cog")])]}}],null,false,979715332)},[_c('span',[_vm._v("Configuración")])])],1):_vm._e()],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{attrs:{"value":"encuadre"}},[_c('encuadreTabComponent',{attrs:{"permisoEscritura":_vm.permisoEscritura}})],1),(_vm.tipoCurso == 'campus')?_c(VTabItem,{attrs:{"value":"avisos"}},[_c('avisosTabComponent',{attrs:{"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"permisoEscritura":_vm.permisoEscritura}})],1):_vm._e(),_c(VTabItem,{attrs:{"value":"modulos"}},[_c('modulosTabComponent',{attrs:{"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"permisoEscritura":_vm.permisoEscritura}})],1),_c(VTabItem,{attrs:{"value":"actividades"}},[_c('actividadesTabComponent',{attrs:{"tipoCurso":_vm.tipoCurso}})],1),(!_vm.sesionCursoAlumno && (_vm.tipoCurso != 'campus' || _vm.cursoPlantilla != null))?_c(VTabItem,{attrs:{"value":"profesores"}},[_c('profesoresTabComponent',{attrs:{"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"cursoPlantilla":_vm.cursoPlantilla,"permisoEscritura":_vm.permisoEscritura}})],1):_vm._e(),(_vm.tipoCurso == 'campus')?_c(VTabItem,{attrs:{"value":"integrantes"}},[_c('integrantesTabComponent')],1):_vm._e(),(_vm.tipoCurso == 'campus')?_c(VTabItem,{attrs:{"value":"asistencias"}},[_c('asistenciasTabComponent',{attrs:{"permisoEscritura":_vm.permisoEscritura}})],1):_vm._e(),(_vm.tipoCurso == 'campus')?_c(VTabItem,{attrs:{"value":"calificaciones"}},[_c('calificacionesTabComponent')],1):_vm._e(),(!_vm.sesionCursoAlumno)?_c(VTabItem,{attrs:{"value":"configuracion"}},[_c('configuracionTabComponent',{attrs:{"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"curso":_vm.curso,"permisoEscritura":_vm.permisoEscritura}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }