<template>
  <div>
    <v-card flat>
          <v-list>
            <template v-for="(item, index) in alumnosPromedio">
              <v-list-item :key="`item-${index}`">
                <v-list-item-icon class="numero-lista">
                  <strong>{{index+1}}</strong>
                </v-list-item-icon>
                <v-list-item-avatar v-if="!sesionCursoAlumno && !sesionCursoPadre" class="avatar-lista">
                  <img v-if="item.alumno.avatar" :src="`${imageURL}/icon/${item.alumno.avatar}`">
                <v-icon v-else size="45">mdi-account-circle</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-if="!sesionCursoAlumno && !sesionCursoPadre">{{item.alumno.nombreCompleto}}</v-list-item-title>
                  <v-list-item-title v-else>{{item.periodo.text}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action @click="verAsistenciasMes(item.promedio.asistenciasMes)" v-if="!loading" >
                    <v-chip small outlined label
                      :color="item.promedio.color"
                      style="width: 60px;">
                      {{item.promedio.asistencias}} / {{item.promedio.total}}
                    </v-chip>
                </v-list-item-action>
                <v-list-item-action v-if="!loading" style="padding-right: 50px;">
                    <v-progress-circular :value="item.promedio.promedio" :color="item.promedio.color" >
                    <span style="font-size: 10px;">{{item.promedio.promedio}}</span>
                    </v-progress-circular>
                </v-list-item-action>
                <v-list-item-action v-if="loading" style="padding-right: 50px;">
                    <v-progress-circular indeterminate color="grey" >
                    <span style="font-size: 10px;">SC</span>
                    </v-progress-circular>
                </v-list-item-action>
              </v-list-item>
              <v-divider class="mx-3" :key="index"></v-divider>
            </template>
          </v-list>
    </v-card>

    <asistenciasAlumnoMesComponent
      v-if="mostrarAsistencias == true"
      :mostrar="mostrarAsistencias"
      :asistenciasMes="asistenciasMes"
      @cerrar="mostrarAsistencias = false"
    />
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import asistenciasAlumnoMesComponent from './asistenciasAlumnoMes.vue';

export default {
  props: {
    idCurso: String,
    alumnos: Array,
    idHijo: String,
    asistencias: { type: Array, default: null },
    periodo: Number,
    periodoItems: Array,
    refrescar: Number,
    retardosFalta: Number,
    coloresAsistencias: { type: Array, default: null },
  },
  components: { 
    asistenciasAlumnoMesComponent,
  },
  computed: {
    ...mapGetters([
      "imageURL",
      "sessionToken",
      "sesionCursoAlumno",
      "sesionCursoPadre", 
      "userId",
      "timezone"
    ]),
    alumnosPromedio(){
      let promedios = [];
      if (this.sesionCursoAlumno || this.sesionCursoPadre) {
        this.periodoItems.forEach(periodo => {
          if (periodo.value != 0) 
            promedios.push({
              periodo,
              promedio: this.promedioMensual(periodo)
            })
        });
      }else {
        this.alumnos.forEach(alumno => {
          promedios.push({
            alumno,
            promedio: this.promedioAlumno(alumno._id)
          })
        });
      }
      return promedios;
    }
  },
  watch:{
    asistencias() {
      this.alumnosPromedio;
    },
    refrescar() {
      this.alumnosPromedio;
    }
  },
  data: () => ({
    loading: false,
    asistenciasMes: [],
    mostrarAsistencias: false,
  }),
  mounted(){
  },
  methods: {
     promedioAlumno(alumno){
        let asistenciasTemp = [];
        if (this.periodo == 0) asistenciasTemp = this.asistencias
        else {
          this.asistencias.forEach(asistencia => {
            let mes = DateTime.fromISO(asistencia.cursoAsistencia.fecha, { zone: this.timezone }).toFormat('yyyy-MM-dd');
            mes = mes.split('-');
            let periodo = parseInt(mes[1])
            if (periodo == this.periodo) asistenciasTemp.push(asistencia);
          });
        }

        let promedioData = {total: asistenciasTemp.length}
        let asistenciasAlumno = [];
        const retardos = [];
        asistenciasTemp.forEach(asistencia => {
          const asis = asistencia.asistencia.find(x=> x.usuario == alumno && (x.asistencia == "A" || x.asistencia == "J" || x.asistencia == "R"));
          if (asis) asistenciasAlumno.push(asis);
          if (asis && asis.asistencia == "R") retardos.push(asis);
        });
        
        let faltasPorRetardos = retardos.length/this.retardosFalta;
        faltasPorRetardos = Math.trunc(faltasPorRetardos);
        const promedio = Math.round(100/promedioData.total * (asistenciasAlumno.length - faltasPorRetardos));
        promedioData.asistencias = asistenciasAlumno.length - faltasPorRetardos;
        promedioData.promedio = isNaN(promedio) ? 'SC%' : promedio + '%'

        if(this.coloresAsistencias){
          if(this.coloresAsistencias && this.coloresAsistencias.length > 0){
            this.coloresAsistencias.sort(this.comparaPorcentajes);
            for (let i = 0; i < this.coloresAsistencias.length; i++) {
              let porcentajeMinimo = this.coloresAsistencias[i].porcentajeMinimo;
              let nextPorcentajeMinimo = 0;
              if(i < this.coloresAsistencias.length - 1) {
                nextPorcentajeMinimo = this.coloresAsistencias[i+1].porcentajeMinimo
              }

              if((promedio >= porcentajeMinimo && promedio < nextPorcentajeMinimo) || (promedio >= porcentajeMinimo && i == this.coloresAsistencias.length - 1)){
                promedioData.color = this.coloresAsistencias[i].color;
              }
            }
          }else{
            promedioData.color = 'black';
          }
        }else {
          if (isNaN(promedio)) promedioData.color = '#757575';
          else if (promedio > 80) promedioData.color = '#4CAF50';
          else if (promedio > 60) promedioData.color = '#EBD559';
          else promedioData.color = '#A70101';
        }

        return promedioData;
     },
     promedioMensual(periodoTmp){
        let asistenciasTemp = [];
        const idUsuario = this.sesionCursoPadre ? this.idHijo : this.userId;
        this.asistencias.forEach(asistencia => {
          let mes = DateTime.fromISO(asistencia.cursoAsistencia.fecha).toFormat('yyyy-MM-dd');
          mes = mes.split('-');
          let periodo = parseInt(mes[1])
          if (periodo == periodoTmp.value) asistenciasTemp.push(asistencia);
        });

        let promedioData = {total: asistenciasTemp.length}
        let asistenciasAlumno = [];
        const retardos = [];
        let asistenciasMes = []

        asistenciasTemp.forEach(asistencia => {
          const asis = asistencia.asistencia.find(x=> x.usuario == idUsuario && (x.asistencia == "A" || x.asistencia == "J" || x.asistencia == "R"));
          if (asis) asistenciasAlumno.push(asis);
          if (asis && asis.asistencia == "R") retardos.push(asis);

          const fechaAsis = DateTime.fromISO(asistencia.cursoAsistencia.fecha).setLocale('es').toFormat("dd / MMMM / yyyy");
          const asisMes = {fecha: fechaAsis};
          if(asis && asis.asistencia == "A") {
            asisMes.asistencia = 'Asistencia';
            asisMes.color = '#43A047';
          }
          else if(asis && asis.asistencia == "J") {
            asisMes.asistencia = 'Justificada';
            asisMes.color = '#1E88E5';
          }
          else if(asis && asis.asistencia == "R") {
            asisMes.asistencia = 'Retardo';
            asisMes.color = '#fb8c00';
          }
          else {
            asisMes.asistencia = 'Falta';
            asisMes.color = '#E53935';
          }

          asistenciasMes.push(asisMes);
        });

        promedioData.asistenciasMes = asistenciasMes;
        
        let faltasPorRetardos = retardos.length/this.retardosFalta;
        faltasPorRetardos.toFixed();
        const promedio = Math.round(100/promedioData.total * (asistenciasAlumno.length - faltasPorRetardos));
        promedioData.asistencias = asistenciasAlumno.length - faltasPorRetardos;
        promedioData.promedio = promedio + '%';

        if(this.coloresAsistencias){
          if(this.coloresAsistencias && this.coloresAsistencias.length > 0){
            this.coloresAsistencias.sort(this.comparaPorcentajes);
            for (let i = 0; i < this.coloresAsistencias.length; i++) {
              let porcentajeMinimo = this.coloresAsistencias[i].porcentajeMinimo;
              let nextPorcentajeMinimo = 0;
              if(i < this.coloresAsistencias.length - 1) {
                nextPorcentajeMinimo = this.coloresAsistencias[i+1].porcentajeMinimo
              }

              if((promedio >= porcentajeMinimo && promedio < nextPorcentajeMinimo) || (promedio >= porcentajeMinimo && i == this.coloresAsistencias.length - 1)){
                promedioData.color = this.coloresAsistencias[i].color;
              }
            }
          }else{
            promedioData.color = 'black'
          }
        }else {
          if (promedio > 80) promedioData.color = '#4CAF50';
          else if (promedio > 60) promedioData.color = '#EBD559';
          else promedioData.color = '#A70101';
        }

        return promedioData;
     },
     comparaPorcentajes(a, b) {
        return a.porcentajeMinimo - b.porcentajeMinimo;
     },
     verAsistenciasMes(asistenciasMes){
      if(this.sesionCursoAlumno || this.sesionCursoPadre){
        this.mostrarAsistencias = true;
        this.asistenciasMes = asistenciasMes;
      }
     }
  }
};
</script>

<style>
  .numero-lista{
      margin-right: 10px !important;
      vertical-align: middle;
  }
  .avatar-lista{
      margin-right: 20px !important;
  }
  .v-chip__content{
    margin: auto;
  }
</style>