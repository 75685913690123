<template>
  <v-list-item>
    <v-list-item-icon>
      <v-checkbox
        v-model="avanceAutomatico"
        color="primary"
        hide-details
        :disabled="loading"
        @change="updateAvanceAutomatico()"
      >
      </v-checkbox>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>Avance automático </v-list-item-title>
      <v-list-item-subtitle
        >Los módulos se mostrarán cuando se complete el módulo
        anterior</v-list-item-subtitle
      >
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import { modificarAvanceAutomaticoService } from "./configuracion.service";

export default {
  name: "retardosFalta",
  props: {
    tipoCurso: String,
    idCurso: String,
    avance: Boolean,
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {},
  computed: {
    ...mapGetters(["vistaSeguimiento"]),
  },
  data() {
    return {
      loading: false,
      avanceAutomatico: false,
    };
  },
  watch: {
    avance() {
      this.avanceAutomatico = this.avance;
    },
  },
  mounted() {
    this.avanceAutomatico = this.avance;
  },
  methods: {
    async updateAvanceAutomatico() {
      try {
        this.loading = true;
        const data = { avanceAutomatico: this.avanceAutomatico };
        const serverResponse = await modificarAvanceAutomaticoService(this.tipoCurso, this.idCurso, data);
        this.loading = false;

        if (!serverResponse.ok) {
          this.avanceAutomatico = !this.avanceAutomatico;
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("nuevoLog", serverResponse.nuevoLog);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    // valorModificado(nuevoRetardosFalta){
    //   this.$emit("retardosFaltaModificado", nuevoRetardosFalta);
    // },
    // nuevoLog(log){
    //   this.$emit("nuevoLog", log);
    // }
  },
};
</script>
