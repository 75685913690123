<template>
  <div>
    <v-container>
      <v-row dense justify="space-between">
        <v-col cols="12" md="3">
          <v-select
            v-model="seccion"
            :items="seccionesConTotal"
            item-text="nombre"
            item-value="_id"
            label="Sección"
            dense
            outlined
            no-data-text="Sin secciones para mostrar"
            :menu-props="menuProps"
          >
          </v-select>
        </v-col>
        <v-col cols="6" md="2" v-if="!vistaSeguimiento">
          <v-tooltip top v-if="sesionCursoProfesor && !sesionCursoPadre">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                outlined
                v-bind="attrs"
                v-on="on"
                :loading="loading"
                @click="recalcularCalificaciones()"
              >
                <v-icon>mdi-table-refresh</v-icon>
              </v-btn>
            </template>
            <span>Recalcular calificaciones</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#1D6F42"
                dark
                v-bind="attrs"
                v-on="on"
                :loading="loading"
                style="margin-left: 10px"
                @click="$emit('download')"
              >
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Generar Excel</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-simple-table>
        <thead>
          <tr>
            <th style="width: 30px">No.</th>
            <th>Alumno</th>
            <th
              v-for="item of tableHeaders.headers"
              :key="item._id"
              style="width: 50px"
            >
              <v-tooltip v-if="item.tooltip" top>
                <template #activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    dark
                    label
                    :color="item.color.codigo"
                  >
                    {{ item.texto }}
                  </v-chip>
                </template>
                <span>{{ item.nombre }}</span>
              </v-tooltip>
              <v-chip v-else label>{{ item.texto }}</v-chip>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(calificacion, index) in calificacionesSeccion"
            :key="calificacion.usuario._id"
          >
            <td>
              <span>{{ index + 1 }}</span>
            </td>
            <td>
              <v-list-item>
                <v-list-item-avatar>
                  <img
                    v-if="calificacion.usuario.avatar"
                    :src="`${imageURL}/icon/${calificacion.usuario.avatar}`"
                    alt="User"
                  />
                  <v-icon v-else>mdi-account</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    calificacion.usuario.nombreCompleto
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </td>
            <td
              v-for="calificacionAlumno in calificacion.calificaciones"
              :key="calificacionAlumno._id"
              align="center"
              style="width: 50px"
            >
              <span>{{ calificacionAlumno.calificacion }}</span>
            </td>
            <td
              v-if="seccion !== 'TOTAL'"
              align="center"
              style="width: 50px"
              @dblclick="
                mostrarPtsExtraDialog(
                  calificacion.usuario._id,
                  calificacion.puntosExtra,
                  calificacion.calificacion
                )
              "
            >
              <span>{{ calificacion.puntosExtra }} </span>
            </td>
            <td align="center" style="width: 50px">
              <span>{{ calificacion.final }}</span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <updatePuntosExtraSeccion
        v-if="ptsExtraData.mostrar"
        :mostrar="ptsExtraData.mostrar"
        :usuario="ptsExtraData.usuario"
        :currentPuntosExtra="ptsExtraData.puntos"
        :maximoPuntosExtra="ptsExtraData.maxPuntos"
        :seccion="seccion"
        :idCurso="idCurso"
        @cancelar="ptsExtraData.mostrar = false"
        @puntosActualizados="puntosActualizados"
      />

      <!-- <calificaciones-alumno
        v-if="!tieneSesion('profesor') && !vistaSeguimiento"
        :calificaciones="calificaciones"
      ></calificaciones-alumno>

      <calificaciones-total
        v-else-if="seccionSelected && seccionSelected._id == 'TOTAL'"
        :calificaciones="calificaciones"
        :secciones="secciones"
      ></calificaciones-total>

      <calificaciones-seccion
        v-else-if="seccionSelected"
        :seccionSelected="seccionSelected"
        :calificaciones="calificaciones"
      ></calificaciones-seccion> -->
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { recalcularCalificacionesService } from "./calificaciones.service";
import updatePuntosExtraSeccion from "./updatePuntosExtraSeccion.dialog.vue";
// import calificacionesSeccion from "./calificacionesTabla.seccion.vue";
// import calificacionesTotal from "./calificacionesTabla.total.vue";
// import calificacionesAlumno from "./calificacionesTabla.alumno.vue";

export default {
  name: "calificacionesTabla",

  components: {
    updatePuntosExtraSeccion,
    // calificacionesSeccion,
    // calificacionesTotal,
    // calificacionesAlumno,
  },

  props: {
    idCurso: { type: String },
    calificaciones: { type: Array, default: () => [] },
    secciones: { type: Array, default: () => [] },
  },

  data() {
    return {
      loading: false,
      pagination: {},
      seccion: "",
      menuProps: {
        closeOnClick: true,
        closeOnContentClick: true,
      },
      ptsExtraData: {
        mostrar: false,
        usuario: null,
        puntos: 0,
        maxPuntos: 0,
      },
    };
  },

  computed: {
    ...mapGetters([
      "sessionToken",
      "app",
      "imageURL",
      "vistaSeguimiento",
      "sesionCursoProfesor",
      "sesionCursoPadre",
    ]),

    seccionesConTotal() {
      return [...this.secciones, { _id: "TOTAL", nombre: "Final" }];
    },

    seccionSelected() {
      return this.seccionesConTotal.find((e) => e._id == this.seccion);
    },

    tableHeaders() {
      const headers = [];
      if (this.seccion === "TOTAL") {
        for (const seccion of this.secciones) {
          const item = {
            _id: seccion._id,
            nombre: seccion.nombre,
            texto: seccion.puntos,
            color: { codigo: "secondary" },
            tooltip: true,
          };
          headers.push(item);
        }
      } else {
        const seccion = this.secciones.find((x) => x._id === this.seccion);
        if (seccion) {
          for (const criterio of seccion.criterios) {
            const item = {
              _id: criterio._id,
              nombre: criterio.nombre,
              texto: criterio.ponderacion,
              color: criterio.color,
              tooltip: true,
            };
            headers.push(item);
          }
          headers.push({
            _id: "pe",
            nombre: "Puntos extra",
            texto: "PE",
            color: { codigo: "secondary" },
            tooltip: true,
          });
        }
      }
      headers.push({
        _id: "total",
        texto: "Total",
        tooltip: false,
      });
      return { headers };
    },

    calificacionesSeccion() {
      const calificaciones = [];
      if (this.seccion === "TOTAL") {
        for (const calificacion of this.calificaciones) {
          let calificacionAlumno = {
            usuario: calificacion.usuario,
            calificaciones: [],
            final: calificacion.final || 0,
          };
          this.secciones.forEach((seccion) => {
            calificacionAlumno.calificaciones.push({
              _id: seccion._id,
              calificacion:
                calificacion.secciones[seccion._id].puntosFinal || 0,
            });
          });
          calificaciones.push(calificacionAlumno);
        }
      } else {
        const seccion = this.secciones.find((x) => x._id === this.seccion);
        if (seccion) {
          this.calificaciones.forEach((calificacion) => {
            const puntosExtra =
              calificacion.secciones[this.seccion].puntosExtra || 0;
            const calificacionSeccion =
              calificacion.secciones[this.seccion].calificacion || 0;
            let calificacionFinal = calificacionSeccion + puntosExtra;
            if (calificacionFinal > 100) calificacionFinal = 100;
            let calificacionAlumno = {
              usuario: calificacion.usuario,
              calificaciones: [],
              puntosExtra,
              calificacion: calificacionSeccion,
              final: calificacionFinal,
            };
            seccion.criterios.forEach((criterio) => {
              calificacionAlumno.calificaciones.push({
                _id: criterio._id,
                calificacion: calificacion.criterios[criterio._id]
                  ? calificacion.criterios[criterio._id].puntosPonderacion
                  : 0,
              });
            });
            calificaciones.push(calificacionAlumno);
          });
        }
      }
      return calificaciones;
    },
  },

  watch: {},

  mounted() {
    this.seccion = this.secciones[0]._id;
  },

  methods: {
    async recalcularCalificaciones() {
      try {
        this.loading = true;
        const serverResponse = await recalcularCalificacionesService(
          this.idCurso
        );
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("cargarCalificaciones");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    mostrarPtsExtraDialog(usuario, puntos, calificacion) {
      this.ptsExtraData.usuario = usuario;
      this.ptsExtraData.puntos = puntos;
      this.ptsExtraData.maxPuntos = Math.round(100 - calificacion);
      this.ptsExtraData.mostrar = true;
    },
    puntosActualizados(payload) {
      this.$emit("puntosUsuarioActualizados", payload);
      this.ptsExtraData.mostrar = false;
    },
  },
};
</script>
