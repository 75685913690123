<template>
  <v-container>
    <v-card outlined>
      <v-toolbar dense color="secondary" dark flat class="text-h6">
        <v-spacer></v-spacer> Integrantes <v-spacer></v-spacer>
      </v-toolbar>
      <integrantesLoadingComponent v-if="loading" />
      <v-list v-else>
        <!-- PROFESOR -->
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar size="35">
              <v-icon size="30" v-if="!profesor || !profesor.avatar"
                >mdi-account</v-icon
              >
              <img
                v-else
                :src="`${imageURL}/icon/${profesor.avatar}`"
                alt="UserAvatar"
              />
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span v-if="!profesor">No hay profesor asignado</span>
              <span v-else>{{ profesor.nombreCompleto }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>Profesor</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <tablaAlumnos
        :idCurso="idCurso"
        :alumnos="alumnosLocal"
        :permisoEscritura="permisoEscritura"
        :cursoEscuela="cursoEscuela"
        @blockUsuario="blockUsuario"
        @integranteEliminado="eliminarIntegrante"
      />
    </v-card>
  </v-container>
</template>

<script>
import integrantesLoadingComponent from "./integrantesLoading.vue";
import tablaAlumnos from "./tablaAlumnos.vue";
import { obtenerIntegrantesCursoService } from "./integrantes.service";
import { ordenarObjetosAsc } from "../../../helpers/ordenador";
import { mapGetters } from "vuex";

export default {
  name: "integrantesTab",
  components: {
    integrantesLoadingComponent,
    tablaAlumnos,
  },
  computed: {
    ...mapGetters(["imageURL", "sessionToken"]),
    alumnosLocal() {
      const alumnos = [];
      let numero = 1;
      for (const alumno of this.alumnos) {
        const nuevoAlumno = {
          _id: alumno.idUsuario,
          numero: numero++,
          nombreCompleto: alumno.nombreCompleto,
          correo: alumno.correo,
          avatar: alumno.avatar,
          bloqueado: alumno.bloqueado,
        };
        alumnos.push(nuevoAlumno);
      }
      return alumnos;
    },
  },
  watch: {
    sessionToken() {
      this.cargarIntegrantes();
    },
  },
  data() {
    return {
      loading: false,
      idCurso: null,
      profesor: null,
      alumnos: [],
      permisoEscritura: false,
      cursoEscuela: false,
      tableHeaders: [
        { value: "numero", text: "#" },
        { value: "avatar", text: "" },
        { value: "nombreCompleto", text: "nombre" },
        { value: "menu", text: "" },
      ],
    };
  },
  mounted() {
    this.idCurso = this.$route.params.idCurso;
    if (this.sessionToken) this.cargarIntegrantes();
  },
  methods: {
    async cargarIntegrantes() {
      try {
        this.loading = true;
        const serverResponse = await obtenerIntegrantesCursoService(
          this.idCurso
        );
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.profesor = serverResponse.profesor;
          this.alumnos = ordenarObjetosAsc(
            serverResponse.alumnos,
            "nombreCompleto"
          );
          this.permisoEscritura = serverResponse.permisoEscritura;
          this.cursoEscuela = serverResponse.cursoEscuela;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    blockUsuario(data) {
      const index = this.alumnos.findIndex((x) => x.idUsuario === data.idUsuario);
      if (index >= 0) this.alumnos[index].bloqueado = data.bloqueado;
    },
    eliminarIntegrante(idIntegrante) {
      const index = this.alumnos.findIndex((x) => x.idUsuario === idIntegrante);
      if (index >= 0) this.alumnos.splice(index, 1)
    },
  },
};
</script>
