import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{attrs:{"disabled":!_vm.agregarCriterio},on:{"click":function($event){return _vm.$emit('agregarCriterio')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(" mdi-table-row-plus-after ")])],1),_c(VListItemTitle,[_vm._v("Agregar criterio")])],1),_c(VListItem,{on:{"click":function($event){return _vm.$emit('modificarSeccion')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(" mdi-square-edit-outline ")])],1),_c(VListItemTitle,[_vm._v("Modificar sección")])],1),_c(VListItem,{on:{"click":function($event){return _vm.$emit('eliminarSeccion')}}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"red darken-1"}},[_vm._v(" mdi-trash-can-outline ")])],1),_c(VListItemTitle,[_vm._v("Eliminar sección")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }