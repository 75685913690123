import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"outlined":""}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VSpacer),_vm._v(" Integrantes "),_c(VSpacer)],1),(_vm.loading)?_c('integrantesLoadingComponent'):_c(VList,[_c(VListItem,[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"size":"35"}},[(!_vm.profesor || !_vm.profesor.avatar)?_c(VIcon,{attrs:{"size":"30"}},[_vm._v("mdi-account")]):_c('img',{attrs:{"src":(_vm.imageURL + "/icon/" + (_vm.profesor.avatar)),"alt":"UserAvatar"}})],1)],1),_c(VListItemContent,[_c(VListItemTitle,[(!_vm.profesor)?_c('span',[_vm._v("No hay profesor asignado")]):_c('span',[_vm._v(_vm._s(_vm.profesor.nombreCompleto))])]),_c(VListItemSubtitle,[_vm._v("Profesor")])],1)],1)],1),_c('tablaAlumnos',{attrs:{"idCurso":_vm.idCurso,"alumnos":_vm.alumnosLocal,"permisoEscritura":_vm.permisoEscritura,"cursoEscuela":_vm.cursoEscuela},on:{"blockUsuario":_vm.blockUsuario,"integranteEliminado":_vm.eliminarIntegrante}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }