import {
  getServerRequest,
  postServerRequest,
  putServerRequest,
} from "../../../services/serverRequest.service";
import { store } from "@/store/store";
const httpURL = store.getters.httpURL;

export async function obtenerCalificacionService(idCurso, data) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/campus/calificaciones/alumnos/${idCurso}`;
  return await getServerRequest(url, config);
}

export async function generarCalificacionesXlsxService(idCurso, sesionPadre) {
  const token = sesionPadre ? store.getters.tokenSesionHijo : store.getters.sessionToken;
  const config = { headers: { token }, responseType: "blob" };
  const url = `${httpURL}/curso/campus/calificaciones/alumnos/${idCurso}/xlsx`;
  return await getServerRequest(url, config);
}

export async function recalcularCalificacionesService(idCurso) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/campus/calificaciones/${idCurso}/recalcular`;
  return await postServerRequest(url, {}, config);
}

export async function updatePuntosExtraSeccionCalificacion({
  idCurso,
  idSeccion,
  idUsuario,
  requestData,
}) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/campus/${idCurso}/calificaciones/${idUsuario}/${idSeccion}/puntosExtra`;
  return await putServerRequest(url, requestData, config);
}
