<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item :disabled="!agregarCriterio" @click="$emit('agregarCriterio')">
        <v-list-item-icon
          ><v-icon> mdi-table-row-plus-after </v-icon>
        </v-list-item-icon>
        <v-list-item-title>Agregar criterio</v-list-item-title>
      </v-list-item>
      <v-list-item @click="$emit('modificarSeccion')">
        <v-list-item-icon
          ><v-icon> mdi-square-edit-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-title>Modificar sección</v-list-item-title>
      </v-list-item>
      <v-list-item @click="$emit('eliminarSeccion')">
        <v-list-item-icon
          ><v-icon color="red darken-1"> mdi-trash-can-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-title>Eliminar sección</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: "menuSeccion",
  props: {
    seccion: Object,
  },
  computed: {
    agregarCriterio() {
      let total = 0;
      this.seccion.criterios.forEach((criterio) => {
        total += criterio.ponderacion;
      });
      return total < 100
    },
  },
  data: () => ({
    loading: false,
  }),
};
</script>