<template>
  <v-container>
    <v-card outlined>
      <v-list-item>
        <v-list-item-action v-if="permisoEscritura && !vistaSeguimiento">
          <menuSeccionComponent
            :seccion="seccion"
            @modificarSeccion="modificarSeccion"
            @eliminarSeccion="eliminarSeccion"
            @agregarCriterio="agregarCriterio"
          />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ seccion.nombre }}</v-list-item-title>
          <v-list-item-subtitle>{{ ponderacion }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mx-3"></v-divider>

      <v-list>
        <v-list-item v-for="criterio in seccion.criterios" :key="criterio._id">
          <v-list-item-action
            v-if="permisoEscritura && !vistaSeguimiento"
            style="margin-right: 10px"
          >
            <menuCriterioComponent
              :criterio="criterio"
              @modificarCriterio="modificarCriterio(criterio._id)"
              @eliminarCriterio="eliminarCriterioAction(criterio._id)"
            />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ criterio.nombre }}</v-list-item-title>
            <v-list-item-subtitle>
              <i>{{criterio.puntos}} pts.</i>
              <span v-if="criterio.asistencias.requerido"> - Asistencias </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon>
            <v-chip
              label
              small
              text-color="white"
              :color="criterio.color.nombre"
              >{{ criterio.ponderacion }}</v-chip
            >
          </v-list-item-icon>
        </v-list-item>
        <v-list-item>
          <v-list-item-action style="margin-right: 25px"></v-list-item-action>
          <v-list-item-content> Total </v-list-item-content>
          <v-list-item-icon>
            <v-chip
              v-if="totalSeccion === 100"
              label
              small
              outlined
              color="primary"
            >
              {{ totalSeccion }}%
            </v-chip>
            <v-chip
              v-if="totalSeccion !== 100"
              label
              small
              outlined
              color="red"
            >
              {{ totalSeccion }}%
            </v-chip>
          </v-list-item-icon>
        </v-list-item>
      </v-list>

      <criterioFormComponent
        v-if="abrirEditorCriterio"
        :mostrar="abrirEditorCriterio"
        :tipoCurso="tipoCurso"
        :idCurso="idCurso"
        :ponderacionMax="ponderacionMax"
        :editarCriterio="editarCriterio"
        :criterio="criterioModificar"
        :idSeccion="seccion._id"
        @cancelar="cancelarCriterioForm()"
        @criterioAgregado="criterioAgregado"
        @criterioModificado="criterioModificado"
      />

      <eliminarCriterioComponent
        v-if="eliminarCriterio.mostrar"
        :mostrar="eliminarCriterio.mostrar"
        :tipoCurso="tipoCurso"
        :idCurso="idCurso"
        :idSeccion="seccion._id"
        :criterio="eliminarCriterio.criterio"
        @cancelar="eliminarCriterio.mostrar = false"
        @criterioEliminado="criterioEliminado"
      />
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import menuSeccionComponent from "./menuSeccion.vue";
import criterioFormComponent from "./criterioForm.vue";
import menuCriterioComponent from "./menuCriterio.vue";
import eliminarCriterioComponent from "./eliminarCriterio.vue";

export default {
  name: "seccionEvaluacion",
  components: {
    menuSeccionComponent,
    criterioFormComponent,
    menuCriterioComponent,
    eliminarCriterioComponent,
  },
  props: {
    tipoCurso: String,
    idCurso: String,
    seccion: Object,
    permisoEscritura: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(["vistaSeguimiento"]),
    ponderacionMax() {
      let sumatoria = 0;
      this.seccion.criterios.forEach((criterio) => {
        sumatoria += criterio.ponderacion;
      });
      return 100 - sumatoria;
    },
    ponderacion() {
      let ponderacion = "";
      if (this.seccion.ponderacion >= 0)
        ponderacion = `${this.seccion.ponderacion}%`;
      else ponderacion = `Promedio (${this.seccion.puntos}%)`;
      return ponderacion;
    },
    totalSeccion() {
      let total = 0;
      this.seccion.criterios.forEach((criterio) => {
        total += criterio.ponderacion;
      });
      return total;
    },
  },
  data: () => ({
    loading: false,
    abrirEditorCriterio: false,
    editarCriterio: false,
    criterioModificar: null,
    eliminarCriterio: {
      mostrar: false,
      criterio: null,
    },
  }),
  methods: {
    agregarCriterio() {
      this.editarCriterio = false;
      this.abrirEditorCriterio = true;
    },
    modificarSeccion() {
      this.$emit("modificarSeccion", this.seccion._id);
    },
    eliminarSeccion() {
      this.$emit("eliminarSeccion", this.seccion._id);
    },
    cancelarCriterioForm() {
      this.abrirEditorCriterio = false;
      this.editarCriterio = false;
    },
    modificarCriterio(idCriterio) {
      const criterio = this.seccion.criterios.find((x) => x._id == idCriterio);
      this.criterioModificar = criterio;
      this.editarCriterio = true;
      this.abrirEditorCriterio = true;
    },
    eliminarCriterioAction(idCriterio) {
      this.eliminarCriterio.criterio = this.seccion.criterios.find(
        (x) => x._id == idCriterio
      );
      this.eliminarCriterio.mostrar = true;
    },
    criterioAgregado(data) {
      this.$emit("criterioAgregado", data);
    },
    criterioModificado(data) {
      this.$emit("criterioModificado", data);
    },
    criterioEliminado(data) {
      this.$emit("criterioEliminado", data);
    },
  },
};
</script>
