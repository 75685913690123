<template>
  <v-list-item>
    
    <v-list-item-icon>
      <v-progress-circular
        v-if="loading"
        size="20"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <v-icon v-else color="primary">mdi-harddisk</v-icon>
    </v-list-item-icon>
    
    
    <v-list-item-content>
      <v-list-item-title v-if="loading">Cargando...</v-list-item-title>
      <v-list-item-title v-else>{{ almacenamientoText }}</v-list-item-title>
      <v-list-item-subtitle>Almacenamiento total del curso</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import { obtenerAlmacenamientoService } from "./configuracion.service.js";
import numeral from "numeral";

export default {
  name: "almacenamientoTotal",
  props: {
    idCurso: String,
  },
  computed: {
    ...mapGetters(["sessionToken"]),
    almacenamientoText () {
      return numeral(this.almacenamiento).format("0.0 b");
    }
  },
  watch: {
    sessionToken() {
      this.cargarAlmacenamiento();
    },
  },
  data () {
    return {
      loading: false,
      almacenamiento: 0,
    }
  },
  mounted () {
    if(this.sessionToken) this.cargarAlmacenamiento();
  },
  methods: {
    async cargarAlmacenamiento () {
      try {
        this.loading = true;
        const serverResponse = await obtenerAlmacenamientoService(this.idCurso);
        this.loading = false;
        if(!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.almacenamiento = serverResponse.almacenamientoTotal;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    }
  }
}
</script>