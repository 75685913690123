<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dark dense flat class="text-h6"
        >Registro de asistencias</v-toolbar
      >

      <v-container grid-list-md>
        <v-list>
            <template v-for="(item, index) in asistenciasMes">
                <v-list-item :key="`item-${index}`">
                <v-list-item-content>
                    <v-list-item-title >{{item.fecha}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action style="display: inline;">
                  <v-chip :color="item.color" x-small></v-chip> 
                  {{item.asistencia}}
                </v-list-item-action>
                </v-list-item>
                <v-divider class="mx-3" :key="index"></v-divider>
            </template>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark small @click="cerrar()">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    mostrar: Boolean,
    asistenciasMes: Array,
  },
  data: () => ({
    loading: false,
  }),
  created() {},
  methods: {
    cerrar() {
      this.$emit("cerrar");
    },
  },
};
</script>

<style scoped>
.formularioClass {
  padding: 15px;
  padding-bottom: 0;
}
</style>
