<template>
    <v-dialog v-model="mostrar" persistent  :max-width="500">
        <v-card>
            <v-toolbar dense color="danger" dark flat class="text-h6">Eliminar criterio</v-toolbar>
            <v-container grid-list-md>
                <p><b>¿Está seguro de eliminar el criterio?</b> <br>
                <ul><li>{{criterio.nombre}}</li></ul>
                </p>
                <p>Con esta acción todas las actividades relacionadas quedarán sin criterio y con puntuación cero.</p>
                <v-alert
                  dense
                  type="warning"
                  border="left"
                >
                  Esta acción no podrá ser revertida.
                </v-alert>
            </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" 
                small 
                outlined 
                :disabled="loading" 
                @click="$emit('cancelar')">Cancelar</v-btn>
                <v-btn color="danger" 
                small
                dark
                :loading="loading" 
                @click="eliminar()">Eliminar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { eliminarCriterioCursoService } from "./evaluacion.service.js";
export default {
  name: "eliminarCriterio",
  props: {
    mostrar: Boolean,
    criterio: Object,
    tipoCurso: String,
    idCurso: String,
    idSeccion: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async eliminar() {
      try {
        this.loading = true;
        const serverResponse = await eliminarCriterioCursoService(
          this.idCurso,
          this.tipoCurso,
          this.criterio._id
        );
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            idSeccion: this.idSeccion,
            idCriterio: this.criterio._id,
          };
          this.$emit("criterioEliminado", emitData);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
