<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="secondary" dark flat dense class="text-h6">
        <v-toolbar-title v-if="!editarModulo">Agregar módulo</v-toolbar-title>
        <v-toolbar-title v-else>Modificar módulo</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <br />

      <v-form
        ref="formulario"
        v-model="esValido"
        lazy-validation
        class="formularioClass"
      >
        <v-text-field
          v-model="datosFormulario.nombre"
          outlined
          dense
          label="Nombre"
          :disabled="loading"
          :rules="[reglasComunes.valorRequerido('Nombre requerido')]"
        ></v-text-field>
        <div
          style="text-align: center"
          v-if="
            puedeModificarClases ||
            puedeModificarActividades ||
            puedeModificarRecursos ||
            puedeModificarExamen
          "
        >
          <p style="font-size: 13px">
            Las secciones con contenido no pueden ser desactivadas
          </p>
        </div>
        <v-container>
          <p>Este módulo contiene:</p>
          <v-row>
            <v-col cols="6">
              <v-checkbox
                :disabled="puedeModificarClases"
                v-model="datosFormulario.contieneClases"
                label="Clases"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" v-if="tipoModulo != 'moduloProfesor'">
              <v-checkbox
                :disabled="puedeModificarActividades"
                v-model="datosFormulario.contieneActividades"
                label="Actividades"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-checkbox
                :disabled="puedeModificarRecursos"
                v-model="datosFormulario.contieneRecursos"
                label="Recursos"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" v-if="tipoModulo != 'moduloProfesor'">
              <v-checkbox
                :disabled="puedeModificarExamen"
                v-model="datosFormulario.contieneExamenes"
                label="Exámenes"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="cerrarForm()"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{ 'disable-events': !esValido }"
          @click="agregarModulo()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  agregarModuloService,
  modificarModuloService,
} from "./modulos.service";
import { validationMixin } from "vuelidate";

export default {
  props: {
    mostrar: Boolean,
    tipoCurso: String,
    idCurso: String,
    editarModulo: Object || null,
    tipo: String,
  },
  mixins: [validationMixin],
  computed: {
    nombreErrors() {
      const errors = [];
      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required && errors.push("Campo requerido.");
      return errors;
    },
    datosFormulario() {
      let nombre = this.editarModulo ? this.editarModulo.nombre : "";
      let contieneClases = this.editarModulo
        ? this.editarModulo.contieneClases
        : false;
      let contieneActividades = this.editarModulo
        ? this.editarModulo.contieneActividades
        : false;
      let contieneRecursos = this.editarModulo
        ? this.editarModulo.contieneRecursos
        : false;
      let contieneExamenes = this.editarModulo
        ? this.editarModulo.contieneExamenes
        : false;
      return {
        nombre,
        contieneClases,
        contieneActividades,
        contieneRecursos,
        contieneExamenes,
      };
    },
    puedeModificarActividades() {
      let puede = false;
      if (
        this.editarModulo != null &&
        this.editarModulo.actividades.length > 0
      ) {
        puede = true;
      }
      return puede;
    },
    puedeModificarClases() {
      let puede = false;
      if (this.editarModulo != null && this.editarModulo.clases.length > 0) {
        puede = true;
      }
      return puede;
    },
    puedeModificarRecursos() {
      let puede = false;
      if (this.editarModulo != null && this.editarModulo.recursos.length > 0) {
        puede = true;
      }
      return puede;
    },
    puedeModificarExamen() {
      let puede = false;
      if (
        this.editarModulo != null &&
        this.editarModulo.examenAplicaciones.length > 0
      ) {
        puede = true;
      }
      return puede;
    },
  },
  data: () => ({
    loading: false,
    esValido: true,
    tipoModulo: null,
    reglasComunes: {
      valorRequerido(textoParaMostrar) {
        return (v) => !!v || textoParaMostrar;
      },
    },
  }),
  created() {
    if(this.tipo) this.tipoModulo = this.tipo
    else this.tipoModulo = this.$route.params.tipoModulo;
  },
  methods: {
    async agregarModulo() {
      if (this.$refs.formulario.validate()) {
        try {
          const data = {
            nombre: this.datosFormulario.nombre,
            contieneClases: this.datosFormulario.contieneClases,
            contieneActividades: this.datosFormulario.contieneActividades,
            contieneRecursos: this.datosFormulario.contieneRecursos,
            contieneExamenes: this.datosFormulario.contieneExamenes,
            tipoModulo: this.tipoModulo,
          };

          this.loading = true;
          let serverResponse = null;
          let mensaje = "";
          if (this.editarModulo) {
            serverResponse = await modificarModuloService(
              data,
              this.tipoCurso,
              this.idCurso,
              this.editarModulo._id
            );
            mensaje = "moduloModificado";
          } else {
            serverResponse = await agregarModuloService(data, this.tipoCurso, this.idCurso);
            mensaje = "moduloRegistrado";
          }
          this.loading = false;

          if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);

          else {
            this.$emit(mensaje, serverResponse.modulo);
            this.$emit("cancelar");
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },

    cerrarForm() {
      this.$refs.formulario.reset();
      this.$emit("cancelar");
    },
  },
};
</script>

<style scoped>
.formularioClass {
  padding: 15px;
  padding-bottom: 0;
}
</style>
