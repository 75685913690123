import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCardTitle,{staticClass:"pt-0"},[_c(VCol,{staticStyle:{"padding":"0px"}},[_c(VCardTitle,{staticStyle:{"font-size":"medium","padding":"0px","margin":"5px"}},[_vm._v(_vm._s(_vm.aviso.titulo))]),_c(VCardSubtitle,{staticStyle:{"font-size":"small","padding":"5px"}},[_vm._v(_vm._s(_vm.moment(_vm.aviso.fecha).format('DD [de] MMMM [de] YYYY')))])],1)],1),_c(VCardText,{staticClass:"text-h8"},[_vm._v(" "+_vm._s(_vm.aviso.descripcion)+" ")]),(_vm.aviso.confirmacion && _vm.permisoEscritura)?_c(VCardActions,{staticClass:"d-flex justify-end mr-2 mb-1"},[_c(VBtn,{attrs:{"small":"","color":"primary","outlined":_vm.mostrarConfirmado},on:{"click":_vm.confirmarAviso}},[_vm._v(_vm._s(_vm.mensaje))])],1):_vm._e(),(_vm.eliminarConfirmacion)?_c('eliminarConfirmacionComponent',{attrs:{"mostrar":_vm.eliminarConfirmacion},on:{"cancelar":function($event){_vm.eliminarConfirmacion = false},"modificarConfirmarAviso":_vm.modificarConfirmarAviso}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }