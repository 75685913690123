import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VMenu,{attrs:{"offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","icon":"","x-small":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){_vm.moverActividad = true}}},[_c(VListItemTitle,[_vm._v("Mover actividad de módulo")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-share")])],1)],1)],1)],1),(_vm.moverActividad)?_c('moverObjetoComponent',{attrs:{"mostrar":_vm.moverActividad,"idCurso":_vm.idCurso,"actividad":_vm.actividad,"idModulo":_vm.idModulo,"tipoCurso":_vm.tipoCurso},on:{"cancelar":function($event){_vm.moverActividad = false},"actividadCambiada":_vm.actividadCambiada}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }