<template>
  <v-card flat>
    <v-tabs v-model="tab" centered color="secondary" show-arrows>
      <v-tabs-slider color="warning"></v-tabs-slider>
      <v-tab href="#encuadre" to="#encuadre">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" left>mdi-book-open-outline</v-icon>
          </template>
          <span>Encuadre</span>
        </v-tooltip>
      </v-tab>
      <v-tab v-if="tipoCurso == 'campus' && !sesionCursoPadre" href="#avisos" to="#avisos">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" left>mdi-bullhorn</v-icon>
          </template>
          <span>Avisos</span>
        </v-tooltip>
      </v-tab>
      <v-tab v-if="!sesionCursoPadre" href="#modulos" to="#modulos">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" left>mdi-view-list</v-icon>
          </template>
          <span>Módulos</span>
        </v-tooltip>
        <v-icon></v-icon>
      </v-tab>
      <v-tab href="#actividades" to="#actividades">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" left>mdi-format-list-text</v-icon>
          </template>
          <span>Actividades</span>
        </v-tooltip>
        <v-icon></v-icon>
      </v-tab>
      <v-tab v-if="!sesionCursoAlumno && !sesionCursoPadre" href="#profesores" to="#profesores">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" left>mdi-briefcase</v-icon>
          </template>
          <span>Profesores</span>
        </v-tooltip>
      </v-tab>
      <v-tab v-if="tipoCurso == 'campus' && !sesionCursoPadre" href="#integrantes" to="#integrantes">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" left>mdi-account-group</v-icon>
          </template>
          <span>Integrantes</span>
        </v-tooltip>
      </v-tab>
      <v-tab v-if="tipoCurso == 'campus'" href="#asistencias" to="#asistencias">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" left
              >mdi-clipboard-text-clock-outline</v-icon
            >
          </template>
          <span>Asistencias</span>
        </v-tooltip>
      </v-tab>
      <v-tab
        v-if="tipoCurso == 'campus'"
        href="#calificaciones"
        to="#calificaciones"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" left>mdi-chart-box</v-icon>
          </template>
          <span>Calificaciones</span>
        </v-tooltip>
      </v-tab>
      <v-tab
        v-if="!sesionCursoAlumno && !sesionCursoPadre"
        href="#configuracion"
        to="#configuracion"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" left>mdi-cog</v-icon>
          </template>
          <span>Configuración</span>
        </v-tooltip>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="encuadre"
        ><encuadreTabComponent :permisoEscritura="permisoEscritura"
      /></v-tab-item>
      <v-tab-item v-if="tipoCurso == 'campus'" value="avisos">
        <avisosTabComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :permisoEscritura="permisoEscritura"
        />
      </v-tab-item>
      <v-tab-item value="modulos">
        <modulosTabComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :permisoEscritura="permisoEscritura"
        />
      </v-tab-item>
      <v-tab-item value="actividades">
        <actividadesTabComponent
          :tipoCurso="tipoCurso"
        />
      </v-tab-item>
      <v-tab-item v-if="!sesionCursoAlumno && (tipoCurso != 'campus' || cursoPlantilla != null)" value="profesores">
        <profesoresTabComponent
          :tipoCurso="tipoCurso" 
          :idCurso="idCurso"
          :cursoPlantilla="cursoPlantilla" 
          :permisoEscritura="permisoEscritura"/>
      </v-tab-item>
      <v-tab-item v-if="tipoCurso == 'campus'" value="integrantes"
        ><integrantesTabComponent
      /></v-tab-item>
      <v-tab-item v-if="tipoCurso == 'campus'" value="asistencias"
        ><asistenciasTabComponent
          :permisoEscritura="permisoEscritura"
      /></v-tab-item>
      <v-tab-item v-if="tipoCurso == 'campus'" value="calificaciones"
        ><calificacionesTabComponent
      /></v-tab-item>
      <v-tab-item v-if="!sesionCursoAlumno" value="configuracion"
        ><configuracionTabComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :curso="curso"
          :permisoEscritura="permisoEscritura"
      /></v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import encuadreTabComponent from "../encuadre/encuadreTab.vue";
import avisosTabComponent from "../avisos/avisosTab.vue";
import modulosTabComponent from "../modulos/modulosTab.vue";
import actividadesTabComponent from "../actividades/actividadesTab.vue";
import profesoresTabComponent from "../profesores/profesoresTab.vue";
import integrantesTabComponent from "../integrantes/integrantesTab.vue";
import asistenciasTabComponent from "../asistencias/asistenciasTab.vue";
import calificacionesTabComponent from "../calificaciones/calificacionesTab.vue";
import configuracionTabComponent from "../configuracion/configuracionTab.vue";
import { mapGetters } from "vuex";

export default {
  name: "cursoTabs",
  props: {
    tipoCurso: String,
    idCurso: String,
    curso: Object,
    cursoPlantilla: {type: String, default: null},
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    encuadreTabComponent,
    avisosTabComponent,
    modulosTabComponent,
    actividadesTabComponent,
    profesoresTabComponent,
    integrantesTabComponent,
    asistenciasTabComponent,
    calificacionesTabComponent,
    configuracionTabComponent,
  },
  computed: {
    ...mapGetters(["sesionCursoAlumno", "sesionCursoPadre", "vistaSeguimiento"]),
  },
  data() {
    return {
      loading: false,
      tab: null,
    };
  },
};
</script>
