<template>
  <v-dialog v-model="mostrar" persistent width="500">
    <v-card>
      <v-toolbar color="primary" dense flat dark>
        <strong>Visto por</strong>
        <v-spacer></v-spacer>
        <v-icon @click="$emit('cancelar')">mdi-close</v-icon>
      </v-toolbar>

      
      <v-card-subtitle class="mt-5 pl-4">Alumnos que han visto: {{ vistos.length }}</v-card-subtitle>

      <v-list v-for="(visto, index) in vistosLocal" :key="index">
      <v-list-item>
        <v-list-item-avatar class="avatar-list">
          <v-avatar color="primary" size="30">
            <v-icon color="white" v-if="!visto.usuario.avatar">mdi-account</v-icon>
            <img
            v-else
            :src="`${imageURL}/avatar/${visto.usuario.avatar}`"
            alt="UserImage"
            />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{visto.usuario.nombreCompleto}}</v-list-item-title>
          <v-list-item-subtitle>Visto: {{ moment(visto.fecha).format('DD [de] MMMM [de] YYYY [a las] HH:mm') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="index !== vistosLocal.length-1"></v-divider>
    </v-list>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import moment from 'moment-timezone';

export default {
  name: 'listadoAvisosVistos',
  props: {
    mostrar: { type: Boolean, default: false },
    vistos: { type: Array, default: null },
  },
  computed: {
    ...mapGetters(["imageURL"]),
  },
  data () {
    return {
      vistosLocal: null,
    }
  },
  watch: {
    vistos(value) {
      this.vistosLocal = [...value].reverse();
    }
  },
  created() {
    this.moment = moment;
    this.vistosLocal = [...this.vistos].reverse();
  },
  methods: {
  }
}
</script>