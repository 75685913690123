<template>
  <v-list-item
    @mouseover="permisoEscritura && !vistaSeguimiento ? (modificarIcon = true) : null"
    @mouseout="permisoEscritura ? (modificarIcon = false) : null"
  >
    <v-list-item-icon>
      <v-btn v-if="modificarIcon && !vistaSeguimiento" @click="mostrarEditar = true" fab x-small color="primary">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <v-icon v-else color="primary">mdi-account-multiple-outline</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ maxAlumnos }} alumnos</v-list-item-title>
      <v-list-item-subtitle>Registro máximo</v-list-item-subtitle>
    </v-list-item-content>
    <editarConfigComponent
      :mostrar="mostrarEditar"
      :titulo="titulo"
      :subtitulo="subtitulo"
      :idCurso="idCurso"
      :tipoModificacion="tipoModificacion"
      :valor="maxAlumnos"
      @valorModificado="valorModificado"
      @nuevoLog="nuevoLog"
      @cancelar="mostrarEditar = false"
    />
  </v-list-item>
</template>

<script>

import editarConfigComponent from './editarConfig.vue';
import { mapGetters } from "vuex";

export default {
  name: "registroAlumnos",
  props: {
    idCurso: String,
    maxAlumnos: Number,
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    editarConfigComponent
  },
  computed: {
    ...mapGetters(["vistaSeguimiento"]),
  },
  data() {
    return {
      loading: false,
      modificarIcon: false,
      mostrarEditar: false,
      titulo: 'Tamaño máximo de alumnos',
      subtitulo: 'Alumnos',
      tipoModificacion: 'maxAlumnos'
    };
  },
  methods: {
    valorModificado(nuevoMaxAlumnos){
      this.$emit("maxAlumnosModificado", nuevoMaxAlumnos);
    },
    nuevoLog(log){
      this.$emit("nuevoLog", log);
    }
  }
};
</script>
