<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="danger" dark dense flat class="text-h6"
        >Eliminar asistencia</v-toolbar
      >

      <v-container grid-list-md>
        <b>
          <i
            aria-hidden="true"
            class="v-icon notranslate v-alert__icon mdi mdi-alert-box theme--light error--text"
          ></i>
          ¿Está seguro de elimiar la asistencia?
        </b>
        <br />
        <i>Esta acción no podrá ser revertida.</i>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            small
            :disabled="loading"
            @click="cerrarForm()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="red"
            dark
            small
            :loading="loading"
            @click="eliminarAsistencia()"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { eliminarAsistenciaService } from "./asistencias.service";

export default {
  props: {
    mostrar: Boolean,
    idCurso: String,
    idAsistencia: String,
  },
  data: () => ({
    loading: false,
  }),
  created() {},
  methods: {
    async eliminarAsistencia() {
      try {
        this.loading = true;
        const serverResponse = await eliminarAsistenciaService(
          this.idCurso,
          this.idAsistencia
        );
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("asistenciaEliminada", this.idAsistencia);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    cerrarForm() {
      this.$emit("cancelar");
    },
  },
};
</script>

<style scoped>
.formularioClass {
  padding: 15px;
  padding-bottom: 0;
}
</style>
