import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"flat":""}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VSpacer),_vm._v(" Módulos "),_c(VSpacer),(_vm.permisoEscritura && !_vm.vistaSeguimiento)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"white","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{on:{"click":function($event){return _vm.crearModulo()}}},[_vm._v(" mdi-text-box-plus ")])],1)]}}],null,false,3271746601)},[_c('span',[_vm._v("Agregar módulo")])]):_vm._e()],1),(_vm.localModulos)?_c(VRow,[_c(VList,{staticStyle:{"width":"100%"}},[_c('draggable',_vm._b({attrs:{"element":"div","ghost-class":"ghost","handle":".handle"},on:{"change":_vm.cambiarOrden},model:{value:(_vm.modulos),callback:function ($$v) {_vm.modulos=$$v},expression:"modulos"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.modulos),function(modulo){return _c(VCol,{key:modulo,staticClass:"col-12"},[_c('moduloItemComponent',{attrs:{"tipoCurso":_vm.tipoCurso,"idModulo":modulo,"idCurso":_vm.idCurso,"tipoModulo":_vm.tipo,"avanceAutomatico":_vm.avanceAutomatico,"permisoEscritura":_vm.permisoEscritura},on:{"moduloEliminado":_vm.moduloEliminado}})],1)}),1)],1)],1):_c(VRow,[_c(VCol,{attrs:{"md":"12","sm":"12"}},[_c(VCard,{staticClass:"my-2",staticStyle:{"text-align":"center"},attrs:{"outlined":""}},[_vm._v(" No hay módulos registrados ")])],1)],1)],1),(_vm.agregarModulo)?_c('agregarModuloComponent',{attrs:{"mostrar":_vm.agregarModulo,"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"tipo":_vm.tipo,"editarModulo":_vm.editarModulo},on:{"cancelar":function($event){(_vm.agregarModulo = false), (_vm.editarModulo = null)},"moduloRegistrado":_vm.moduloRegistrado}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }