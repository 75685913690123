<template>
  <span>
    <v-icon v-if="loading" color="primary"
      >mdi-all-inclusive</v-icon
    >
    <v-icon v-if="active && !loading" color="green darken-1" @click="activate()"
      >mdi-toggle-switch</v-icon
    >
    <v-icon v-if="!active && !loading" @click="deactivate()"
      >mdi-toggle-switch-off</v-icon
    >
  </span>
</template>

<script>
export default {
  name: "switchMini",
  props: {
    active: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    activate() {
      if (!this.loading && !this.disabled) this.$emit("activate");
    },
    deactivate() {
      if (!this.loading && !this.disabled) this.$emit("deactivate");
    },
  },
};
</script>
