import { getServerRequest, putServerRequest, patchServerRequest, deleteServerRequest } from '../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function obtenerAlumnosService(idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/asistencias/alumnos/${idCurso}`;
    return await getServerRequest(url, config);
}

export async function registrarAsistenciaService(data, idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/asistencias/${idCurso}`;
    return await putServerRequest(url, data, config);
}

export async function modificarAsistenciaService(data, idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/asistencia/modificar/${idCurso}`;
    return await patchServerRequest(url, data, config);
}

export async function eliminarAsistenciaService(idCurso, idAsistencia) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/asistencia/eliminar/${idCurso}/${idAsistencia}`;
    return await deleteServerRequest(url, config);
}

export async function modificarFechaAsistenciaService(data, idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/asistencia/modificar/fecha/${idCurso}`;
    return await patchServerRequest(url, data, config);
}