import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{staticStyle:{"width":"100%"}},[(_vm.modulo.visible || !_vm.sesionCursoAlumno || _vm.avanceAutomatico)?_c('div',{staticStyle:{"width":"100%"}},[(_vm.loading)?_c(VCard,{staticClass:"my-2",attrs:{"outlined":""}},[_c(VSkeletonLoader,{staticStyle:{"margin":"20px"},attrs:{"type":"list-item-avatar-three-line"}})],1):_vm._e(),_c(VCard,{staticClass:"my-2",attrs:{"outlined":""}},[_c(VList,[_c(VListItem,[(
              !_vm.sesionCursoAlumno &&
              _vm.tipoModulo != 'moduloProfesor' &&
              !_vm.vistaSeguimiento &&
              _vm.permisoEscritura
            )?_c(VListItemIcon,{class:{ handle: _vm.dragState == true },staticStyle:{"margin":"auto"},on:{"mouseover":function($event){_vm.permisoEscritura && !_vm.vistaSeguimiento
                ? (_vm.dragState = true)
                : null},"mouseout":function($event){_vm.permisoEscritura ? (_vm.dragState = false) : null}}},[_c(VIcon,{attrs:{"color":"grey"}},[_vm._v("mdi-drag")])],1):_vm._e(),(
              _vm.tipoCurso == 'campus' &&
              !_vm.sesionCursoAlumno &&
              _vm.tipoModulo != 'moduloProfesor' &&
              !_vm.vistaSeguimiento &&
              _vm.permisoEscritura
            )?_c(VListItemAction,[_c('moduloSwitchComponent',{attrs:{"idModulo":_vm.modulo._id,"idCurso":_vm.idCurso,"visible":_vm.modulo.visible,"deshabilitado":_vm.avanceAutomatico},on:{"moduloVisibleModificado":_vm.moduloVisibleModificado}})],1):_vm._e(),_c(VListItemContent,{staticStyle:{"margin-left":"10px"}},[_c(VListItemTitle,[(_vm.moduloHabilitado)?_c('a',{staticClass:"link text-h6",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.verModulo()}}},[_vm._v(" "+_vm._s(_vm.modulo.nombre)+" ")]):_c('p',{staticClass:"link text-h6"},[_vm._v(_vm._s(_vm.modulo.nombre))])]),_c(VListItemSubtitle,[(_vm.modulo.contieneClases)?_c('genericoChipComponent',{attrs:{"text":_vm.modulo.clases.length,"icon":"mdi-motion-play-outline"}}):_vm._e(),(_vm.modulo.contieneActividades)?_c('genericoChipComponent',{attrs:{"text":_vm.modulo.actividades.length,"icon":_vm.actividadArchivoIcon}}):_vm._e(),(_vm.modulo.contieneRecursos)?_c('genericoChipComponent',{attrs:{"text":_vm.modulo.recursos.length,"icon":"mdi-link-variant"}}):_vm._e(),(_vm.modulo.contieneExamenes)?_c('genericoChipComponent',{attrs:{"text":_vm.modulo.examenAplicaciones.length,"icon":_vm.examenIcon}}):_vm._e(),(_vm.tipoCurso == 'campus' && !_vm.loading)?_c('genericoChipComponent',{attrs:{"text":((_vm.modulo.avance) + "%"),"icon":"mdi-finance"}}):_vm._e()],1)],1),_c(VListItemAction,[(_vm.permisoEscritura && !_vm.vistaSeguimiento)?_c(VMenu,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3448670655)},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){return _vm.modificarModulo()}}},[_c(VListItemTitle,[_vm._v("Modificar módulo")]),_c(VListItemIcon,[_c(VIcon,{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v(" mdi-file-outline ")])],1)],1),_c(VListItem,{on:{"click":function($event){_vm.habilitarEliminar = true}}},[_c(VListItemTitle,[_c('font',{attrs:{"color":"#D32F2F"}},[_vm._v("Eliminar módulo")])],1),_c(VListItemIcon,[_c(VIcon,{staticStyle:{"margin-right":"5px"},attrs:{"color":"red darken-2","small":""}},[_vm._v(" mdi-trash-can-outline ")])],1)],1)],1)],1):_vm._e()],1)],1)],1)],1),(_vm.agregarModulo)?_c('agregarModuloComponent',{attrs:{"mostrar":_vm.agregarModulo,"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"tipo":_vm.tipoModulo,"editarModulo":_vm.editarModulo},on:{"cancelar":function($event){(_vm.agregarModulo = false), (_vm.editarModulo = null)},"moduloModificado":_vm.moduloModificado}}):_vm._e(),(_vm.habilitarEliminar)?_c('eliminar-modulo',{attrs:{"mostrar":_vm.habilitarEliminar,"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"idModulo":_vm.idModulo,"tipoModulo":_vm.tipoModulo,"modulo":_vm.modulo},on:{"cancelar":function($event){_vm.habilitarEliminar = false},"moduloEliminado":_vm.moduloEliminado}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }