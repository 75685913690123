<template>
  <v-dialog v-model="mostrar" persistent width="300">
    <v-card>
      <v-toolbar dense dark flat tile color="primary">
        <v-toolbar-title>
          <span>Actualizar puntos extra</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-5">
        <v-form v-model="isFormValid" lazy-validation ref="form">
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                v-model="formData.puntosExtra"
                :items="puntosDisponibles"
                outlined
                dense
                :rules="formRules"
                label="Puntos extra"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                color="primary"
                outlined
                small
                class="mr-2"
                :disabled="loading"
                @click="$emit('cancelar')"
              >
                <span>Cancelar</span>
              </v-btn>
              <v-btn
                color="primary"
                small
                :loading="loading"
                :disabled="!isFormValid"
                @click="submit()"
              >
                <span>Guardar</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { updatePuntosExtraSeccionCalificacion } from "./calificaciones.service";

export default {
  props: {
    mostrar: Boolean,
    usuario: String,
    seccion: String,
    currentPuntosExtra: Number,
    maximoPuntosExtra: Number,
    idCurso: String,
  },

  data() {
    return {
      isFormValid: false,
      loading: false,

      formRules: [
        (v) => !!v || v == 0 || "Ingresa una cantidad válida",
        (v) => !isNaN(Number(v)) || "Solo puedes ingresar números",
        (v) => (v >= 0 && v <= 100) || "Ingresa una cantidad entre 0 y 100",
      ],

      formData: {
        puntosExtra: this.currentPuntosExtra,
      },
    };
  },

  computed: {
    puntosDisponibles() {
      return [
        0,
        ...Array.from({ length: this.maximoPuntosExtra }, (v, k) => k + 1),
      ];
    },
  },

  methods: {
    async submit() {
      await this.$refs.form.validate();
      if (!this.isFormValid) return;

      this.loading = true;
      try {
        const serverResponse = await updatePuntosExtraSeccionCalificacion({
          idCurso: this.idCurso,
          idSeccion: this.seccion,
          idUsuario: this.usuario,
          requestData: this.formData,
        });

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          return;
        }
        const payload = {
          ...serverResponse,
          idUsuario: this.usuario,
          idSeccion: this.seccion,
        };
        this.$emit("puntosActualizados", payload);
      } catch (error) {
        this.$appErrorMessage();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
