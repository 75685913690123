<template>
  <v-container>
    <v-card flat>
      <v-toolbar dense color="secondary" dark flat class="text-h6">
        <v-spacer></v-spacer> Encuadre <v-spacer></v-spacer>
      </v-toolbar>

      <encuadreLoadingComponent v-if="loading" />
      <v-row v-else>
        <v-col md="8">
          <descripcionComponent :permisoEscritura="permisoEscritura" />
        </v-col>
        <v-col md="4">
          <evaluacionComponent :permisoEscritura="permisoEscritura" />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import encuadreLoadingComponent from "./encuadreLoading.vue";
import evaluacionComponent from "./evaluacion/evaluacion.vue";
import descripcionComponent from "./descripcionCurso.vue";

export default {
  name: "encuadreTab",
  props: {
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    encuadreLoadingComponent,
    evaluacionComponent,
    descripcionComponent,
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>
