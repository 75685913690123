<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="danger" dark dense flat class="text-h6">{{titulo}}</v-toolbar>

      <v-container grid-list-md>
        <b>{{leyenda}}</b>
        <br />
        <p><b>•</b> Todas las actividades y calificaciones serán borradas de manera permanente.</p>
        <i>Esta acción no podrá ser revertida.</i>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            small
            :disabled="loading"
            @click="cerrarForm()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="red"
            dark
            small
            :loading="loading"
            @click="eliminarIntegrante()"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import { eliminarIntegranteService } from "./integrantes.service";
import { mapGetters } from "vuex";

export default {
  props: {
    mostrar: Boolean,
    idCurso: String,
    idIntegrante: { type: String || null },
    nombreIntegrante: { type: String || null },
  },
  computed: {
    ...mapGetters(["app", "sessionToken", "sesionCursoAlumno"]),
  },
  data: () => ({
    loading: false,
    leyenda: "",
    titulo: "",
  }),
  created() {
      if(this.app == "campus" && this.sesionCursoAlumno) {
          this.leyenda = "¿Está seguro de abandonar el curso?";
          this.titulo = "Salir del grupo";
      }
      else {
          this.leyenda = "¿Está seguro de elimiar a " + this.nombreIntegrante + " ?";
          this.titulo = "Eliminar integrante";
      }
  },
  methods: {
    async eliminarIntegrante() {
      try {
        this.loading = true;
        const data = { idCurso: this.idCurso, idIntegrante: this.idIntegrante }
        const serverResponse = await eliminarIntegranteService(data);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("integranteEliminado", this.idIntegrante);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    cerrarForm() {
      this.$emit("cancelar");
    },
  },
};
</script>

<style scoped>
.formularioClass {
  padding: 15px;
  padding-bottom: 0;
}
</style>
