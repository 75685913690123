<template>
  <v-dialog v-model="mostrar" width="600" persistent>
    <v-card :loading="loading">
      <v-toolbar color="secondary" dense flat dark class="text-h6">
        <strong>{{ aviso ? "Modificar aviso" : "Agregar aviso" }}</strong>
      </v-toolbar>

      <v-container>
        <v-card elevation="0">
          <v-container fluid>
          <v-text-field
            v-model="titulo"
            label="Título"
            outlined
            dense
            :disabled="loading"
            :error-messages="errors.titulo"
            @input="validarTitulo()"
            @blur="validarTitulo()"
          ></v-text-field>

          <v-textarea
            v-model="descripcion"
            label="Descripción"
            append-icon="mdi-comment-plus-outline"
            outlined
            :disabled="loading"
            rows="5"
            no-resize
            :error-messages="errors.descripcion"
            @input="validarDescripcion()"
            @blur="validarDescripcion()"
          ></v-textarea>
        </v-container>
        </v-card>
      </v-container>

      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          outlined
          color="primary"
          :disabled="loading"
          @click="$emit('cancelar')"
        >Cancelar
        </v-btn>
        <v-btn
          small
          color="primary"
          :loading="loading"
          :class="{
            'disable-events': blockBtn,
          }"
          @click="submit()"
        >{{ aviso ? "Modificar" : "Agregar"}}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { crearAvisoProfesorService, modificarAvisoService } from "./avisos.service.js";

export default {
  name: 'agregarAviso',
  props: {
    mostrar: { type: Boolean, default: false },
    tipoCurso: { type: String, default: null },
    idCurso: { type: String, default: null },
    aviso: { type: Object, default: () => null },
  },
  mixins: [validationMixin],
  validations: {
    titulo: { required },
    descripcion: { required },
  },
  computed: {
    blockBtn() {
      return this.errors.titulo.length > 0 || this.errors.descripcion.length > 0 || this.titulo.length == 0 || this.descripcion.length == 0;
    }
  },
  data () {
    return {
      loading: false,
      titulo: "",
      descripcion: "",
      errors: {
        titulo: [],
        descripcion: []
      },
    }
  },
  watch: {
    aviso() {
      if(this.aviso) this.setData();
    }
  },
  created() {
    if(this.aviso) this.setData();
  },
  methods: {
    setData() {
      this.titulo = this.aviso.titulo;
      this.descripcion = this.aviso.descripcion;
    },
    validarTitulo() {
      this.$v.titulo.$touch();
      const errors = [];
      if (this.$v.titulo.$dirty) {
        !this.$v.titulo.required && errors.push("Campo requerido.");
      }
      this.errors.titulo = errors; 
    },
    validarDescripcion() {
      this.$v.descripcion.$touch();
      const errors = [];
      if (this.$v.descripcion.$dirty) {
        !this.$v.descripcion.required && errors.push("Campo requerido.");
      }
      this.errors.descripcion = errors; 
    },
    async submit(){
      try {
        this.loading = true;
        const data = {
          titulo: this.titulo,
          descripcion: this.descripcion
        };

        const serverResponse = this.aviso
        ? await modificarAvisoService(this.tipoCurso, this.idCurso, this.aviso._id, data)
        : await crearAvisoProfesorService(this.tipoCurso, this.idCurso, data)

        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.aviso
          ? this.$emit("avisoModificado", serverResponse.avisoModificado)
          : this.$emit("nuevoAviso", serverResponse.nuevoAviso);
        }
        
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    }
  }
  
}
</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}

.errorMessage {
  color: #ff0000;
}
.invalid_input {
  border: 1px solid red;
}

.link {
  text-decoration: none;
}

</style>