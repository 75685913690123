<template>
  <v-container>
    <v-card flat>
      <v-toolbar dense color="secondary" dark flat class="text-h6">
        <v-spacer></v-spacer> Calificaciones <v-spacer></v-spacer>
      </v-toolbar>
      <calificacionesLoadingComponent v-if="loading" />
      <v-card v-else outlined>
        <div>
          <v-row>
            <v-col cols="12">
              <calificacionesTablaComponent
                :idCurso="idCurso"
                :calificaciones="calificaciones"
                :secciones="secciones"
                @download="descargarCalificaciones"
                @puntosUsuarioActualizados="puntosUsuarioActualizados"
                @cargarCalificaciones="cargarCalificaciones"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import calificacionesLoadingComponent from "./calificacionesLoading.vue";
import calificacionesTablaComponent from "./calificacionesTabla.vue";
import {
  obtenerCalificacionService,
  generarCalificacionesXlsxService,
} from "./calificaciones.service";
import { obtenerCalificacionControlParental } from "../../controlParental/controlParental.service";

export default {
  name: "calificacionesTab",

  components: {
    calificacionesLoadingComponent,
    calificacionesTablaComponent,
  },

  computed: {
    ...mapGetters(["sessionToken", "app", "sesionCursoPadre"]),
  },
  watch: {
    sessionToken() {
      this.cargarCalificaciones();
    },
  },
  created() {
    this.idCurso = this.$route.params.idCurso;
    if (this.sessionToken) this.cargarCalificaciones();
  },
  data() {
    return {
      loading: false,
      idCurso: null,
      calificaciones: [],
      alumnos: [],
      asistencias: [],
      criterio: null,
      retardosFalta: 3,
      secciones: [],

      showUpdatePuntosExtra: false,
      usuarioPuntosExtra: null,
      seccionPuntosExtra: null,
      currentPuntosExtra: null,
      maximoPuntosExtra: null,
    };
  },
  methods: {
    async cargarCalificaciones() {
      try {
        this.loading = true;
        let serverResponse = null;
        if (this.sesionCursoPadre)
          serverResponse = await obtenerCalificacionControlParental(
            this.idCurso
          );
        else serverResponse = await obtenerCalificacionService(this.idCurso);
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.calificaciones = serverResponse.calificaciones;
          this.secciones = serverResponse.evaluacion;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async descargarCalificaciones() {
      try {
        this.loading = true;
        const serverResponse = await generarCalificacionesXlsxService(
          this.idCurso,
          this.sesionCursoPadre
        );
        this.loading = false;
        const url = window.URL.createObjectURL(serverResponse);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Calificaciones.xlsx");

        link.click();
        link.remove();
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    puntosUsuarioActualizados(payload) {
      const index = this.calificaciones.findIndex(
        (x) => x.usuario._id === payload.idUsuario
      );
      if (index >= 0) {
        this.calificaciones[index].secciones[payload.idSeccion].puntosExtra =
          payload.puntosExtra;
        this.calificaciones[index].secciones[payload.idSeccion].puntosFinal =
          payload.puntosFinal;
        this.calificaciones[index].final = payload.calificacionFinal;
      }
    },
  },
};
</script>
