<template>
  <v-dialog v-model="mostrar" width="500" persistent transition="dialog-bottom-transition">
      <v-card>
        <v-alert
          border="top"
          colored-border
          type="error"
          class="pt-6 pb-0"
        >
        ¿Está seguro de cancelar la confirmación?
        </v-alert>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="primary" 
            outlined 
            small 
            @click="$emit('cancelar')"
          >Cancelar
          </v-btn>
          <v-btn 
            color="danger" 
            small
            dark
            @click="$emit('modificarConfirmarAviso')">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'eliminarConfirmacion',
  props: {
    mostrar: { type: Boolean, default: false },
  }
}
</script>